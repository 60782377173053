import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as math from 'mathjs';
import 'react-resizable/css/styles.css';
import DraggablePaper from './DraggablePaper';
import { styled } from '@mui/system';
import { useTable } from 'react-table';


const ContainerStyles = {
  maxHeight: '70vh',
  overflowY: 'auto',
  padding: 2,
};

const Container = styled(Box)(ContainerStyles);

const ImpedanceDataEditor = ({ openModal, handleCloseModal, fileData, setFileData, setPlotData, weighting, setIsSigmaSet, loadedFileNames }) => {
  const [editedData, setEditedData] = React.useState(fileData);

  // Synchronize editedData with fileData
  React.useEffect(() => {
    if (Array.isArray(fileData)) {
      setEditedData(fileData);
    }
  }, [fileData]);

  const handleRowDelete = React.useCallback((dataIndex) => {
    setEditedData((prevData) =>
      prevData.map(dataset =>
        dataset.filter((_, index) => index !== dataIndex)
      )
    );
  }, []);

  const createNewPlotData = (data) => {
    return data.map((dataset, datasetIndex) =>
      dataset.map((dataPoint) => {
        const Z = math.complex(dataPoint.zreal, dataPoint.zimag);
        const Y = math.inv(Z);
        const real = dataPoint.zreal;
        const imag = dataPoint.zimag;
        const magnitude = Math.sqrt(real ** 2 + imag ** 2);
        const phase = Math.atan2(imag, real) * (180 / Math.PI);

        return {
          realImpedance: dataPoint.zreal,
          imagImpedance: -dataPoint.zimag,
          realAdmittance: Y.re,
          imagAdmittance: Y.im,
          freq: dataPoint.freq,
          datasetIndex: datasetIndex,
          magnitude,
          phase,
        };
      })
    );
  };

  const handleDataSave = () => {
    // If the weighting is 'sigma', validate the sigma data
    if (weighting.type === 'sigma') {
      const sigmaData = weighting.data;
      if (
        sigmaData.length !== editedData[0].length ||
        sigmaData[0].length !== editedData.length
      ) {
        alert('Error: Shape mismatch between sigma data and impedance data.');
        setIsSigmaSet(false);
        return;  // Return early if validation fails
      }
    }

    setFileData(editedData);
    const newPlotData = createNewPlotData(editedData);
    setPlotData(newPlotData);
    handleCloseModal();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Dataset Index',
        accessor: 'datasetIndex',
        Cell: ({ value }) => `Dataset ${value + 1} (${loadedFileNames[value]})`, // use loadedFileNames here
        width: 100,
      },
      {
        Header: 'Index',
        accessor: 'index',
        width: 50,
      },
      {
        Header: 'Frequency',
        accessor: 'freq',
        width: 100,
      },
      {
        Header: 'ZReal',
        accessor: 'zreal',
        width: 100,
      },
      {
        Header: 'ZImag',
        accessor: 'zimag',
        width: 100,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <IconButton onClick={() => handleRowDelete(row.original.dataIndex)}>
            <DeleteIcon />
          </IconButton>
        ),
        width: 50,
      }
    ],
    [handleRowDelete, loadedFileNames]
  );

  const data = React.useMemo(() => {
    return editedData.flatMap((dataset, datasetIndex) =>
      dataset.map((dataPoint, dataIndex) => ({ ...dataPoint, index: dataIndex + 1, datasetIndex, dataIndex }))
    );
  }, [editedData]);

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <Dialog open={openModal} onClose={handleCloseModal}
      PaperComponent={DraggablePaper}
      PaperProps={{ handle: '.modal-title' }}
    >
      <div className="modal-title">
        <DialogTitle>Delete Data Points</DialogTitle>
      </div>
      <DialogContent dividers>
      <Typography variant="body2" sx={{ mb: 2, fontSize: '0.7rem', fontStyle: 'italic' }}>
        You can remove unwanted rows in the table below. Remember to press 'Save Changes' when you're done editing.<br />
        <span style={{color: '#FFA500'}}>Please note: if you load multiple files and delete a row at a certain index, the corresponding row will also be deleted from all other files.</span>
      </Typography>

        <Container>
          <TableContainer component={Paper}>
          <Table stickyHeader {...getTableProps()}>
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell {...column.getHeaderProps()}>{column.render('Header')}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <TableCell
                        {...cell.getCellProps()}
                        style={{
                          width: cell.column.width,
                          maxWidth: cell.column.width,
                          overflow: 'auto',
                        }}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
          </TableBody>

            </Table>
          </TableContainer>
          <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={handleDataSave}>
            Save Changes
          </Button>
          <Button variant="contained" color="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </DialogActions>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default ImpedanceDataEditor;
