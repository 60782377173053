import * as math from 'mathjs';


export function custom_tanh(z) {
    if (math.isComplex(z)) {
      const x = z.re;
      const y = z.im;

      if (Number.isFinite(x)) {
        const tx = math.tanh(x);
        const ty = Math.tan(y);
        const cx = 1 / math.cosh(x);
        const txty = tx * ty;

        const denom = 1 + txty * txty;
        const newRealPart = tx * (1 + ty * ty) / denom;
        const newImaginaryPart = ((ty / denom) * cx) * cx;

        return math.complex(newRealPart, newImaginaryPart);
      } else {
        const real = Math.sign(x);
        const imag = Number.isFinite(y) ? Math.sign(Math.sin(2 * y)) : 0;

        return math.complex(real, imag);
      }
    } else {
      return math.tanh(z);
    }
  }

 export function custom_coth(z) {
    if (math.isComplex(z)) {
      // Avoid division by zero
      if (z.re === 0 && z.im === 0) {
        return math.complex(Infinity, Infinity);
      }

      const tanhZ = custom_tanh(z);
      const one = math.complex(1, 0);
      return math.divide(one, tanhZ);
    } else {
      // Avoid division by zero
      if (z === 0) {
        return Infinity;
      }

      return 1 / math.tanh(z);
    }
  }
