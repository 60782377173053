
import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const SimplifiedFullCellBattery = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = 'Simplified full-cell of battery with intercalation cathode and metal anode';

    const circuitExpression = '(Rser + pow(pow((1 / (s * Cdl_c)), -1) + pow((Rct_c + (1 / (s * Cd_c))), -1), -1) + pow(pow((1 / (s * Cdl_a)), -1) + pow((Rct_a + pow(pow(Rd_a, -1) + pow((1 / (s * Cd_a)), -1), -1)), -1), -1) + (s * L1))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 0.04, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct_c', initialValue: 0.4, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl_c', initialValue: 0.01, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct_a', initialValue: 0.2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl_a', initialValue: 0.001, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'L1', initialValue: 5e-6, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd_c', initialValue: 2000, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd_a', initialValue: 1000, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rd_a', initialValue: 0.5, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = $$R_{ser} + \\frac{1}{\\frac{1}{s \\cdot C_{dl_{c}}} + \\frac{1}{R_{ct_{c}} + \\frac{1}{s \\cdot C_{d_{c}}}}} + \\frac{1}{\\frac{1}{s \\cdot C_{dl_{a}}} + \\frac{1}{R_{ct_{a}} + \\frac{1}{\\frac{1}{R_{d_{a}}} + \\frac{1}{s \\cdot C_{d_{a}}}}}} + s \\cdot L_{1}$$';


    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/simplifiedfullcellbattery_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/simplifiedfullcellbattery.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                This circuit can be used to anlyze impedance spectra of
                Lead-acid, NiCd, NiMH batteries because both of them  have
                intercalation type of cathode and metall-type of anode. It represents
                simplified version of the&nbsp;
                <span onClick={() => handleNavigate('Batteries and Fuel Cells', 'Full cell battery')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Full cell battery
                </span>&nbsp;where distributed elements representing diffusion are replaced with their low-frequency limiting values, corresponding to
                discrete resistors and capacitors.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance of electrolyte in separator and of current collectors
                    </li>
                    <li>
                        <Latex>{`$$R_{ct_{c}}$$`}</Latex> is sum of the charge-transfer resistance of cathode and the resistance
                        representing low-frequecy limit of the finite-lenght reflective diffusion of intercalation inside cathode particles.
                    </li>
                    <li>
                        <Latex>{`$$C_{dl_{c}}$$`}</Latex> is double-layer capacitance of cathode
                    </li>

                    <li>
                        <Latex>{`$$R_{ct_{a}}$$`}</Latex> is the charge-transfer resistance of anode
                    </li>
                    <li>
                        <Latex>{`$$C_{dl_{a}}$$`}</Latex> is double-layer capacitance of anode
                    </li>
                    <li>
                        <Latex>{`$$R_{d_{a}}$$`}</Latex> is low-frequency limit of the impedance of finite-length transmissive diffusion
                        through passivating layer on anode interface and <Latex>{`$$C_{d_{a}}$$`}</Latex> is capacitance due to dielectric relaxation of passivating layer.
                    </li>
                    <li>
                        <Latex>{`$$L$$`}</Latex> is the gemetric inductivity of winding, or inductivity due to artifacts often observed with measurements on batteries.
                    </li>

                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};

export default SimplifiedFullCellBattery;
