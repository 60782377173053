import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const InfiniteLengthWarburg = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Infinite length diffusion (Warburg impedance)';

    const circuitExpression = '(sqrt(Rd / (s * Cd)))';

    const circuitVariables = [
        { name: 'Rd', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = \\sqrt\\frac{Rd}{s \\cdot Cd}';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };



    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                Linear diffusion from a medium which length can be approximated as infinite, results
                in impedance analogous to that of an infinite length transmission line composed of capacitors and resistors.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/warburg_impedance_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/warburg_impedance.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$$W$$`}</Latex>
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$C_{d} (F/cm)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega /cm)$$`}</Latex>
                    </li>
                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\frac{dE}{dc} (volt~cm^{3}) = \\frac{F\\cdot n \\cdot S}{C_{d}}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$D (sec^{-1} cm^{3}) = \\frac{1}{C_{d} \\cdot R_{d}} $$`}</Latex>
                </Box>
                Where S is the geometric electrode area in <Latex>{`$$cm^{2}$$`}</Latex>, n - the number of electrons participating in the reaction and c  - the volume concentration in <Latex>{`$$mol \\cdot cm^{3}$$`}</Latex>.

            </Typography>


            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default InfiniteLengthWarburg;
