import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';

const TutorialRunningFit = ({ closeModal, selectInnerSection, selectInnerSubsection }) => {

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="body1" color="text.secondary" component="div">
                There are basically four methods which can be used to input an impedance model. These methods will be described below.
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                1. Choosing the weighting method
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                There are currently four weighting methods which can be used.
                <ol style={{ listStyleType: 'upper-roman' }}>
                    <li>
                        Modulus: the weights are equal for the imaginary and real parts of each point of the impedance. It is represented as:
                        <Box display="flex" justifyContent="center" my={2}>
                        <Latex>{`$$w_{j, \\text{Re}} = w_{j, \\text{Im}} = \\frac{1}{Z^{2}_{j, \\text{Re}} + Z^{2}_{j, \\text{Im}}} $$`}</Latex>
                        </Box>
                        This makes the weights proportional to the inverse of the modulus of the impedance. It is also the default weighting method.

                    </li>
                    <li>Proportional: the weights are chosen by:
                        <Box display="flex" justifyContent="center" my={2}>
                            <Latex>{`$$w_{i} = \\frac{1}{Z^{2}_{i}} $$`}</Latex>
                        </Box>
                    </li>
                    <li>Unit: the weights for all points are assigned as 1:
                        <Box display="flex" justifyContent="center" my={2}>
                            <Latex>{`$$w_{i} = 1 $$`}</Latex>
                        </Box>
                        Here it is assumed that variances are the same for all data points [1].
                    </li>
                    <li>Sigma: the true variances are known.:
                        <Box display="flex" justifyContent="center" my={2}>
                            <Latex>{`$$w_{i} = \\frac{1}{\\sigma^{2}_{i}} $$`}</Latex>
                        </Box>
                    </li>

                </ol>
                When a file containing an array of sigma values is uses, the number of rows must correspond to the number of frequencies and the number of columns must correspond to the
                number of spectra/data loaded.
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                2. Selecting the Fit Type
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                Depending on the complexity of your data and the level of precision you desire, you can select between various fit types. Here's how to go about it:

            <ol style={{ listStyleType: 'upper-roman' }}>
                <li>Fit Sequential: This is the default fitting method. Click on the 'Fit Sequential' button to select it. The button will be highlighted when active.</li>
                <li>Fit Simultaneous - No Smoothing': This is a more complex fit type that performs a simultaneous fit without any smoothing. Select it by clicking on the 'Fit Simultaneous - No smoothing' button.</li>
                <li>Fit Simultaneous: This is similar to the previous one but includes smoothing in its fitting process. To select it, click on the 'Fit Simultaneous' button.</li>
                <li>Fit Spline: This fitting type uses spline regression. To select it, click on the 'Fit Spline' button. When you select this method, an additional field labeled 'df' (degrees of freedom) will appear. The minimum 'df' value is 4, and you can increase it according to your needs. Remember that a higher 'df' value results in a more flexible fit, but it might also overfit the data (Coming soon).</li>
            </ol>

            Remember, the fit type you choose significantly affects the result of your data fitting. Ensure to pick the one that best suits your data and modeling objectives.
            After selecting the fit type, click on the "Fit" button to proceed with the fitting process.
            </Typography>

            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                3. WRSS, WRMS, AIC
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                After the fitting process is concluded, three values are presented

            <ol style={{ listStyleType: 'upper-roman' }}>
                <li>WRSS: This is the weighted residual sum of squares.</li>
                <li>WRMS: This is the weighted residual mean square (also sometimes referred to as the reduced chisquare).</li>
                <li>AIC: This is the Akaike Information Criterion.</li>

            </ol>
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h4>References</h4>
                <ul>
                    <li>
                        [1] A. Battistel, G. Du, and F. La Mantia, “On the Analysis of Non-stationary Impedance Spectra,” Electroanalysis, vol. 28, no. 10, pp. 2346-2353, 2016.

                    </li>
                    <li>
                        [2] M. Ingdal, R. Johnsen, and D. A. Harrington, "The Akaike information criterion in weighted regression of immittance data," in Electrochimica Acta, vol. 317, pp. 648-653, Sep. 2019.

                    </li>

                </ul>
            </Typography>

        </Box>
    );
};


export default TutorialRunningFit;
