
import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const ElectrochemicalSensor = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal }) => {
    const circuitName = 'Electrolyte-Insulator-Semiconductor Sensor';

    const circuitExpression = '(Rser + pow(pow(R1, -1) + pow((1 / (s * C1)), -1), -1) + (1 / (s * C2)))'

    const circuitVariables = [
        { name: 'Rser', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'R1', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C1', initialValue: 0.001, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
        { name: 'C2', initialValue: 10.0, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{\\frac{1}{R_1} + s \\cdot C_1} + \\frac{1}{s \\cdot C_2}';


    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/electrochemical_sensor_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
            Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/electrochemical_sensor.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>

            <Typography variant="subtitle1" color="text.secondary">
                Electrolyte-Insulator-Semiconductor Sensor can be described by
                above equivalent circuit proposed by Diot at al. [1].
                This sensor is an analog of a field effect transistor (MOSFET) where the
                role of grate is played by Ion Sensitive membrane, therefore charge due to adsorbed
                ions, proportional to pH, controls the current between source and drain
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Physical meaning of parameters
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph component="div">
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance between tip of reference electrode and ion-sensitive membrane
                    </li>
                    <li>
                        <Latex>{`$$R_1$$`}</Latex> is the combined resistance of semiconductor and semiconductor-insulator contact.
                    </li>
                    <li>
                        <Latex>{`$$C_1$$`}</Latex> is the capacitance of semiconductor-insulator contact.
                    </li>
                    <li>
                        <Latex>{`$$C_2$$`}</Latex> is the capacitance of insulator.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] J.L.Diot, J. Joseph, J.R.Matin, and P.Clechet,
                        "pH Dependence of the <Latex>{`$$Si/Si0_{2}$$`}</Latex> Interface State Density for EOS
                        Systems, Electroanal. Chem. 193 (1956) 75"
                    </li>
                </ul>
            </Typography>
            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

    };


export default ElectrochemicalSensor;
