import React, { useEffect, useRef } from 'react';
import Plotly from 'plotly.js-dist';
import { Box } from '@mui/material';


const BodePlot = ({ data = [], fitData = null, indices=[], indicesName = "" }) => {
  const bodePlotRef = useRef(null);

  useEffect(() => {
    const plotElement = document.getElementById('bode-plot');

    const handleResize = () => {
      const update = { height: window.innerHeight * 0.5 }; // Update the height based on the screen height
      Plotly.update('bode-plot', {}, update);
    };
    const drawBodePlot = (data, fitData, indices, indicesName) => {
      const generatedIndices = indices.length
      ? indices
      : Array.from({ length: data.length }, (_, i) => i);

      const processedData = Array.isArray(data[0]) ? data : [data];

      if (processedData.length === 0 || processedData[0].length === 0) {
        Plotly.purge(document.getElementById('bode-plot'));
        return;
      }

      const phaseTraces = processedData.map((dataset, datasetIndex) => {
        const x = [];
        const y = [];
        const z = [];

        dataset.forEach((dataPoint) => {
          x.push(generatedIndices[datasetIndex]);
          y.push(dataPoint.freq);
          z.push(dataPoint.phase);
        });

        return {
          x,
          y,
          z,
          mode: 'markers',
          type: 'scatter3d',
          name: processedData.length > 1 ? `Data ${datasetIndex + 1}` : 'Phase',
          marker: { size: 2 },
          legendgroup: `group-${datasetIndex}`,
        };
      });

      let fitPhaseTraces = [];
      // In this modification, if data[datasetIndex] does not exist,
      // it will log an error message to the console, and the resulting trace will be null.
      // Then, the filter function will remove any null values from the fitTraces array.
      if (fitData && fitData.length > 0) {
        const processedFitData = Array.isArray(fitData[0]) ? fitData : [fitData];
        fitPhaseTraces = processedFitData.map((dataset, datasetIndex) => {
          if (!data[datasetIndex]) {
            console.error(`Data at index ${datasetIndex} not found.`);
            return null;
          }

          const x = [];
          const y = [];
          const z = [];

          dataset.forEach((dataPoint) => {
            x.push(generatedIndices[datasetIndex]);
            y.push(dataPoint.freq);
            z.push(dataPoint.phase);
          });

          return {
            x,
            y,
            z,
            mode: 'lines',
            type: 'scatter3d',
            name: `Fit ${datasetIndex + 1}`,
            line: { color: 'red' },
            legendgroup: `group-${datasetIndex}`,
          };
        }).filter(trace => trace !== null);
      }


      const traces = [...phaseTraces, ...fitPhaseTraces];

      const layout = {
        title: 'Bode Phase Plot',
        scene: {
          xaxis: {
            title: indicesName ? indicesName : 'Index',
          },
          yaxis: { title: 'Frequency (Hz)',  type: 'log', autorange: true, },
          zaxis: {
            title: {
              text: 'Phase (°)',
              font: {
                family: 'Arial Unicode MS',
              },
            },
            autorange: 'reversed', // Invert the y-axis
          },
        },
        showlegend: true,
        legend: {
          x: 0.9, // Adjust x position of the legend
          y: 0.9, // Adjust y position of the legend
          xanchor: 'right',
          yanchor: 'top',
          bgcolor: 'rgba(255, 255, 255, 0.7)', // Add background color to the legend with some transparency
          font: {
            size: 10, // Adjust the font size of the legend
          },
          itemclick: 'toggleothers',
          itemdoubleclick: 'toggle',
        },
        hovermode: 'closest',
        margin: {
          l: 20, // Reduce left margin
          r: 20, // Reduce right margin
          b: 20, // Reduce bottom margin
          t: 30, // Reduce top margin
          pad: 4,
        },
        height: window.innerHeight * 0.5, // Adjust the height based on the screen height
        width: '100%',
      };

      const config = {
        responsive: true,
        displaylogo: false,
      };




      window.addEventListener('resize', handleResize);

      Plotly.react(plotElement, traces, layout, config);
      };

      if (data.length === 0 || data[0].length === 0) {
        return;
      }
      drawBodePlot(data, fitData, bodePlotRef.current);

      return () => {
        Plotly.purge(plotElement); // Add this line to purge the WebGL context properly
        window.removeEventListener('resize', handleResize); // Clean up the event listener
      };
    }, [data, fitData, indices, indicesName]);



  return (
    <div>
      <Box mx="auto" width="100%">
        <div
          id="bode-plot"
          ref={bodePlotRef}
          style={{ width: '100%', height: '100%' }}
          ></div>
        </Box>
      </div>
    );
  };

export default BodePlot;


