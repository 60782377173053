import React, { useState } from 'react';
import { Input } from '@mui/material';
import IndicesButton from './IndicesButton';

const SpectraIndices = ({ setIndices, setIndicesName,  fileData, isFileUploaded}) => {

  const [fileInputValue, setFileInputValue] = useState('');

  const handleIndicesUpload = (e) => {
    const files = e.target.files;
    if (!files.length) return;

    const file = files[0];
    const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "").replace(/ /g, "_");

    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
      const lineDelimiter = /\r?\n/;
      const valueDelimiter = /[ ,\t]+/;
      const lines = fileContent.trim().split(lineDelimiter);

      // Check if the first row contains headers (non-numeric values)
      const firstRowColumns = lines[0].split(valueDelimiter);
      const firstValue = Number(firstRowColumns[0]);
      const hasHeader = isNaN(firstValue);
      const dataLines = hasHeader ? lines.slice(1) : lines;

      let values = dataLines
        .map((line) => line.split(valueDelimiter).map(Number))
        .flat();

      if (!values || !values.length) {
        alert('Error: Invalid file format.');
        setFileInputValue(''); // Reset the file input value
        return;
      }
      // Check that the length of the indices matches the number of files loaded
      if (fileData.length > 0 && values.length !== fileData.length) {
        alert('Error: The number of indices does not match the number of files loaded.');
        setFileInputValue(''); // Reset the file input value
        return;
        }

      // Set the state variable with the new indices data
      setIndices(values);
      setIndicesName(fileNameWithoutExtension); // Save the name using setIndicesName
    };

    reader.readAsText(file);
    setFileInputValue(''); // Reset the file input value
  };



  return (
    <div>
      <Input
        type="file"
        accept=".txt, .csv"
        value={fileInputValue}
        onChange={handleIndicesUpload}
        style={{ display: 'none' }}
        id="indices-upload"
      />
      <label htmlFor="indices-upload">
        <IndicesButton
          component="span"
          color="primary"
          variant="outlined"
          disabled={!isFileUploaded}
        >
          Upload Indices
        </IndicesButton>
      </label>
    </div>
  );
};

export default SpectraIndices;
