import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
// import Latex from 'react-latex';
import 'katex/dist/katex.min.css';

const TutorialSimulateData = ({ closeModal, selectInnerSection, selectInnerSubsection }) => {

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="body1" color="text.secondary" component="div">
                The simulate button opens up an interface that serves two purposes.
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                1. Simulating the response of an impedance model (this functionality can only be used when the impedance model is valid).
                In the absence of of data, frequencies are generated using the frequency range and points per decade specified by the user.
                Please follow the steps below:

            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                <ol style={{ listStyleType: 'upper-roman' }}>
                    <li>Input the frequency ranges for the simulation denoted by Fmin and Fmax and the number of points per decade denoted by Pperd.</li>
                    <li>As you change the values of the Fmin, Fmax, Pperd and the calues of the parameters, watch the impedance simulated in real time.</li>
                </ol>
                Please Note that you cannot change the names of the parameters here. To do that you have to open the edit parameters dialog.
            </Typography>
            <Divider/>

            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                2. Selecting appropriate initial values for the parameters of the impedance model.
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                When a valid model and data are entered into the application, the simulator can be used to fine tune the initial guess of the parameters in other to obtain a good fit.
                All you need do is to keep adjusting the values of the parameters until the simulated impedance represented by dashed lines matches the data represented by blue circles.
                Once you are satisfied with the fit, click on the accept button to accept the values of the parameters.
                This action modifies the original values of the parameters in the edit parameters dialog. You can then proceed to fit the data.
                <p>Please&nbsp;
                <span onClick={() => document.getElementById('my-video').play()} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    click here
                </span>&nbsp;
                to see how the Simulator works.</p>
            </Typography>
            <video id="my-video" width="100%" controls>
             <source src={'/circuit_images/simulator_video.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

        </Box>
    );
};


export default TutorialSimulateData;
