import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const HavriliakNegami = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Havriliak-Negami element.';

    const circuitExpression = '(R / pow(1 + (pow(s * C, U)), phi))';

    const LatexExpression = 'Z(s) = \\frac{R}{\\left[1 + (C \\cdot s)^{U}\\right]^{\\phi}}';

    const circuitVariables = [
        { name: 'R', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'phi', initialValue: 0.4, lowerBound: 1e-1, upperBound: 1.01, smoothing: '0.0' },
        { name: 'U', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },

    ];

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };


    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                This element allows one to obtain the frequency dependence characteristic for homogeneous and inhomogeneous transmission lines with resistive termination.
                Such spectra can be observed experimentally inncases of infinite diffusion from inhomogeneous porous electrode or through inhomogeneous porous layers.
                Detailed discussion of this element is is given in [1]. The case of <Latex>{`$$\\phi = 0.5$$`}</Latex> corresponds to a finite length transmissive Warburg impedance.
                If U is fixed equal to 1, this distrbuted element is also know an the "Cole-Davidson" response.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/hav_neg.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                HavNeg
            </Typography>


            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$\\phi$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$U$$`}</Latex>
                    </li>
                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                R is a resistor parallel to a distributed element. In some cases it can be interpreted as the charge transfer resistance of an
                electrochemical reaction <Latex>{`$$R_{ct}$$`}</Latex>, or as diffusion related impedance <Latex>{`$$D=\\frac{1}{RC}$$`}</Latex>. If the process is diffusion-like,
                using the distributed_elements associated with diffusion is preferable for quantitative analysis. For the C parameter, the is no possible direct conversion.
                In a case where <Latex>{`$$\\phi > 0.5$$`}</Latex>, C closely resembles a capacitor.
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] Havriliak and Negami, J.Polym.Sci. C14(1966) 99
                    </li>

                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default HavriliakNegami;
