import React, { useState, useRef, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import SharedButton from './SharedButton';

const FormulaSaver = ({ formulaName, setFormulaName, expression, variables, isDisabled}) => {
  const [saveStatus, setSaveStatus] = useState('idle');
  const [isTimedOut, setIsTimedOut] = useState(false);
  const saveStatusRef = useRef(saveStatus);

  saveStatusRef.current = saveStatus;

  useEffect(() => {
    if (saveStatus === 'editing') {
      const timer = setTimeout(() => {
        setIsTimedOut(true);
        setSaveStatus('idle');
      }, 10000); // set timeout for 10 seconds

      return () => clearTimeout(timer);
    }
  }, [saveStatus]);

  useEffect(() => {
    if (isTimedOut) {
      const timer = setTimeout(() => {
        setIsTimedOut(false);
      }, 2000); // show 'Timed out' message for 2 seconds

      return () => clearTimeout(timer);
    }
  }, [isTimedOut]);

  const handleSaveFormula = () => {
    if (saveStatusRef.current === 'idle') {
      setSaveStatus('editing');
      return;
    }

    if (saveStatusRef.current === 'editing' && !isTimedOut) {
      const settings = {
        expression,
        variables,
      };
      const file = new Blob([JSON.stringify(settings, null, 2)], {
        type: 'application/json',
      });
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${formulaName}.json`;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);

      setSaveStatus('saved');
      setTimeout(() => {
        setSaveStatus('idle');
      }, 3000);
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <Grid container direction="column" alignItems="flex-start">
      {saveStatus === 'editing' && (
          <TextField
            value={formulaName}
            onChange={(e) => setFormulaName(e.target.value)}
            label="Formula name"
            sx={{
              '& .MuiInputBase-input': {
                height: '30px',
                padding: '0px 10px',
                fontSize: '16px',
              }
            }}
          />
        )}
        <Grid item>
          <SharedButton
            sx={{
              mt: 1,
              width: '200px',
              '&.Mui-disabled': {
                  backgroundColor: '#d3d3d3', // Light gray background
                  color: '#808080' // Dark gray text
              }
              }}
            onClick={handleSaveFormula}
            component="button" // Add this to render SharedButton as a button element
            disabled={isDisabled}
          >
            {saveStatus === 'saved' ? 'Saved!' : (saveStatus === 'editing' ? 'Confirm Save' : 'Save Model')}
          </SharedButton>
          {isTimedOut && <div style={{ color: 'red' }}>Timed out, please try again.</div>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormulaSaver;
