import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const Fletcher = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);


    const circuitName = 'Activated adsorption at a homogeneous porous surface';

    const circuitExpression = '((1 + Tanh(sqrt((Ri + Re) / (4 * (1 / ((s * Cd) + 1 / (Rp + 1 / (s * Cp))))))) / (sqrt((Ri + Re) / (4 * (1 / ((s * Cd) + 1 / (Rp + 1 / (s * Cp)))))))) * ((Ri * Re) / (Ri + Re)) + sqrt(Ri * Re * (1 / ((s * Cd) + 1 / (Rp + 1 / (s * Cp))))) * Coth(2 * (sqrt((Ri + Re) / (4 * (1 / ((s * Cd) + 1 / (Rp + 1 / (s * Cp)))))))))';

    const LatexExpression = 'Z(s) = \\frac{1 + tanh(\\sqrt{\\frac{Ri + Re}{4(\\frac{1}{sCd} + \\frac{1}{Rp + \\frac{1}{sCp}})}})}{\\sqrt{\\frac{Ri + Re}{4(\\frac{1}{sCd} + \\frac{1}{Rp + \\frac{1}{sCp}})}}}) * (\\frac{Ri Re}{Ri + Re}) + \\sqrt{Ri Re * (\\frac{1}{sCd} + \\frac{1}{Rp + \\frac{1}{sCp}})} * coth(2 * (\\sqrt{\\frac{Ri + Re}{4(\\frac{1}{sCd} + \\frac{1}{Rp + \\frac{1}{sCp}})}})';

    const circuitVariables = [
        { name: 'Re', initialValue: 0.1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Ri', initialValue: 0.9, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rp', initialValue: 0.01, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cp', initialValue: 200, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);



    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                Treatment of porous electrochemically active materials, as shown in&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Activated adsorption')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                activated adsorption at a homogeneous porous surface
                </span>,
                combines both the ionic and electronic conductivity of the porous material into one summary specific conductivity <Latex>{`$$\\rho$$`}</Latex>.
                Indeed, in the analysis of real impedance spectra, it is difficult to distinguish the contributions of ionic and electronic conductivities, particularly because
                one of them is usually small compared to the other. The considerable presence of the second conductivity is indicated by a non-zero real part of the impedance at the
                high-frequency limit. This contribution in real experimental spectra is difficult to distinguish from the serial resistance caused by different processes.
                <br/>
                Nevertheless, theoretical treatment considering both conductivities exists and is given using different approaches in [1] and [2]. The fitting of synthetic data
                generated by the resulting impedance function shows that in cases of low noise, it is indeed possible to distinguish the cases where both ionic and
                electronic resistances are present from the case where the serial resistance is in series with a&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Wphase')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Wphase element
                </span>&nbsp;
                (i.e., only one resistance is present). Note that although the ratio between the contributing resistivities can be exactly determined by the fitting procedure,
                there is no way to ascertain which of the resistances is ionic and which is electronic. It is usually safe to assume that in highly porous systems like
                electrochemically deposited polymers, or in ceramic composites like Li-ion battery cathodes, the smaller one is the ionic resistance, whereas in compressed
                and highly conductive systems like carbon anodes, the electronic resistance is smaller. Here, we use the treatment by Fletcher [2], as equations
                are presented using the electrical elements formalism.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/fletcher_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />


            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/fletcher.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Fletch
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function in terms of electric parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">

                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z(s) = \\left(1 + \\frac{tanh(\\mu(s))}{\\mu(s)} \\right) \\cdot \\frac{R_{i}R_{e}}{R_{i}+R_{e}} + \\sqrt{\\left( R_{i} + R_{e} \\right) \\cdot Z_{s}(s) \\cdot coth(2\\mu(s))}$$`}</Latex>
                </Box>
                <br/>
                    here
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z(s) = \\frac{1}{sC_{dl} + \\frac{1}{R_{ct} + \\frac{1}{sC_{s}}}}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$\\mu (s) = \\sqrt{\\frac{R_{i} + R_{e}}{4 \\cdot Z_{s}(s)}}$$`}</Latex>
                </Box>

            </Typography>


            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{e} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{i} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{p} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{p} (F)$$`}</Latex>
                    </li>
                </ul>
            </Typography>


            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">

                Fit parameters can be transformed into specific electronic resistance of porous electrode <Latex>{`$$\\rho_{E} (\\Omega~cm)$$`}</Latex>, specific ionic resistance
                of porous electrode <Latex>{`$$\\rho_{I} (\\Omega~cm)$$`}</Latex>,
                specific double-layer capacitance of a porous electrode <Latex>{`$$C_{dl} (F/cm^{2})$$`}</Latex>, specific charge transfer resistance <Latex>{`$$R_{ct} (\\Omega cm^{2})$$`}</Latex>
                and specific pseudo-capacitance of activated adsorption <Latex>{`$$C_{s} (F/cm^{2})$$`}</Latex> as follows.
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\rho_{E} = \\frac{R_{d}}{d} \\cdot S$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\rho_{I} = \\frac{R_{d}}{d} \\cdot S$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$C_{dl} = \\frac{C_{d}}{S_{true}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$R_{ct} = R_{p} \\cdot S_{true} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$C_{s} = \\frac{C_{p}}{S_{true}} $$`}</Latex>
                </Box>
                Here <Latex>{`$$S_{true}$$`}</Latex> is the true electrochemically active area of the electrode and <Latex>{`$$d$$`}</Latex> is the thickness of the prorous layer.
                <Latex>{`$$S_{true}$$`}</Latex> can be obtained independently from BET analysis (usually an overestimation),
                or calculated based on knowledge of the true density of the particles <Latex>{`$$\\sigma_{true}$$`}</Latex>, packing density of the layer <Latex>{`$$\\sigma$$`}</Latex>, and
                the characteristic thickness <Latex>{`$$l$$`}</Latex> of the particles as shown below:
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$S_{true} = \\frac{S \\cdot d}{l} \\cdot \\frac{\\sigma}{\\sigma_{true}}$$`}</Latex>
                </Box>
                <br/>
                This relations is correct for flake-type particles. For spherical particles it can be accordingly modified.
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] G.Paasch, K.Micka, P.Gersdorf, Electochim. Acta 38 (1993) 2653
                    </li>
                    <li>
                        [2] S.Fletcher, J.Electroanal.Chem. 337 (1992) 127
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default Fletcher;
