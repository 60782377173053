import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import { Button } from '@mui/material';


const Weighting = ({ setWeighting, fileData, setIsSigmaSet }) => {
  const [selectedWeightMethod, setSelectedWeightMethod] = useState('modulus');
  const [showSigmaUpload, setShowSigmaUpload] = useState(false);
  const [fileInputValue, setFileInputValue] = useState('');

  const handleWeightMethodChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedWeightMethod(selectedOption);
    if (selectedOption === 'sigma') {
      setShowSigmaUpload(true);
      setIsSigmaSet(false);
    } else {
      setShowSigmaUpload(false);
      setWeighting({ type: selectedOption });
      setIsSigmaSet(true);
    }
  };

  const handleSigmaUpload = (e) => {
    const files = e.target.files;
    if (!files.length) return;

    const file = files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
      const delimiter = /[ ,\t]+/;
      let rows = fileContent.trim().split('\n');
      const numColumns = rows[0].split(delimiter).length;
      const isMatrix = rows.every(
        (row) => row.split(delimiter).length === numColumns
      );

      if (!isMatrix) {
        alert('Error: Sigma data is not a matrix.');
        setFileInputValue(''); // Reset the file input value
        setIsSigmaSet(false);
        return;
      }

      const sigmaData = rows.map((line) => line.split(delimiter).map(Number));

      // Check if the shape of sigma data matches the shape of fileData
      if (
        fileData.length > 0 &&
        (sigmaData.length !== fileData[0].length ||
          sigmaData[0].length !== fileData.length)
      ) {
        alert('Error: Shape mismatch between sigma data and impedance data.');
        setFileInputValue(''); // Reset the file input value
        setIsSigmaSet(false);
        return;
      }

      // Set the state variable with the new sigma data
      setWeighting({ type: 'sigma', data: sigmaData });
      setIsSigmaSet(true);
    };

    reader.readAsText(file);
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel htmlFor="weighting-method">Select weighting method:</InputLabel>
        <Select
          id="weighting-method"
          value={selectedWeightMethod}
          onChange={handleWeightMethodChange}
          label="Select weighting method:"
        >
          <MenuItem value="unit">Unit</MenuItem>
          <MenuItem value="proportional">Proportional</MenuItem>
          <MenuItem value="modulus">Modulus</MenuItem>
          <MenuItem value="sigma">Sigma</MenuItem>
        </Select>
      </FormControl>
      {showSigmaUpload && (
        <div>
            <input
            id="sigma-file-upload"
            type="file"
            accept=".txt,.csv"
            value={fileInputValue}
            onChange={handleSigmaUpload}
            style={{ display: 'none' }}
            />
            <label htmlFor="sigma-file-upload">
            <Button variant="contained" component="span">
                Upload Sigma Data
            </Button>
            </label>
        </div>
        )}
    </div>
  );
};

export default Weighting;
