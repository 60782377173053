// import React from 'react';
// import { Paper } from '@mui/material';
// import Draggable from 'react-draggable';

// const DraggablePaper = React.forwardRef((props, ref) => {
//   const nodeRef = React.useRef(null);

//   return (
//     <Draggable nodeRef={nodeRef} defaultPosition={{ x: 0, y: 0 }}>
//       <Paper ref={nodeRef} {...props} />
//     </Draggable>
//   );
// });

// export default DraggablePaper;

// import React from 'react';
// import { Paper, Box } from '@mui/material';
// import Draggable from 'react-draggable';
// import { styled } from '@mui/system';

// const DraggableHandleStyles = {
//   cursor: 'move',
// };

// const DraggableHandle = styled(Box)(DraggableHandleStyles);

// const DraggablePaper = React.forwardRef((props, ref) => {
//   const nodeRef = React.useRef(null);

//   return (
//     <Draggable nodeRef={nodeRef} defaultPosition={{ x: 0, y: 0 }} handle=".draggable-handle">
//       <Paper ref={nodeRef} {...props}>
//         <DraggableHandle className="draggable-handle" />
//         {props.children}
//       </Paper>
//     </Draggable>
//   );
// });

// export { DraggableHandle };
// export default DraggablePaper;

import React from 'react';
import { Paper, Box } from '@mui/material';
import Draggable from 'react-draggable';
import { styled } from '@mui/system';

const DraggableHandleStyles = {
  cursor: 'move',
};

const DraggableHandle = styled(Box)(DraggableHandleStyles);

const DraggablePaper = React.forwardRef((props, ref) => {
  const nodeRef = React.useRef(null);

  return (
    <Draggable nodeRef={nodeRef} defaultPosition={{ x: 0, y: 0 }} handle=".modal-title">
      <Paper ref={nodeRef} {...props}>
        <DraggableHandle className="modal-title" />
        {props.children}
      </Paper>
    </Draggable>
  );
});

export { DraggableHandle };
export default DraggablePaper;



/* Sure! The main difference is that the updated version uses the nodeRef prop in the Draggable component and passes it to the Paper component.

In the original version, you were not using the nodeRef prop at all. This would cause a warning when using React in Strict mode because ReactDOM.findDOMNode() is deprecated.

The updated version creates a nodeRef using React.useRef(null) and passes it to both the Draggable component and the Paper component. This way, the Draggable component can access the underlying DOM node directly through the nodeRef, without relying on ReactDOM.findDOMNode().

Here is a brief explanation of the changes:

const nodeRef = React.useRef(null);: This line creates a new ref with a null initial value.
Draggable nodeRef={nodeRef}: The nodeRef is passed to the Draggable component as a prop. This allows the Draggable component to access the DOM node directly.
<Paper ref={nodeRef} {...props} />: The nodeRef is also passed to the Paper component using the ref prop. This ensures that the nodeRef points to the actual DOM node of the Paper component, which is necessary for the Draggable component to work properly.
By making these changes, the updated version eliminates the need for ReactDOM.findDOMNode() and ensures compatibility with React Strict mode.*/