import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
// import Latex from 'react-latex';
import 'katex/dist/katex.min.css';

const TutorialEnteringModel = ({ closeModal, selectInnerSection, selectInnerSubsection }) => {


    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="body1" color="text.secondary" component="div">
                There are basically four methods which can be used to input an impedance model. These methods will be described below.
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                1. Using the "Impedance Expression" Input Field
                </Typography>
                <Divider/>
                <Typography variant="body1" color="text.secondary" component="div">
                The "Impedance Expression" field allows you to input an arbitrary impedance expression:
                <ol>
                    <li>Navigate to the 'Impedance Expression' text input field and type out your impedance expression.</li>
                    <li>The operators specified under "Operators" are allowed, and you can type them out directly instead of using the buttons. Please note that "Tanh" and "Coth" must have their first letters capitalized.</li>
                    <li>After inputting your expression, proceed to add your parameters.</li>
                    <li>Click on the 'Edit Parameters' button to add the parameters of the model. Ensure that the names of the circuit elements, which represent the parameters of the impedance model, match the names inputted in the "Edit Parameters" interface.</li>
                    <li>Once a match between the names of the parameters is established, the resulting Python expression will appear on the screen.</li>
                </ol>
                </Typography>

            <Divider/>

            <Divider/>
            <Typography variant="h5" color="text.primary" gutterBottom>
                2. Using the circuit composer
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                The circuit composer is a user interface that allows the user to build a circuit model by connecting elements in series and parallel.

                To connect
                The circuit composer is the recommended method for building a circuit model, as it is the most intuitive and user-friendly method.
                <ol style={{ listStyleType: 'upper-roman' }}>
                    <li>Click on the "compose" button to open up the circuit composer.</li>
                    <li>You will see several components to choose from.</li>
                    <li>To connect elements in series simply prefix the elements with "ser" followed by a bracket containing the elements which should be separated by a comma for instance ser(R_0, C_0) denotes series combination of a resistor and capacitor.</li>
                    <li>This series combination is composed by typing ser, followed by a "bracket", then clicking on "R" followed by a comma and then clicking on "C" and then closing the bracket.</li>
                    <li>Note that the elements should be selected from the existing elements and not typed as indexes are automatically assigned by the application.</li>
                    <li>Similarly to connect elements in parallel simply prefix the elements with "par" followed by a bracket containing the elements which should be separated by a comma.</li>
                    <li>To view the circuit, click on the "view circuit button". It is also possible to view the tree-like representation of the circuit by clicking on "view tree"</li>
                    <li>Once you are satisfied, click on the submit button and close the composer. The impedance expression of the composed circuit now appears on the impedance expression input field</li>
                    <li>On the impedance expression input field, the names of the circuit elements can be edited. Take note that the names of the circuit elements (which represent the parameters of the impedance model) must match the names inputed in the "edit parameters" interface </li>
                    <li>Once a match between the names of the parameters is established, the resulting python expression becomes appears on the screen.</li>
                    <li>At this stage the "Fit" buttom becomes active.</li>
                </ol>
                <p>Please&nbsp;
                <span onClick={() => document.getElementById('composer-video').play()} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    click here
                </span>&nbsp;
                to see how to use the circuit composer.</p>
            </Typography>
            <video id="composer-video" width="100%" controls>
             <source src={'/circuit_images/using_the_composer_01.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                3. Using the circuit library
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                The "From Library" button opens up the cicuit library explorer. Once an model is selected, it is loaded into the application and appears on the impedance expression input field which allows for further editing.
                <ol style={{ listStyleType: 'upper-roman' }}>
                    <li>Navigate to the 'Data' section in the application.</li>
                    <li>Select the 'Load Fit Data' option.</li>
                    <li>In the dialog box that appears, navigate to the location of your saved fit data on your device. The file should be in JSON format, previously exported from this application.</li>
                    <li>Select the file and click 'Open'.</li>
                    <li>The application will then load and validate the data, and if successful, you will see your fit data populated in the respective fields of the application.</li>
                </ol>
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                4. Loading a Model from a File
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                To load a previously saved model, you can follow the steps below:

                <ol>
                    <li>Click on the menu icon located at the top-right corner of the application, beside "FitMyEIS", to open the file drawer.</li>
                    <li>Click on the 'Load Impedance Model' button. This will open a file dialog box.</li>
                    <li>Navigate to the location of your saved model file. The file should be in JSON format containing your previously saved model information, including the expression and the variables list.</li>
                    <li>Select the file and click 'Open'. The application will automatically parse the information in the file and load the model parameters into the appropriate fields.</li>
                    <li>If there's any issue with the file or its format, an error message will be displayed to help you resolve the issue.</li>
                </ol>

                Now, your pre-saved model has been loaded and you can proceed with your analysis.
            </Typography>

        </Box>
    );
};

export default TutorialEnteringModel;
