import React, {useState} from 'react';
import 'react-resizable/css/styles.css';
import DraggablePaper from './DraggablePaper';
import ComposerButton from './ComposerButton';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
  Modal,
  Paper,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/system';

const DraggablePaperStyles = {
  width: '100%',
  maxWidth: '100%',
};

const ModalBoxStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  padding: 2,
  overflowY: 'auto',
  backgroundColor: 'background.paper',
  boxShadow: 5,
  borderRadius: 1,
};

const ContainerStyles = {
  maxHeight: '70vh',
  overflowY: 'auto',
  padding: 2,
};

const textFieldStyle = {
  marginRight: '10px',
  width: '100px',
  // marginBottom: '4px',
  // marginLeft: '6px',
  fontSize: '14px',
};
const addButtonStyle = {
  height: '40px',
  width: '100%',
  // marginLeft: '16px',
};

const inputStyle = {
  padding: '4px 8px',
  paddingTop: '6px',
  paddingBottom: '8px',
  marginLeft: '10px', // Add margin to the entry inside the box
  fontSize: '14px',
};


// Convert your makeStyles classes to styled components
const DraggablePaperStyled = styled(DraggablePaper)(DraggablePaperStyles);
const ModalBox = styled(Box)(ModalBoxStyles);
const Container = styled(Box)(ContainerStyles);

const Variable = ({
  openModal,
  handleCloseModal,
  variables,
  handleVariableAddition,
  handleVariableChange,
  handleVariableEdit,
  handleVariableBlur,
  handleVariableDeletion,
  RpSelected,
}) => {
  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleShowAdvanced = () => {
    setShowAdvanced(!showAdvanced);
  };


  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{ display: 'flex', alignItems: 'center', marginTop: '5vh' }}
    >
      <DraggablePaperStyled handle=".modal-title">
        <ModalBox>
          <Paper>
            <Container>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} flexWrap="wrap">
              <div className="modal-title">
                <Typography variant="h6" id="modal-title">
                  Parameters:
                </Typography>
              </div>
              <ComposerButton
                onClick={handleShowAdvanced}
                style={{ width: '200px', marginTop: '10px', marginRight: '10px', marginBottom: '10px', marginLeft: '10px' }}>
                  {showAdvanced ? "Hide Advanced Options" : "Show Advanced Options"}
              </ComposerButton>
            </Box>
              {variables.map((variable, index) => (
                <Box display="flex" alignItems="center" mb={1} justifyContent="space-between" flexWrap="wrap" width="100%" key={index}>
                  <TextField
                    label="Name"
                    value={variable.name}
                    onChange={(e) =>
                        handleVariableChange(index, { name: e.target.value })
                    }
                    style={textFieldStyle}
                    margin="dense"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                  />
                  <TextField
                    label="Initial Value"
                    value={variable.initialValue}
                    onChange={(e) => {
                        handleVariableChange(index, { initialValue: e.target.value });
                        handleVariableEdit();
                    }}
                    onBlur={() => handleVariableBlur(index, 'initialValue')}
                    style={textFieldStyle}
                    margin="dense"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                  />
                  <TextField
                    label="Lower Bound"
                    value={variable.lowerBound}
                    onChange={(e) => {
                        handleVariableChange(index, { lowerBound: e.target.value });
                        handleVariableEdit();
                    }}
                    onBlur={() => handleVariableBlur(index, 'lowerBound')}
                    style={textFieldStyle}
                    margin="dense"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                  />
                  <TextField
                    label="Upper Bound"
                    value={variable.upperBound}
                    onChange={(e) => {
                        handleVariableChange(index, { upperBound: e.target.value });
                        handleVariableEdit();
                    }}
                    onBlur={() => handleVariableBlur(index, 'upperBound')}
                    style={textFieldStyle}
                    margin="dense"
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                  />

                  {showAdvanced && (
                    <>
                      <TextField
                        label="Smoothing"
                        value={variable.smoothing}
                        onChange={(e) =>
                          handleVariableChange(index, { smoothing: e.target.value })
                        }
                        onBlur={() => handleVariableBlur(index, 'smoothing')}
                        style={textFieldStyle}
                        margin="dense"
                        variant="outlined"
                        inputProps={{ style: inputStyle }}
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={variable.transmissionLine === 'multiply'}
                            onChange={(e) =>
                              handleVariableChange(index, {
                                transmissionLine: e.target.checked ? 'multiply' : undefined,
                              })
                            }
                          />
                        }
                        label="M"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={variable.transmissionLine === 'divide'}
                            onChange={(e) =>
                              handleVariableChange(index, {
                                transmissionLine: e.target.checked ? 'divide' : undefined,
                              })
                            }
                          />
                        }
                        label="D"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={variable.transmissionLineRole === 'Rp'}
                            onChange={(e) =>
                              handleVariableChange(index, { transmissionLineRole: e.target.checked ? 'Rp' : null })
                            }
                            disabled={RpSelected && variable.transmissionLineRole !== 'Rp'}
                          />
                        }
                        label="Rp"
                      />
                    </>
                  )}
                  <IconButton
                    color="primary"
                    onClick={() => handleVariableDeletion(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
              <Button
                variant="outlined"
                color="primary"
                onClick={handleVariableAddition}
                startIcon={<AddCircleOutlineIcon />}
                style={addButtonStyle}
              >
                Add Parameter
              </Button>
              <Button onClick={handleCloseModal} color="primary">
                Close
              </Button>
            </Container>
          </Paper>
        </ModalBox>
      </DraggablePaperStyled>
    </Modal>
  );
};

export default Variable;