
import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const TwoRCElements = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Two RC Elements in Series';

    const circuitExpression = '(Rser + pow(pow(R1, -1) + pow((1 / (s * C1)), -1), -1) + pow(pow(R2, -1) + pow((1 / (s * C2)), -1), -1))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 8.0e-2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'R1', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C1', initialValue: 1, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
        { name: 'R2', initialValue: 5, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C2', initialValue: 5, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{\\frac{1}{R_1} + (s \\cdot C_1)} + \\frac{1}{\\frac{1}{R_2} + (s \\cdot C_2)}';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };



    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/two_rc_elements_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/two_rc_elements.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                Serial-layer model of a two-component ceramic
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                This equivalent circuit was proposed by Maxwell [1]. Two phases are assumed to build thin layers parallel to the electrodes.
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                High frequency response of a photoelectrochemical solar cell was analysed using this circuit [2]. The smallest time constant
                is associated with space charge layer in the semiconductor, while the largest time constant is associated with the process on
                the semiconductor/electrolyte interface.
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>References:</h3>
                <ul>
                    <li>
                        [1] J.C.Maxwell, A Treatise on Electricity and Magnetism, 1881, 2nd ed., Clarendon Press, Oxford.
                    </li>
                    <li>
                        [2] W.M.Shen, M.Tomkiewicz, and D.Cahen, Impedance Study of Surface Optimization of <Latex>{`$$\\text{n-CuInS}_{2}$$`}</Latex> &nbsp;
                        in Photoelectrochemical Solar Cells, J. Electrochem. Soc. 133, (1986) 112
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};

export default TwoRCElements;
