import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const SimplifiedInterc = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);


    const circuitName = 'Simplified case valid for spectra restricted to high-frequencies.';

    const circuitExpression = '(sqrt(Rm * ((1 / ((s * C1) + 1 / R1)) + (1 / ((s * Cdl) + 1 / Rct)) + (1 / ((s * Cd) + 1 / Rs)))) * Coth(sqrt(Rm / ((1 / ((s * C1) + 1 / R1)) + (1 / ((s * Cdl) + 1 / Rct)) + (1 / ((s * Cd) + 1 / Rs))))))';

    const LatexExpression = 'Z(s) = \\sqrt{R_{m} \\cdot \\frac{1}{sC_{1} + \\frac{1}{R_{1}}} + \\frac{1}{sC_{dl} + \\frac{1}{R_{ct}}} + \\frac{1}{sC_{d} + \\frac{1}{R_{s}}}} \\coth\\left[ \\sqrt{\\frac{R_{m}}{\\frac{1}{sC_{1} + \\frac{1}{R_{1}}} + \\frac{1}{sC_{dl} + \\frac{1}{R_{ct}}} + \\frac{1}{sC_{d} + \\frac{1}{R_{s}}}}} \\right]';

    const circuitVariables = [
        { name: 'Rm', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct', initialValue: 0.2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rs', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 100, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'R1', initialValue: 0.02, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C1', initialValue: 0.1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                In some cases the low-frequency impedance spectrum and OCV/charge relation are not available but high-frequency components are of interest for the
                investigation of interfacial properties of intercalation materials or for quality control. In this case it is possible to similify the element&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Interc')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Trphase
                </span>.&nbsp;
                In this case it is possible to simplify above model by combining all low-frequency related components into one RC element, where the resistor <Latex>{`$$R_{s}$$`}</Latex> gives
                the underestimation of the summary resistive hindrance due to diffusion and phase formation and capacitor is near to the true value of diffusion pseudocapacitance, <Latex>{`$$C_{d}$$`}</Latex>.

                <br/>
                After such simplification it is possible to obtain values of high frequency parameters from the impedance spectrum limited to <Latex>{`$$10~mHz$$`}</Latex> or even <Latex>{`$$100~mHz$$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/transhf_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />
            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/transhf.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Transhf
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function in terms of electric parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">

                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z(s) = \\sqrt{R_{m} \\cdot Z_{s}(s)} \\coth\\left[ \\sqrt{\\frac{R_{m}}{Z_{s}(s)}} \\right]$$`}</Latex>
                </Box>
                <br/>
                    where
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z_{s}(s) = \\frac{1}{sC_{1} + \\frac{1}{R_{1}}} + \\frac{1}{sC_{dl} + \\frac{1}{R_{ct}}} + \\frac{1}{sC_{d} + \\frac{1}{R_{s}}}$$`}</Latex>
                </Box>
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{m} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{ct} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{dl} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{s} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{1} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{1} (F)$$`}</Latex>
                    </li>
                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                Same as for <span onClick={() => handleNavigate('Distributed Elements', 'Interc')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Interc
                </span>,&nbsp; except for <Latex>{`$$R_{s}$$`}</Latex> which does not have an exact physical meaning but represent underestimation
                of the diffusion resistance, <Latex>{`$$R_{s}$$`}</Latex>.

            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default SimplifiedInterc;
