
import { styled } from '@mui/system';
import SharedButton from './SharedButton';
import { alpha } from '@mui/material/styles';

const StyledCancelButton = styled(SharedButton)(({ theme }) => ({
  color: '#fff',
  backgroundColor: alpha(theme.palette.error.main, 0.3),
  width: '100%',
  '&:hover': {
    backgroundColor: alpha(theme.palette.error.dark, 0.5),
    color: theme.palette.success.dark,
  },
  '&:active': {
    backgroundColor: alpha(theme.palette.error.dark, 0.7),
  },
}));

const CancelButton = (props) => <StyledCancelButton {...props} />;

export default CancelButton;


