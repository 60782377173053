// import React from 'react';
// import { Box, TextField } from '@mui/material';

// const CustomInput = React.forwardRef(({ value, ...props }, ref) => (
//   <div
//     ref={ref}
//     {...props}
//     dangerouslySetInnerHTML={{ __html: value }}
//     contentEditable={false}
//     style={{
//       border: '1px solid rgba(0, 0, 0, 0.23)',
//       borderRadius: '4px',
//       padding: '18.5px 14px',
//       width: '100%',
//       height: '100px', // Set a fixed height
//       overflowY: 'auto', // Enable vertical scrolling when content overflows
//       overflowX: 'auto', // Enable horizontal scrolling when content overflows
//       fontFamily: 'monospace',
//       whiteSpace: 'pre-wrap',
//       backgroundColor: '#f5f5f5',
//       fontSize: '0.8125rem',
//     }}
//   />
// ));

// const HighlightedExpressionBox = ({ highlightedExpression }) => {
//   return (
//     <Box width="100%">
//       <TextField
//         label="Highlighted Expression"
//         value={highlightedExpression}
//         InputProps={{ readOnly: true, inputComponent: CustomInput }}
//         fullWidth
//         multiline
//         rows={4}
//         variant="outlined"
//         size="small"
//         inputProps={{
//           style: {
//             fontFamily: 'monospace',
//             whiteSpace: 'pre-wrap',
//           },
//         }}
//       />
//     </Box>
//   );
// };

// export default HighlightedExpressionBox;


import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';

const CustomInput = React.forwardRef(({ value, onFocus, onBlur, ...props }, ref) => (
  <div
    ref={ref}
    {...props}
    dangerouslySetInnerHTML={{ __html: value }}
    contentEditable={false}
    onFocus={onFocus}
    onBlur={onBlur}
    style={{
      border: 'none',
      outline: 'none',
      padding: '18.5px 14px',
      width: '100%',
      height: '100px', // Set a fixed height
      overflowY: 'auto', // Enable vertical scrolling when content overflows
      overflowX: 'auto', // Enable horizontal scrolling when content overflows
      fontFamily: 'monospace',
      whiteSpace: 'pre-wrap',
      fontSize: '0.8125rem',
    }}
  />
));

const HighlightedExpressionBox = ({ highlightedExpression }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Box width="100%">
      <TextField
        label="Highlighted Expression"
        value={highlightedExpression}
        InputProps={{
          readOnly: true,
          inputComponent: CustomInput,
          onFocus: handleFocus,
          onBlur: handleBlur,
        }}
        fullWidth
        multiline
        rows={4}
        variant="outlined"
        size="small"
        inputProps={{
          style: {
            fontFamily: 'monospace',
            whiteSpace: 'pre-wrap',
          },
        }}
      />
      <Box
        borderRadius={4}
        border={isFocused ? '2px solid #3f51b5' : '1px solid rgba(0, 0, 0, 0.23)'}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
        }}
      />
    </Box>
  );
};

export default HighlightedExpressionBox;
