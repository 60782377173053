import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Footer from './Footer';
import { styled } from '@mui/system';

const Main = styled('div')({
  flex: '1 0 auto', // This allows the component to grow and shrink, but not shrink below its content's height
});

const Layout = () => {
  return (
    <Box display="block" minHeight="100vh">
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </Box>
  );
};

export default Layout;
