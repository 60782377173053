import React, { useState } from 'react';
import { Grid } from '@mui/material';
import SharedButton from './SharedButton';

const FitDataSaver = ({ fitDataName, setFitDataName, weighting, selectedFit, fitParams, fitParamsError, paramNames, fileData,loadedFileNames, plotData, fitData, wrss, wrms, aic, expression, isDisabled, formulaName, setFormulaName, variables }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [saveButtonText, setSaveButtonText] = useState("Save Project (JSON)");

  const handleSaveFitData = () => {
    if (!inputVisible) {
      setInputVisible(true);
      return;
    }

    const settings = {
      weighting,
      selectedFit,
      fitParams,
      fitParamsError,
      paramNames,
      fileData,
      loadedFileNames,
      plotData,
      fitData,
      wrss,
      wrms,
      aic,
      expression,
      formulaName,
      variables
    };
    const file = new Blob([JSON.stringify(settings, null, 2)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fitDataName}.json`;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 100);

    setSaveButtonText("Saved!");
    setTimeout(() => {
      setInputVisible(false);
      setSaveButtonText("Save Project (JSON)");
    }, 2000);
  };

  return (
    <Grid item xs={12} sm={6}>
      <Grid container direction="column" alignItems="flex-start">
        {inputVisible && (
          <Grid item>
            <input
              type="text"
              value={fitDataName}
              onChange={(e) => setFitDataName(e.target.value)}
              placeholder="Enter fit data name"
              style={{
                color: fitDataName === 'fitdata' ? 'rgba(0, 0, 0, 0.5)' : 'inherit',
              }}
              onFocus={() => {
                if (fitDataName === 'fitdata') {
                  setFitDataName('');
                }
              }}
              onBlur={() => {
                if (fitDataName === '') {
                  setFitDataName('fitdata');
                }
              }}
            />
          </Grid>
        )}
        <Grid item>
          <SharedButton
            sx={{
              mt: 1,
              width: '200px',
              '&.Mui-disabled': {
                  backgroundColor: '#d3d3d3', // Light gray background
                  color: '#808080' // Dark gray text
              }
              }}
            onClick={handleSaveFitData}
            component="button" // Add this to render SharedButton as a button element

            disabled={isDisabled}
          >
            {saveButtonText}
          </SharedButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FitDataSaver;
