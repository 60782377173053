import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';

import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const HomogeneousAdsorption = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = 'Adsorption at a homogeneous porous surface (open-circuit terminated transmission line)';

    const circuitExpression = '(sqrt(Rd / (s * Cd)) * Coth(sqrt(Rd * (s * Cd))))';

    const circuitVariables = [
        { name: 'Rd', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 300, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];


    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);




    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                This distributed element can be used as a first approximation of electrochemical capacitors, where the activation energy of electron transfer is negligible (carbon-type).
                Element is electrically equivalent to the&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Wo')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    planar finite-length reflective diffusion.
                </span>
                &nbsp;Therefore the same function can be used in this case. However, the parameters have a different electrochemical meaning and can be transformed into specific resistance
                of a porous electrode <Latex>{`$$\\rho (\\Omega~cm)$$`}</Latex> and surface capacitance of a porous electrode <Latex>{`$$C_{s} (F/cm^{2})$$`}</Latex> as shown below.

            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" component="div">
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\rho = \\frac{R_{d}}{d} \\cdot S$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$C_{s} = \\frac{C_{d}}{S_{true}} $$`}</Latex>
                </Box>
                Here <Latex>{`$$S_{true}$$`}</Latex> is the true electrochemically active area of the electrode and <Latex>{`$$d$$`}</Latex> is the thickness of theprorous layer.
                <Latex>{`$$S_{true}$$`}</Latex> can be obtained independently from BET analysis (usually an overestimation),
                or calculated based on knowledge of the true density of the particles <Latex>{`$$\\sigma_{true}$$`}</Latex>, packing density of the layer <Latex>{`$$\\sigma$$`}</Latex>, and
                the characteristic thickness <Latex>{`$$l$$`}</Latex> of the particles as shown below:
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$S_{true} = \\frac{S \\cdot d}{l} \\cdot \\frac{\\sigma}{\\sigma_{true}}$$`}</Latex>
                </Box>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default HomogeneousAdsorption;
