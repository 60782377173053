import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const EARC = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = <>Reflective dielectric system (<Latex inline>{`$\\varepsilon$`}</Latex>ARC)</>;

    const circuitExpression = '(Rser + pow(pow((1 / (s * Cinf)), -1) + pow(((1 / (s * C0)) + (1 / (pow(s, phi) * C))), -1), -1))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 8.0e-2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C', initialValue: 0.0001, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'phi', initialValue: 0.1, lowerBound: 1e-1, upperBound: 1.01, smoothing: '0.0' },
        { name: 'Cinf', initialValue: 10e-9, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C0', initialValue: 0.001, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{\\text{ser}} + \\frac{1}{s C_{\\text{inf}} + \\frac{1}{\\frac{1}{s C_{0}} + \\frac{1}{s^{\\phi}C}}}';


    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                Detailed description of distributed element CPE is given&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'CPE')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    here
                </span>,&nbsp;

            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/earc_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/earc.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Impedance of a <strong>dielectric material compressed between 2 electrodes</strong> proposed by Cole [1]
                It usually gives an impedance spectrum in form of an arc in <Latex>{`$$\\varepsilon$$`}</Latex>-presentation. This is a generalization of the&nbsp;
                <span onClick={() => handleNavigate('Simple Circuits', 'Debye model')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Debye
                </span>&nbsp;circuit for the case when dielectric losses can not be described with a simple resistor but rather with a frequency-dependent impedance
                corresponding to distribution of orientation energies of dipoles.
                <br/>
                This circuit is not physically realizable for all frequencies and can therefore describe experimental data only in narrow frequency range.
                Nevertheless it is widely used to analyse impedance spectra. See&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'CPE')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    CPE
                </span>&nbsp;for details on CPE-distributed element.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance in the electrolyte pathway that is not compensated for by the reference electrode
                    </li>
                    <li>
                        <Latex>{`$$C_{inf}$$`}</Latex> corresponds to instantaneous polarisation due to deformation of electronic shells. It is related to dielectric constant of material as:
                        <Box display="flex" justifyContent="center" my={2}>
                            <Latex>{`$$\\varepsilon = \\frac{C_{inf}}{\\varepsilon_{0}}$$`}</Latex>
                        </Box>
                    </li>
                    <li>
                        <Latex>{`$$C_{0}$$`}</Latex> corresponds to orientation of dipoles in electric field. It is related to static dielectric constant of material as
                        <Box display="flex" justifyContent="center" my={2}>
                        <Latex>{`$$\\varepsilon_{s} = \\frac{C_{2}}{\\varepsilon_{0}} + \\varepsilon$$`}</Latex>
                        </Box>
                    </li>
                    <li>
                        <Latex>{`$$\\text{CPE}$$`}</Latex> represents the frequency-dependent dielectric losses. For cases when <Latex>{`$$\\phi=0$$`}</Latex>, it simplifies to a simple resistor
                        which is related to the time constant of relaxation as:
                        <Box display="flex" justifyContent="center" my={2}>
                        <Latex>{`$$\\tau = RC_{0}$$`}</Latex>
                        </Box>
                    </li>
                    <li>
                        Other parameters have their usual meanings as given&nbsp;
                        <span onClick={() => handleNavigate('Distributed Elements', 'CPE')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            here
                        </span>.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] K.S.Cole and R.H. Cole, Dispersion and Absorption in Dielectrics. I. Alternating Current Characteristics, J. Chem. Phys. 9 (1941) 341
                    </li>

                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};


export default EARC;
