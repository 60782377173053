
export const getImpedance = (elementType, index, s) => {
  switch (elementType) {
    case 'R':
      return `${elementType}_${index}`;
    case 'L':
      return `(${s} * ${elementType}_${index})`;
    case 'C':
      return `(1 / (${s} * ${elementType}_${index}))`;
    case 'CPE':
      return `(1 / (pow(${s}, phicpe_${index}) * Ccpe_${index}))`;
    case 'W':
      return `(sqrt(Rd_${index} / (${s} * Cd_${index})))`;
    case 'Wo':
      return `(sqrt(Rdopen_${index} / (${s} * Cdopen_${index})) * Coth(sqrt(Rdopen_${index} * (${s} * Cdopen_${index}))))`;
    case 'Ws':
      return `(sqrt(Rdshort_${index} / (${s} * Cdshort_${index})) * Tanh(sqrt(Rdshort_${index} * (${s} * Cdshort_${index}))))`;
    case 'G':
      return `(sqrt(Rdger_${index} / (${s} * Cdger_${index})) * Tanh(sqrt(Rdger_${index} * (${s} * Cdger_${index}))))`;
    case 'Wphase':
      return `(sqrt(Rdphase_${index} / ((${s} * Cdphase_${index}) + (1 / (Rpphase_${index} + pow(${s} * Cpphase_${index}, -1))))) * Coth(sqrt(Rdphase_${index} * ((${s} * Cdphase_${index}) + (1 / (Rpphase_${index} + pow(${s} * Cpphase_${index}, -1)))))))`;
    case 'Wgen':
      return `(sqrt(Rdgen_${index} / (${s} * Cdgen_${index})) * ((1 + ((((1 / (s * Ctgen_${index} + pow(Rtgen_${index}, -1))) / sqrt(Rdgen_${index} / (${s} * Cdgen_${index}))) - 1) / (((1 / (s * Ctgen_${index} + pow(Rtgen_${index}, -1))) / sqrt(Rdgen_${index} / (${s} * Cdgen_${index}))) + 1))  * exp(-2 * (sqrt(Rdgen_${index} * (${s} * Cdgen_${index})))))/((1 - ((((1 / (s * Ctgen_${index} + pow(Rtgen_${index}, -1))) / sqrt(Rdgen_${index} / (${s} * Cdgen_${index}))) - 1) / (((1 / (s * Ctgen_${index} + pow(Rtgen_${index}, -1))) / sqrt(Rdgen_${index} / (${s} * Cdgen_${index}))) + 1))  * exp(-2 * (sqrt(Rdgen_${index} * (${s} * Cdgen_${index}))))))))`;
    case 'Wspinf':
      return `(Rdspinf_${index} / (1 + sqrt(Rdspinf_${index} * (${s} * 3 * Cdspinf_${index}))))`;
    case 'Wsplim':
      return `(Tanh(sqrt(Rdsplim_${index} * (${s} * 3 * Cdsplim_${index}))) / (sqrt((${s} * 3 * Cdsplim_${index}) / Rdsplim_${index}) - (1 / Rdsplim_${index}) * Tanh(sqrt(Rdsplim_${index} * (${s} * 3 * Cdsplim_${index})))))`;
    case 'Wcyinf':
      return `(((K0(sqrt(2 * Rdcyinf_${index} * (${s} * Cdcyinf_${index})))) / ((sqrt(2 * Rdcyinf_${index} * (${s} * Cdcyinf_${index}))) * K1(sqrt(2 * Rdcyinf_${index} * (${s} * Cdcyinf_${index}))))) * Rdcyinf_${index})`;
    case 'Wcylim':
      return `(((I0(sqrt(2 * Rdcylim_${index} * (${s} * Cdcylim_${index})))) / ((sqrt(2 * Rdcylim_${index} * (${s} * Cdcylim_${index}))) * I1(sqrt(2 * Rdcylim_${index} * (${s} * Cdcylim_${index}))))) * Rdcylim_${index})`;
    case 'Paasch':
      return `(sqrt(Rmpaasch_${index} / ((${s} * Cdlpaasch_${index}) + 1 / (Rctpaasch_${index} + (sqrt(Rdpaasch_${index} / (${s} * Cdpaasch_${index})) * Coth(sqrt(Rdpaasch_${index} * (${s} * Cdpaasch_${index}))))))) * Coth(sqrt(Rmpaasch_${index} * ((${s} * Cdlpaasch_${index}) + 1 / (Rctpaasch_${index} + (sqrt(Rdpaasch_${index} / (${s} * Cdpaasch_${index})) * Coth(sqrt(Rdpaasch_${index} * (${s} * Cdpaasch_${index})))))))))`;
    case 'Fletch':
      return `((1 + Tanh(sqrt((Rifletch_${index} + Refletch_${index}) / (4 * (1 / ((${s} * Cdfletch_${index}) + 1 / (Rpfletch_${index} + 1 / (${s} * Cpfletch_${index}))))))) / (sqrt((Rifletch_${index} + Refletch_${index}) / (4 * (1 / ((${s} * Cdfletch_${index}) + 1 / (Rpfletch_${index} + 1 / (${s} * Cpfletch_${index})))))))) * ((Rifletch_${index} * Refletch_${index}) / (Rifletch_${index} + Refletch_${index})) + sqrt(Rifletch_${index} * Refletch_${index} * (1 / ((${s} * Cdfletch_${index}) + 1 / (Rpfletch_${index} + 1 / (${s} * Cpfletch_${index}))))) * Coth(2 * (sqrt((Rifletch_${index} + Refletch_${index}) / (4 * (1 / ((${s} * Cdfletch_${index}) + 1 / (Rpfletch_${index} + 1 / (${s} * Cpfletch_${index})))))))))`;
    case 'Trsph':
      return `(sqrt(Rmtrsph_${index} / ((${s} * Cdltrsph_${index}) + 1 / (Rcttrsph_${index} + ((Tanh(sqrt(Rdtrsph_${index} * (${s} * 3 * Cdtrsph_${index})))) / ((sqrt((${s} * 3 * Cdtrsph_${index}) / Rdtrsph_${index}) - (1 / Rdtrsph_${index}) * Tanh(sqrt(Rdtrsph_${index} * (${s} * 3 * Cdtrsph_${index}))))))))) * (Coth(sqrt(Rmtrsph_${index} * ((${s} * Cdltrsph_${index}) + 1 / (Rcttrsph_${index} + ((Tanh(sqrt(Rdtrsph_${index} * (${s} * 3 * Cdtrsph_${index})))) / ((sqrt((${s} * 3 * Cdtrsph_${index}) / Rdtrsph_${index}) - (1 / Rdtrsph_${index}) * Tanh(sqrt(Rdtrsph_${index} * (${s} * 3 * Cdtrsph_${index}))))))))))))`;
    case 'Trphase':
      return `(sqrt(Rmtrphase_${index} / ((${s} * Cdltrphase_${index}) + 1 / (Rcttrphase_${index} + (sqrt(Rdtrphase_${index} / ((${s} * Cdtrphase_${index}) + 1 / (Rptrphase_${index} + 1 / (${s} * Cptrphase_${index})))) * Coth(sqrt(Rdtrphase_${index} * (((${s} * Cdtrphase_${index}) + 1 / (Rptrphase_${index} + 1 / (${s} * Cptrphase_${index})))))))))) * Coth(sqrt(Rmtrphase_${index} * ((${s} * Cdltrphase_${index}) + 1 / (Rcttrphase_${index} + (sqrt(Rdtrphase_${index} / ((${s} * Cdtrphase_${index}) + 1 / (Rptrphase_${index} + 1 / (${s} * Cptrphase_${index})))) * Coth(sqrt(Rdtrphase_${index} * (((${s} * Cdtrphase_${index}) + 1 / (Rptrphase_${index} + 1 / (${s} * Cptrphase_${index}))))))))))))`;
    case 'Interc':
      return `(sqrt(Rminterc_${index} * ((1 / ((${s} * Cdlinterc_${index}) + 1 / (Rctinterc_${index} + (sqrt(Rdinterc_${index} / ((${s} * Cdinterc_${index}) + 1 / (Rpinterc_${index} + (1 / (${s} * Cpinterc_${index}))))) * Coth(sqrt(Rdinterc_${index} * ((${s} * Cdinterc_${index}) + 1 / (Rpinterc_${index} + (1 / (${s} * Cpinterc_${index})))))))))) + (1 / ((${s} * C1interc_${index}) + 1 / R1interc_${index})))) * Coth(sqrt(Rminterc_${index} / ((1 / ((${s} * Cdlinterc_${index}) + 1 / (Rctinterc_${index} + (sqrt(Rdinterc_${index} / ((${s} * Cdinterc_${index}) + 1 / (Rpinterc_${index} + (1 / (${s} * Cpinterc_${index}))))) * Coth(sqrt(Rdinterc_${index} * ((${s} * Cdinterc_${index}) + 1 / (Rpinterc_${index} + (1 / (${s} * Cpinterc_${index})))))))))) + (1 / ((${s} * C1interc_${index}) + 1 / R1interc_${index}))))))`;
    case 'Transhf':
      return `(sqrt(Rmtrhf_${index} * ((1 / ((${s} * C1trhf_${index}) + 1 / R1trhf_${index})) + (1 / ((${s} * Cdltrhf_${index}) + 1 / Rcttrhf_${index})) + (1 / ((${s} * Cdtrhf_${index}) + 1 / Rstrhf_${index})))) * Coth(sqrt(Rmtrhf_${index} / ((1 / ((${s} * C1trhf_${index}) + 1 / R1trhf_${index})) + (1 / ((${s} * Cdltrhf_${index}) + 1 / Rcttrhf_${index})) + (1 / ((${s} * Cdtrhf_${index}) + 1 / Rstrhf_${index}))))))`;
    case 'Cole':
      return `(Rcole_${index} / ((Rcole_${index} * (pow(${s}, phicole_${index}) * Ccole_${index})) + 1))`;
    case 'DavCole':
      return `((Rdavcole_${index} * (1 + Udavcole_${index})) / (1 + Udavcole_${index} * (1 + (pow(${s} * Cdavcole_${index}, phidavcole_${index})))))`;
    case 'HavNeg':
      return `(Rhavneg_${index} / pow(1 + (pow(${s} * Chavneg_${index}, Uhavneg_${index})), phihavneg_${index}))`;
    case 'NUD':
      return `(pow(Rdnud_${index} / (${s} * Cpsnud_${index}), phinud_${index}) / Tanh(pow(Rdnud_${index} * (${s} * Cpsnud_${index}), phinud_${index})))`;
    case 'FCP':
      return `(sqrt(Rpfcp_${index} / (${s} * Cdlfcp_${index} + (1 / Rctfcp_${index}) + (1 / (Rafcp_${index} + 1 / (${s} * Cafcp_${index}))))) / Tanh(sqrt(Rpfcp_${index} * (${s} * Cdlfcp_${index} + (1 / Rctfcp_${index}) + (1 / (Rafcp_${index} + 1 / (${s} * Cafcp_${index})))))))`
    case 'Armstr':
      return `(1 / ((1 / Rinf1_${index}) + (k_${index} / (k_${index} + ${s})) * (1 / R1_${index}) + (1 / Rinf2_${index}) * (${s} / (k_${index} + ${s})) + (${s} * Cinf_${index})))`
    default:
      return '';
  }
};



export const getAdmittance = (elementType, index, s) => {
  const impedanceExpression = getImpedance(elementType, index, s);
  return `pow(${impedanceExpression}, -1)`;
};


const tokenize = (circuitString) => {
  const regex = /(?:ser|par)|([A-Za-z]+(?:_)(\d+))|\(|\)/g;
  const tokens = [];
  let match;

  let openParenthesesCount = 0;

  while ((match = regex.exec(circuitString)) !== null) {
    if (match[0] === 'ser') {
      tokens.push({ type: 'SERIES' });
    } else if (match[0] === 'par') {
      tokens.push({ type: 'PARALLEL' });
    } else if (match[1]) {
      const valueEndIndex = match[1].indexOf("_");

      tokens.push({
        type: "ELEMENT",
        value: match[1].substring(0, valueEndIndex),
        index: parseInt(match[2], 10),
      });
    } else if (match[0] === '(') {
      tokens.push({ type: '(' });
      openParenthesesCount++;
    } else if (match[0] === ')') {
      tokens.push({ type: ')' });
      openParenthesesCount--;
      if (openParenthesesCount < 0) {
        throw new Error('Unmatched closing parenthesis found in the input.');
      }
    }
  }

  if (openParenthesesCount > 0) {
    throw new Error('Unmatched opening parenthesis found in the input.');
  }

  return tokens;
};




const buildCircuitTree = (tokens) => {
  const root = { type: null, children: [] };
  let current = root;
  const stack = [];
  const subCircuitStack = [];

  tokens.forEach((token) => {
    if (token.type === "PARALLEL" || token.type === "SERIES") {
      const newNode = {
        type: token.type,
        children: [],
      };

      if (subCircuitStack.length > 0) {
        subCircuitStack[subCircuitStack.length - 1].children.push(newNode);
      } else {
        current.children.push(newNode);
      }

      stack.push(current);
      current = newNode;
      subCircuitStack.push(newNode);
    } else if (token.type === "ELEMENT") {
      if (subCircuitStack.length > 0) {
        subCircuitStack[subCircuitStack.length - 1].children.push(token);
      } else {
        current.children.push(token);
      }
    } else if (token.type === '(') {
      stack.push(current);
    } else if (token.type === ')') {
      current = stack.pop();
      subCircuitStack.pop();
    }
  });

  return root;
};



const evaluateCircuitTree = (node) => {
  if (!node) {
    return "";
  }

  if (node.type === null) {
    if (node.children.length === 1) {
      return evaluateCircuitTree(node.children[0]);
    } else {
      // Handle the case when there are multiple children or no children at the root level
      throw new Error("Root node with null type must have exactly one child.");
    }
  }

  if (node.type === "ELEMENT") {
    const impedance = getImpedance(node.value, node.index, "s");
    return impedance;
  } else if (node.type === "PARALLEL" || node.type === "SERIES") {
    const expressions = node.children.map(evaluateCircuitTree);

    if (node.type === "PARALLEL") {
      const admittances = expressions.map(exp => `pow(${exp}, -1)`);
      return `pow(${admittances.join(" + ")}, -1)`;
    } else {
      return `(${expressions.join(" + ")})`;
    }
  }

  return "";
};



export const parseCircuit = (circuitString) => {
  try {
    const tokens = tokenize(circuitString);
    // console.log(`Tokens: ${JSON.stringify(tokens)}`);

    const circuitTree = buildCircuitTree(tokens);
    // console.log(`Circuit Tree: ${JSON.stringify(circuitTree)}`);

    // Check if the circuit tree is empty and throw an error if it is
    if (circuitTree.type === null && circuitTree.children.length === 0) {
      throw new Error("Circuit tree is empty.");
    }

    const impedance = evaluateCircuitTree(circuitTree);

    // Return both the impedance expression and the circuitTree object
    return { impedance, circuitTree, error: null };
  } catch (error) {
    console.error(`Error parsing circuit: ${error.message}`);
    return { impedance: '', circuitTree: null, error: error.message };
  }
};

