import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Link } from '@mui/material';
import Cookies from 'js-cookie';

const TermsConditionsPopup = () => {
    const [open, setOpen] = useState(!Cookies.get('termsAccepted'));

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            // Don't close the dialog if it was a backdrop click or escape key press
            return;
        }

        Cookies.set('termsAccepted', 'true', { expires: 365 }); // The cookie will expire after 365 days.
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogContent>
                <p>By continuing with this website, you agree with the <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer">privacy policy</Link>.</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Agree</Button>
            </DialogActions>
        </Dialog>
    );
}

export default TermsConditionsPopup;
