import axios from "axios";
import React, { useState, useEffect, useCallback, useRef} from 'react';
import { Link } from 'react-router-dom';
import { create, all } from 'mathjs';



import {
  Box,
  Button,
  Drawer,
  Typography,
  Grid,
  List,
  ListItem,
  Modal,
  Paper,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import DraggablePaper from './DraggablePaper';
import { Close as CloseIcon } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from "@mui/material/IconButton";
import Divider from '@mui/material/Divider';
import { Container, Dialog, DialogTitle,  DialogActions } from '@mui/material';
import { LinearProgress } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import { custom_tanh, custom_coth } from '../custom_funtions/mathHelpers';


// Data loading and saving components
import ImpedanceLoader from './ImpedanceLoader';
import LoadCustomData from "./LoadCustomData";
import ImpedanceDataEditor from "./ImpedanceDataEditor";
import FitDataLoader from './FitDataLoader';
import FormulaLoader from './FormulaLoader';
import FormulaSaver from './FormulaSaver';
import FitDataSaver from './FitDataSaver';
import FitDataCSVSaver from './FitDataCSVSaver';

// Fit parameters component
import ParamTable from './ParamTable';

// Plot Components
import NyquistPlot from './NyquistPlot';
import AdmittancePlot from './AdmittancePlot';
import BodePlot from './BodePlot';
import BodeMagPlot from './BodeMagPlot';
import PlotParams from './PlotParams';
import SingleSpectraPlot from "./SingleSpectraPlot";


// Styling components
import theme from './theme';
import SharedButton from './SharedButton';
import ResetButton from './ResetButton';
import CancelButton from './CancelButton';
import CircuitElementInputButton from './CircuitElementInputButton';
import PreLoadedCircuitsButton from './PreLoadedCircuitsButton';
import AddButton from './AddButton';
import ComposerButton from './ComposerButton';
import ColorChangeAppBar from './ColorChangeAppBar';
import './BatchFitter.css';

// Main components
import Tutorial from './Tutorials';
import CircuitComposer from './CircuitComposer';
import CircuitLibrary from './CircuitLibrary';
import Simulator from './Simulator';
import Operators from './Operators';
import Variable from './Variable';
import Weighting from './Weighting';
import FitOptions from './FitOptions';
import ExpressionTextField from './ExpressionTextField';
import PythonFunctionBox from './PythonFunctionBox';
import HighlightedExpressionBox from './HighlightedExpressionBox';
import Metrics from './Metrics';

import SpectraIndices from './SpectraIndices';

// Alert on Successfule loading of files
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';


const config = {};
const math = create(all, config);

// Define the new function
math.import({
  K0: function(x) {
    let result = window.besselK(0, x);
    return math.complex(result.re, result.im);
  },
  K1: function(x) {
    let result = window.besselK(1, x);
    return math.complex(result.re, result.im);
  },
  I0: function(x) {
    let result = window.besselI(0, x);
    return math.complex(result.re, result.im);
  },
  I1: function(x) {
    let result = window.besselI(1, x);
    return math.complex(result.re, result.im);
  },
  Tanh: function(x) {
    let result = custom_tanh(x);
    return math.complex(result);
  },
  Coth: function(x) {
    let result = custom_coth(x);
    return math.complex(result);
  },
});



const operators = ['+', '-', ',', '*', '/', '(', ')', 'Tanh', 'Coth', 'sqrt', 'pow', 'exp', 's'];
const operatorMapping = {
  '+': '+',
  '-': '-',
  ',': ',',
  '*': '*',
  '**': '**',
  '/': '/',
  '(': '(',
  ')': ')',
  'Coth': '1/jnp.tanh',
  'Tanh': 'jnp.tanh',
  'sqrt': 'jnp.sqrt',
  'pow': 'jnp.power',
  'exp': 'jnp.exp',
  's':  's',
  'K0': 'k0',
  'I0': 'i0',
  'K1': 'k1',
  'I1': 'i1',

};



const BatchFitter = () => {


  // State variables for the loading data
  const [variables, setVariables] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [loadedFileNames, setLoadedFileNames] = useState([]);
  const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
  const [formulaName, setFormulaName] = useState("formula");
  const [loadedFormulaName, setLoadedFormulaName] = useState('');


  /*In the main component, the weighting state is used to store
  the selected weighting method or the sigma data,
  depending on the user's choice.The initial value of 'modulus' means that,
  by default, the "Modulus" weighting method is applied.*/
  const [weighting, setWeighting] = useState({ type: 'modulus' });
  const [isSigmaSet, setIsSigmaSet] = useState(true);

  // State variables for the optional indices
  const [indices, setIndices] = useState([]);
  const [indicesName, setIndicesName] = useState('');

  // State variable for the fitting process
  const [nanDetected, setNanDetected] = useState(false);
  const [fittingCanceled, setFittingCanceled] = useState(false);
  const [uniqueId, setUniqueId] = useState(null);
  const [cancelClicked, setCancelClicked] = useState(false);
  const [cancelMessage, setCancelMessage] = useState("");

  const [source, setSource] = useState(axios.CancelToken.source());
  const [resultsFetched, setResultsFetched] = useState(false);
  const [optimizationInProgress, setOptimizationInProgress] = useState(false);
  const [optimizationComplete, setOptimizationComplete] = useState(false);
  const [openVariableModal, setOpenVariableModal] = useState(false);
  const [selectedFit, setSelectedFit] = useState("sequential");
  const [splineDf, setSplineDf] = useState(4);
  const [fitParams, setFitParams] = useState(null);


  // Add a new state variable for the success message display
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // State variable for the plots
  const [plotData, setPlotData] = useState([]);
  // Fitted data
  const [fitData, setFitData] = useState(null);
  const [paramNames, setParamNames] = useState([]);

  // State variables for the metrics
  const [wrss, setWrss] = useState(null);
  const [wrms, setWrms] = useState(null);
  const [aic, setAic] = useState(null);

  // State variables for the error message
  const [errorText, setErrorText] = useState('');

  // State variables for the drawer
  const [drawerOpen, setDrawerOpen] = useState(false);

  // State to open the modal for the resulting python function
  const [openPythonFunction, setOpenPythonFunction] = React.useState(false);


  // State variables for the circuit library
  const [openLibrary, setOpenLibrary] = useState(false);
  const [openComposer, setOpenComposer] = useState(false);

  // State variables for the Tutorial
  const [openTutorial, setOpenTutorial] = useState(false);

  // State variables for controlling the visibility of the parameters table
  const [openParametersTable, setOpenParametersTable] = useState(false);



  const [fitParamsError, setFitParamsError] = useState([]);


  // State variables for the Expression
  const [formulaError, setFormulaError] = useState(null);
  const [paramsModalOpen, setParamsModalOpen] = useState(false);
  const [isInputValid, setIsInputValid] = useState(true);
  const [expression, setExpression] = useState('');
  const [mismatch, setMismatch] = useState(false);
  const [highlightedExpression, setHighlightedExpression] = useState('');
  const [pythonFunction, setPythonFunction] = useState('');


  // Circuit composer state
  const [circuit, setCircuit] = useState('');

  // State variables for the plots
  const [plotKey, setPlotKey] = useState(0);
  const [openNyquist, setOpenNyquist] = useState(false);
  const [openBode, setOpenBode] = useState(false);

  const inputRef = useRef(null);
  const [fitDataName, setFitDataName] = useState('fitdata');
  const [openSimulator, setOpenSimulator] = useState(false);



  // Variables for the screen size
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const drawerWidth = 240;
  const drawerWidthOpen = theme.spacing(30); // 240px

   // Impedance Editor
  const [openImpedanceEditor, setOpenImpedanceEditor] = useState(false);

  // Custom Loader
  const [openCustomLoader, setOpenCustomLoader] = useState(false);




  const convertToPythonExpression = useCallback((expression) => {
    // console.log('Input expression:', expression); // Log the input expression

    const tokenize = (str) => {
      const regex = /[/*+^(),-]|(?:sqrt|coth|tanh|pow|exp)|\b\d+(?:\.\d+)?\b|\b\w+\b/g;



      let tokens = [];
      let match;
      while ((match = regex.exec(str)) !== null) {
        tokens.push(match[0]);
      }
      return tokens;
    };

    const tokens = tokenize(expression).map((token) => operatorMapping[token] || token); // Updated line
    // console.log('Tokens:', tokens); // Log the tokens


    // Extract variable names from the expression
    const expressionVariables = expression.split(/[\s+/*^(),-]|(?:sqrt|coth|tanh|pow?exp)/).filter((el) => el && !operators.includes(el));



    const uniqueExpressionVariables = [...new Set(expressionVariables)];

    // Filter non-empty variables that are present in the expression
    const nonEmptyVariablesInExpression = variables.filter(
      (variable) => variable.name !== '' && uniqueExpressionVariables.includes(variable.name)
    );

    const expressionConstants = tokens.filter(
      (token) => /^[0-9]+(\.[0-9]+)?$/.test(token)
    );

    const nonEmptyVariablesAndConstantsInExpression = [
      ...nonEmptyVariablesInExpression,
      ...expressionConstants.map((constant) => ({ name: constant })),
    ];


    const variableAndConstantNames = nonEmptyVariablesAndConstantsInExpression.map(
      (variable) => variable.name
    );


    // Check if all variable names in the expression are present in the variables array
    const validTokens = [...variableAndConstantNames, ...Object.values(operatorMapping)];


    const expressionContainsValidVariables = tokens.every((token) =>
    validTokens.includes(token) || /^[0-9]+(\.[0-9]+)?$/.test(token)
    );


    if (!expressionContainsValidVariables) {
      // console.log('Invalid variables:', tokens.filter(token => !validTokens.includes(token)));
      // console.log('Valid tokens:', validTokens);
      // console.log('Tokens:', tokens);
      return null;
    }

    // Generate variable assignment lines for non-empty variables present in the expression
    const RpIndex = variables.findIndex((variable) => variable.transmissionLineRole === 'Rp');

    const variableAssignments = nonEmptyVariablesAndConstantsInExpression
      .filter((variableOrConstant) => !/^[0-9]+(\.[0-9]+)?$/.test(variableOrConstant.name))
      .map((variable, index) => {
        if (variable.transmissionLine === 'multiply') {
          return `  ${variable.name} = p[${index}] * p[${RpIndex}]`;
        } else if (variable.transmissionLine === 'divide') {
          return `  ${variable.name} = p[${index}] / p[${RpIndex}]`;
        }
        return `  ${variable.name} = p[${index}]`;
      })
      .join('\n');

    // console.log(`variableAssignments is ${variableAssignments}`)


    // Construct the function definition
    const functionDefinition = `def model(p, f):\n  w = 2*jnp.pi*f\n  s = 1j*w\n${variableAssignments}`;

    // Convert the expression to a Python expression
    const pythonExpression = Object.entries(operatorMapping).reduce(
      (convertedExpression, [placeholder, pythonOperator]) =>
        convertedExpression.split(placeholder).join(
          typeof pythonOperator === 'function' ? pythonOperator(fileData.map((data) => data.freq).join(', ')) : pythonOperator
        ),
      expression
    );

    // Construct the final Python function
    const finalPythonFunction = `${functionDefinition}\n  Z = ${pythonExpression} * jnp.ones_like(f)\n  return jnp.concatenate((Z.real, Z.imag), axis=0)`;

    return finalPythonFunction;
  }, [fileData, variables]);


  useEffect(() => {
    const nonEmptyVariables = variables.filter((v) => v.name !== '');

    if (nonEmptyVariables.length === 0) {
      setMismatch(true);
    } else {
      const regex = new RegExp(`\\b(${nonEmptyVariables.map((v) => v.name).join('|')})\\b`, 'g');
      const highlighted = expression.replace(regex, (match) => {
        return `<mark>${match}</mark>`;
      });
      setHighlightedExpression(highlighted);

      // const strippedHighlightedExpression = highlighted
      //   .replace(/<\/?mark>/g, '')
      //   .replace(/&nbsp;/g, '');

      // console.log(`expression is ${strippedHighlightedExpression}`);
      const allNonEmptyVariablesInExpression = nonEmptyVariables.every((variable) => {
        const variableRegex = new RegExp(`\\b${variable.name}\\b`);
        return variableRegex.test(expression);
      });

      const pythonFunction = convertToPythonExpression(expression);
      // console.log('pythonFunction:', pythonFunction); // Log the generated Python function

      if (
        pythonFunction !== null &&
        allNonEmptyVariablesInExpression &&
        expression !== ''
      ) {
        setMismatch(false);
      } else {
        setMismatch(true);
      }
    }
  }, [expression, variables, convertToPythonExpression]);


  const handleVariableDeletion = (index) => {
    setVariables((prevVariables) => prevVariables.filter((_, i) => i !== index));
  };


  const handleOperatorButtonClick = (operator) => {
    const cursorPosition = inputRef.current.selectionStart;
    const beforeCursor = expression.slice(0, cursorPosition);
    const afterCursor = expression.slice(cursorPosition);

    const newExpression = (`${beforeCursor}${operator}${afterCursor}`);
    setExpression(newExpression)
    try {
      const spacedExpression = addSpacesAroundOperators(newExpression);
      math.parse(spacedExpression);
      setFormulaError(null);
    } catch (error) {
      setFormulaError(error.message);
    }
  };


  const handleClearExpression = () => {
    setExpression('');
  };

  // Python Expression Conversion

  useEffect(() => {
    const newPythonFunction = convertToPythonExpression(expression);
    setPythonFunction(newPythonFunction);
  }, [expression, convertToPythonExpression]);

  useEffect(() => {
    if (fitParams) {
      // console.log('Updated Result:', fitParams);
    }
  }, [fitParams]);



  const createExpressionsWithVariables = (variables, fileData, expression) => {
    const variableDictionary = variables.reduce((dict, variable) => {
      // If the variable value is real, convert it to a complex number
      const variableValue = typeof variable.initialValue === 'number'
        ? `complex(${variable.initialValue}, 0)`
        : variable.initialValue;

      dict[variable.name] = variableValue;
      return dict;
    }, {});

    const expressionsWithS = fileData.map((data) => {
      const sValue = `complex(0, 1) * 2 * PI * ${data.freq}`;
      return expression.replace(/\bs\b/g, sValue);
    });

    return expressionsWithS.map((exprWithS) =>
      Object.keys(variableDictionary).reduce((expr, variableName) => {
        const variableValue = variableDictionary[variableName];
        const variableRegex = new RegExp(`\\b${variableName}\\b`, 'g');
        return expr.replace(variableRegex, variableValue);
      }, exprWithS)
    );
  };


  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });


  const fetchResults = useCallback(async (unique_id, retryLimit = 100) => {
    let retries = 0;
    while (true) {
      // Check if the fitting process has been canceled, the cancel button has been clicked, or the results have been fetched
      if (fittingCanceled || cancelClicked || resultsFetched) {
        // Break out of the loop and stop polling
        return null; // Return null when the fitting process has been canceled or the results have been fetched
      }

      try {
        const resultsResponse = await axiosInstance.get(`/get-results/${unique_id}`, {
          cancelToken: source ? source.token : undefined,
        });

        if (resultsResponse.status === 200) {
          const results = resultsResponse.data;

          // Add a check for fitParams here
          if (resultsFetched) {
            return null; // Return null when the results have been fetched
          }

          return results;
        } else if (resultsResponse.status === 202) {
          const errorResponse = resultsResponse.data;
          if (errorResponse.error === "Results not found") {
            // No need to throw an error here, just wait and poll again
            await new Promise((resolve) => setTimeout(resolve, 2000));
            continue;
          } else {
            throw new Error('Error fetching results');
          }
        } else if (resultsResponse.status === 500) {
          const errorResponse = resultsResponse.data;
          if (errorResponse.error === "Task failed") {
            setResultsFetched(false);
            setOptimizationInProgress(false); // Set the optimization in progress to false
            console.error('Task failed');
            return null; // Return null when encountering a task failure
          }
          throw new Error('Server error');
        } else {
          throw new Error('Unexpected error fetching results');
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('Request canceled:', error.message);
          return null; // Return null when the request is canceled
        } else {
          console.error(error);
          retries++;
          if (retries >= retryLimit) {
            throw new Error('Max retries reached');
          }

          // Wait for 5 seconds before polling again
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
      }
    }
  }, [source, fittingCanceled, cancelClicked, axiosInstance, resultsFetched]);

  const handleSubmit = async () => {
    setUniqueId(null);
    setFittingCanceled(false);
    setCancelClicked(false);
    setOptimizationInProgress(true);
    setErrorText('');
    setResultsFetched(false);
    setFitData([]);
    setWrss(null);
    setWrms(null);
    setAic(null);
    setFitParams(null);
    setIndices([]);
    setIndicesName('');
    setNanDetected(false);  // New state variable for NaN detection
    setCancelMessage("");

    const expressionsWithVariables = fileData && Array.isArray(fileData)
      ? fileData.map((dataset) =>
          createExpressionsWithVariables(variables, dataset, expression)
        )
      : [];

    try {
      const evaluatedExpressions = expressionsWithVariables.map((exprWithVars) => {
        return math.evaluate(exprWithVars);
      });

      // console.log(`evaluatedExpression:, ${evaluatedExpressions}`);
      const isArrayOfComplexNumbers = evaluatedExpressions.every(
        (evaluatedExpression) =>
          Array.isArray(evaluatedExpression) &&
          evaluatedExpression.every((value) => math.typeOf(value) === "Complex")
      );

      if (isArrayOfComplexNumbers) {
        const newSource = axios.CancelToken.source();

        try {
          const payload = {
            expression,
            variables,
            data: fileData,
            model: pythonFunction,
            weighting: weighting,
            selectedFit: selectedFit,
            splineDf: selectedFit === 'spline' ? splineDf : undefined, // Add the df parameter conditionally
          };

          // console.log('Payload data:', payload); // Log the payload data

          const response = await axiosInstance.post('/batchfit', payload, {
            cancelToken: newSource.token,
          });

          const data = response.data;
          const unique_id = data.task_id;
          setUniqueId(unique_id); // Set the unique_id in the state
          // Set the source in the state so it can be used for cancellation later
          setSource(newSource);

          if (data.nanDetected) {  // Handle NaN detection
            setNanDetected(true);
            setErrorText('NaN values detected during the fitting process and will be replaced by 1e15.');
          }

        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Request canceled:', error.message);
          } else {
            console.error("Error is:", error);
            setOptimizationInProgress(false);

            if (axios.isAxiosError(error)) {
              if (error.response) {
                switch (error.response.status) {
                  case 404:
                    setErrorText('The fitting process encountered an error. Please ensure the server is running and try again.');
                    break;
                  case 500:
                    setErrorText('The server encountered an error. Please try again later.');
                    break;
                  default:
                    setErrorText(`An unexpected error occurred: ${error.message}`);
                }
              } else if (error.request) {
                setErrorText('No response received from the server. Please check your connection and try again.');
              } else {
                setErrorText(`An unexpected error occurred: ${error.message}`);
              }
            } else {
              setErrorText(`Error during fitting process: ${error.message}`);
            }

            setResultsFetched(false);
            // console.error("resultsfetched:", resultsFetched);
          }
        }
      } else {
        setFitParams(null);
        console.error("Invalid expression: The expression does not return an array of complex numbers.");
        setErrorText("Invalid expression: The expression must return an array of complex numbers."); // Set the error text
        setResultsFetched(false);
        setOptimizationInProgress(false);
      }

    } catch (error) {

      if (error.message.includes('Too few arguments in function pow')) {
        setErrorText("Invalid expression: The expression is ill formed. Please ensure the correct syntax and number of arguments for all functions.");
      } else {
        setErrorText("An unexpected error occurred. Please check your inputs and try again.");
      }

      setResultsFetched(false);
      setOptimizationInProgress(false);
    }
  };

  useEffect(() => {
    if (uniqueId && optimizationInProgress && resultsFetched) {
      setOptimizationInProgress(false);
      setOptimizationComplete(true);
      setTimeout(() => {
        setOptimizationComplete(false);
      }, 2000);
    } else if (fittingCanceled) {
      setOptimizationInProgress(false);
    }
  }, [uniqueId, optimizationInProgress, resultsFetched, fittingCanceled]);


  useEffect(() => {
    let errorTimeout;
    if (errorText || cancelMessage) {
      errorTimeout = setTimeout(() => {
        setErrorText('');
        setCancelMessage("");
      }, 5000); // Clear the error message after 5 seconds
    }

    // Clear the timeout when the component is unmounted
    return () => {
      clearTimeout(errorTimeout);
    };
  }, [errorText, cancelMessage]);




  // Refactored code to separate fetching and processing of results
  const processResults = useCallback(async (unique_id) => {
    try {
      const results = await fetchResults(unique_id);

      if (cancelClicked) {
        return;
      }

      if (results) {
        setFitParams(results.fitParams);
        setFitParamsError(results.fitParamsError);
        setParamNames(results.paramNames);

        if (results.fitData) {
          const fitDataArray = results.fitData;
          setFitData(fitDataArray);
          setWrss(results.wrss);
          setWrms(results.wrms);
          setAic(results.aic);
        } else {
          setFitData([]);
          setWrss(null);
          setWrms(null);
          setAic(null);
        }
        setResultsFetched(true);
      }
    } catch (error) {
      console.error("Error processing results:", error);
    }
  }, [cancelClicked, fetchResults]);



  useEffect(() => {
    if (uniqueId) {
      processResults(uniqueId);
    }
  }, [uniqueId, processResults]);



  const cancelFit = async () => {
    if (!uniqueId) {
      console.error('unique_id is not set yet. Cannot cancel the fitting process.');
      return;
    }

    setCancelClicked(true);

    if (source) {
      source.cancel('Fitting process canceled by the user.');
      setSource(null);
    }
    setResultsFetched(false);
    setFittingCanceled(true);
    setCancelMessage("Fitting process canceled by the user. Please start the fitting process again if needed."); // Set the cancel message here

  };






  const handleVariableAddition = () => {
    setVariables([...variables, { name: '', initialValue: 0.1, lowerBound: 1e-15, upperBound: 1e15, smoothing: 0.0 }]);
  };





  const addSpacesAroundOperators = (expr) => {
    // Add spaces around operators
    let spacedExpression = expr.replace(/([+\-*/^])/g, ' $1 ');

    // Add a multiplication symbol between a closing parenthesis and an opening parenthesis or a variable/constant
    spacedExpression = spacedExpression.replace(/\) *(\(|[a-zA-Z])/g, ') * $1');

    return spacedExpression;
  };


  const handleExpressionChange = (e) => {
    const inputExpression = e.target.value;
    setExpression(inputExpression);

    try {
      const spacedExpression = addSpacesAroundOperators(inputExpression);
      math.parse(spacedExpression);
      setFormulaError(null);
    } catch (error) {
      setFormulaError(error.message);
    }
  };

  const RpSelected = variables.some((variable) => variable.transmissionLineRole === 'Rp');


  // Reset formula
  const handleResetFormula = () => {
    setExpression('');
    setHighlightedExpression('');
    setVariables([]);
    setLoadedFileNames([]);
    setFileData([]);
    setIsFileUploaded(false);
    setFitParams(null);
    setWrms(null);
    setWrss(null);
    setAic(null);
    setFitData(null); // Now setting fit data null
    setPlotData([]); // Clear the plot data
    setPlotKey((prevPlotKey) => prevPlotKey + 1); // Increment the plotKey state variable
    setLoadedFormulaName('');
    setIsSettingsLoaded(false);
    setFormulaError(null);
    setIndices([]);
    setIndicesName('');
    setIsSigmaSet(true);
    setWeighting({ type: 'modulus' });
    setNanDetected(false);
    setCancelMessage("");
    setCancelClicked(false);
    setFittingCanceled(false);
    setResultsFetched(false);
    setOptimizationInProgress(false);
    setOptimizationComplete(false);
    setFitParamsError([]);
    setParamNames([]);
    setUniqueId(null);

    // console.log(`setShowSuccessMessage upon reset is ${showSuccessMessage}`);
  };


  const handleVariableEdit = () => {
    if (!isInputValid) {
      setIsInputValid(true);
    }
  };


  const handleVariableChange = (index, change) => {
    const newVariables = [...variables];
    newVariables[index] = { ...newVariables[index], ...change };
    setVariables(newVariables);
  };



  const handleVariableBlur = (index, field) => {
    const newVariables = [...variables];
    const variable = { ...newVariables[index] };
    let inputValid = true;

    let isEmpty = false; // Add this flag

    const showError = (message) => {
      alert(message);
      inputValid = false;
    };

    // Check if the input value is empty and set the isEmpty flag to true
    const handleEmptyInput = (variable, field) => {
      if (variable[field] === '') {
        isEmpty = true; // Set the flag to true instead of setting the default value immediately
      }
    };

    if (field === 'initialValue') {
      handleEmptyInput(variable, 'initialValue');
      const initialValue = parseFloat(variable.initialValue);
      const lowerBound = parseFloat(variable.lowerBound);
      const upperBound = parseFloat(variable.upperBound);

      if (initialValue <= 0) {
        showError('Initial value must be greater than zero.');
        variable.initialValue = initialValue * -1;
      } else if (initialValue <= lowerBound) {
        variable.initialValue = lowerBound * 1.1;
      } else if (initialValue >= upperBound) {
        variable.initialValue = upperBound * 0.9;
      }
    } else if (field === 'lowerBound') {
      handleEmptyInput(variable, 'lowerBound');
      const lowerBound = parseFloat(variable.lowerBound);
      const initialValue = parseFloat(variable.initialValue);

      if (lowerBound < 0) {
        showError('Lower bound must be non-negative.');
        variable.lowerBound = 1e-15;
      } else if (lowerBound >= initialValue) {
        variable.lowerBound = initialValue * 0.9;
      }
    } else if (field === 'upperBound') {
      handleEmptyInput(variable, 'upperBound');
      const upperBound = parseFloat(variable.upperBound);
      const initialValue = parseFloat(variable.initialValue);

      if (upperBound < 0) {
        showError('Upper bound must be non-negative.');
        variable.upperBound = 1e15;
      } else if (upperBound <= initialValue) {
        variable.upperBound = initialValue * 1.1;
      }
    }  else if (field === 'smoothing') {
      handleEmptyInput(variable, 'smoothing');
      const smoothing = variable.smoothing;

      if (smoothing === '' || smoothing === undefined) {
        variable.smoothing = '0.0';
      } else if (isNaN(parseFloat(smoothing)) && smoothing !== 'inf') {
        showError('Smoothing must be a non-negative number or "inf".');
        variable.smoothing = '0.0';
      } else if (smoothing !== 'inf' && parseFloat(smoothing) < 0) {
        showError('Smoothing must be non-negative or "inf".');
        variable.smoothing = 1.0;
      }
    }

    if (isEmpty) {
      switch (field) {
        case 'initialValue':
          variable.initialValue = 0.1;
          break;
        case 'lowerBound':
          variable.lowerBound = 1e-15;
          break;
        case 'upperBound':
          variable.upperBound = 1e15;
          break;
        case 'smoothing':
          variable.smoothing = 0.0;
          break;
        default:
          console.error(`Unexpected field name: ${field}`);
          break;
      }
    }

    // Convert values to floats, except when smoothing is set to 'inf'
    variable.initialValue = parseFloat(parseFloat(variable.initialValue).toFixed(15));
    variable.lowerBound = parseFloat(parseFloat(variable.lowerBound).toFixed(15));
    variable.upperBound = parseFloat(parseFloat(variable.upperBound).toFixed(15));
    if (variable.smoothing !== 'inf') {
      variable.smoothing = parseFloat(parseFloat(variable.smoothing).toFixed(15));
    }

    newVariables[index] = { ...variable }; // Create a new object with the updated variable
    setVariables(newVariables);
    setIsInputValid(inputValid);
  };




  const toggleModal = (modal, value) => {
    switch (modal) {
      case 'params':
        setParamsModalOpen(value);
        break;
      case 'variable':
        setOpenVariableModal(value);
        break;
      case 'nyquist':
        setOpenNyquist(value);
        break;
      case 'bode':
        setOpenBode(value);
        break;
      case 'simulator': // Add this case
        setOpenSimulator(value);
        break;
      case 'drawer': // Add this case
        setDrawerOpen(value);
        break;
      case 'impedanceEditor': // Add this case
        setOpenImpedanceEditor(value);
        break;
      case 'customLoader': // Add this case
        setOpenCustomLoader(value);
        break;
      case 'pythonFunction': // Add this case
        setOpenPythonFunction(value);
        break;
      case 'parametersTable': // Add this case
        setOpenParametersTable(value);
        break;
      default:
        break;
    }
  };


  const handleFitChange = (fitMethod, newDf) => {
    setSelectedFit(fitMethod);
    if (newDf) {
      setSplineDf(newDf);
    }
  };


  const handleClearCircuit = () => {
    setCircuit('');
  };

  const handleLoadFitData = (settings) => {
    // Set your state based on the loaded settings
    setWeighting(settings.weighting);
    setSelectedFit(settings.selectedFit);
    setFitParams(settings.fitParams);
    setFitParamsError(settings.fitParamsError);
    setParamNames(settings.paramNames);
    setFileData(settings.fileData);
    setLoadedFileNames(settings.loadedFileNames);
    setPlotData(settings.plotData);
    setFitData(settings.fitData);
    setWrss(settings.wrss);
    setWrms(settings.wrms);
    setAic(settings.aic);
    setExpression(settings.expression);
  };

  const handleLibraryClick = () => {
    setOpenLibrary(true);
  };

  const handleTutorialClick = () => {
    setOpenTutorial(true);
  };


  const handleComposerClick = () => {
    setOpenComposer(true);
  };





  const drawer = (
    <div >
       <Typography variant="h6" noWrap style={{padding: '16px'}}>
      Load Data
    </Typography>
    <Divider />
      <List>
      <ListItem >
        <ImpedanceLoader
          setLoadedFileNames={setLoadedFileNames}
          setIsFileUploaded={setIsFileUploaded}
          setShowSuccessMessage={setShowSuccessMessage}
          setFileData={setFileData}
          setPlotData={setPlotData}
          setFitData={setFitData}
        />
        </ListItem>

        <ListItem>
          <SharedButton
            style={{ width: '200px' }}
            onClick={() => {

                toggleModal('customLoader', true)


            }}
          >
            Load Custom Data
          </SharedButton>

          <LoadCustomData
            openModal={openCustomLoader}
            handleCloseModal={() => toggleModal('customLoader', false)}
            setPlotData={setPlotData}
            setFileData={setFileData}
            setFitData={setFitData}
            showSuccessMessage={showSuccessMessage}
            setIsFileUploaded={setIsFileUploaded}
            setShowSuccessMessage={setShowSuccessMessage}
            setLoadedFileNames={setLoadedFileNames}
          />
        </ListItem>

        <ListItem>
          <SharedButton
            style={{ width: '200px' }}
            onClick={() => {
              if (fileData && fileData.length > 0) { // check if fileData is present
                toggleModal('impedanceEditor', true)
              } else {
                alert("Please load a valid impedance data first.");
              }
            }}
          >
            Edit Impedance Data
          </SharedButton>

          <ImpedanceDataEditor
            openModal={openImpedanceEditor}
            handleCloseModal={() => toggleModal('impedanceEditor', false)}
            fileData={fileData}
            setFileData={setFileData}
            setPlotData={setPlotData}
            weighting={weighting}
            setIsSigmaSet={setIsSigmaSet}
            loadedFileNames={loadedFileNames}
          />
        </ListItem>


        <ListItem>
        <FormulaLoader
          loadedFormulaName={loadedFormulaName}
          setLoadedFormulaName={setLoadedFormulaName}
          setExpression={setExpression}
          setVariables={setVariables}
          isSettingsLoaded={isSettingsLoaded}
          setIsSettingsLoaded={setIsSettingsLoaded}
        />

        </ListItem>
        <ListItem>
        <FitDataLoader
          onLoadFitData={handleLoadFitData}
          setIsFileUploaded={setIsFileUploaded}
          setLoadedFormulaName={setLoadedFormulaName}
          setExpression={setExpression}
          setVariables={setVariables}
          setIsSettingsLoaded={setIsSettingsLoaded}
           />
        </ListItem>

      </List>


  <List>

    </List>

      <Divider />
    <Typography variant="h6" noWrap sx={{ padding: theme.spacing(2) }}>
      Save Data
    </Typography>
    <Divider />
    <List>
    <ListItem>
    <Tooltip title="Button will become active only after the formula and variables are correct">
      <span>
        <FormulaSaver
          formulaName={formulaName}
          setFormulaName={setFormulaName}
          expression={expression}
          variables={variables}
          isDisabled={mismatch}
        />
      </span>
    </Tooltip>

      </ListItem>
      <ListItem>
      <Tooltip
        title="Button will become active only after the fitting is complete"
        enterDelay={500}
        leaveDelay={200}>
          <span>
            <FitDataSaver
              fitDataName={fitDataName}
              setFitDataName={setFitDataName}
              weighting={weighting}
              selectedFit={selectedFit}
              fitParams={fitParams}
              fitParamsError={fitParamsError}
              paramNames={paramNames}
              fileData={fileData}
              loadedFileNames={loadedFileNames}
              plotData={plotData}
              fitData={fitData}
              wrss={wrss}
              wrms={wrms}
              aic={aic}
              expression={expression}
              isDisabled={!fitData}
              formulaName={formulaName}
              setFormulaName={setFormulaName}
              variables={variables}
            />
          </span>
        </Tooltip>
      </ListItem>
      <ListItem>
        <FitDataCSVSaver
          exptData={plotData}
          fitData={fitData}
          wrss={wrss}
          wrms={wrms}
          aic={aic}
          loadedFileNames={loadedFileNames}
          isDisabled={!fitData && !fileData}

        />
        </ListItem>
      <Collapse in={showSuccessMessage}>
      <Alert
        severity="success"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setShowSuccessMessage(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        File loaded successfully!
      </Alert>
    </Collapse>
    </List>

    </div>
  );

  return (
    <Container maxWidth="xl" sx={{ border: 'none', position: 'relative' }}>
       <ColorChangeAppBar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => toggleModal('drawer', true)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component={Link} to="/" sx={{ flexGrow: 1, color: '#fff'}}>
          FitMyEIS
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <IconButton
          edge="end"
          color="inherit"
          aria-label="tutorial"
          onClick={handleTutorialClick}
        >
          <SchoolIcon />
          <Typography variant="body1">
            Tutorials
          </Typography>
        </IconButton>
      </ColorChangeAppBar>

      <Tutorial openTutorial={openTutorial} setOpenTutorial={setOpenTutorial} />

      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={() => toggleModal('drawer', false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [theme.breakpoints.up("sm")]: {
            width: drawerOpen ? drawerWidthOpen : drawerWidth,
          },
        }}
        PaperProps={{ // add styles directly to Paper
          sx: {
            width: drawerWidth,
            [theme.breakpoints.up("sm")]: {
              width: drawerOpen ? drawerWidthOpen : drawerWidth,
            },
          },
        }}
      >
        {drawer}
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: theme.spacing(8),
          position: "relative",
          border: "none",
        }}
      >
            {/* SecondBox */}
      <Box
        width="100%"
        mt={2}
        mb={3}
        ml={{ xs: 0, sm: theme.spacing(2) }}
        mr={{ xs: 0, sm: theme.spacing(2) }} // Add this
        border={3}
        borderColor="divider"
        borderRadius={4}
        px={1}
        paddingTop={2}
        paddingBottom={6}
      >

<Grid container item xs={12} direction="row" spacing={10} justifyContent="center">

{/* Components */}
<Grid item xs={12} sm={6}>



{/* Formula Input */}

<Grid item xs={12}>
<Box
   sx={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: isSmallScreen ? 'wrap' : 'nowrap',
    gap: '8px',
    width: '100%',
  }}
>
    {/* Add Circuit Composer */}

      <ComposerButton onClick={handleComposerClick}>
          Compose
        </ComposerButton>

      <CircuitComposer
        openComposer={openComposer}
        setOpenComposer={setOpenComposer}
        setCircuit={setCircuit}
        initialCircuitString={circuit}
        setExpression={setExpression}
        clearCircuit={handleClearCircuit}
        setLoadedFormulaName={setLoadedFormulaName}
      />

      {/* Add Preloaded circuits */}

        <PreLoadedCircuitsButton onClick={handleLibraryClick}>
          From Library
        </PreLoadedCircuitsButton>

        <CircuitLibrary
        openLibrary={openLibrary}
        setOpenLibrary={setOpenLibrary}
        setExpression={setExpression}
        setLoadedFormulaName={setLoadedFormulaName}
        setVariables={setVariables}
        setIsSettingsLoaded={setIsSettingsLoaded}
      />



    {/* Add Simulate button */}

    <PreLoadedCircuitsButton
        variant="contained"
        color="primary"
        onClick={() => toggleModal('simulator', true)}
      >
        Simulate
      </PreLoadedCircuitsButton>
      <Modal
        open={openSimulator}
        onClose={() => toggleModal('simulator', false)}
        aria-labelledby="simulator-modal"
        aria-describedby="simulator-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: isSmallScreen ? '80%' : '70%',
            maxHeight: isSmallScreen ? '85%' : '90%',
            overflow: "auto",
          }}
        >
          {openSimulator && !mismatch ? (
            <Simulator
              variables={variables}
              onVariableChange={handleVariableChange}
              onVariableBlur={handleVariableBlur}
              onAccept={() => toggleModal('simulator', false)}
              onClose={() => toggleModal('simulator', false)}
              expression={expression}
              plotData={plotData}
            />
          ) : (
            <Typography variant="body1" color="error">
              No parameters available. Please run the fit first.
            </Typography>
          )}

          {/* Add the close IconButton here */}
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => toggleModal('simulator', false)}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Modal>



        <CancelButton
            variant="contained"
            onClick={handleClearExpression}
            fullWidth
            style={{ fontSize: '14px' }}
          >
            Clear
          </CancelButton>
        </Box>

    </Grid>

    <Grid item xs={12} style={{ marginBottom: '16px' }}></Grid>

    <Grid item xs={12} sm={12}>
      <ExpressionTextField
        label="Impedance Expression"
        value={expression}
        onChange={handleExpressionChange}
        fullWidth
        inputRef={inputRef}
        multiline
        rows={4}
        inputProps={{
          spellCheck: 'false',
          style: {
            overflow: 'auto', // Enable scrolling when the content overflows
          },
        }}
      />
      {formulaError && (
        <Typography variant="body1" color="error">
          {formulaError}
        </Typography>
      )}
    </Grid>




    {/* Second grid within the first column */}
    <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
    <Operators
    theme={theme}
    handleOperatorButtonClick={handleOperatorButtonClick}
    handleExpressionChange={handleExpressionChange}
    operators={operators}
  />
  </Grid>

  <Grid item xs={12} sm={12} style={{ marginTop: '20px'}}>
    <HighlightedExpressionBox highlightedExpression={highlightedExpression} />
  </Grid>

  <PythonFunctionBox pythonFunction={pythonFunction} mismatch={mismatch} toggleModal={toggleModal} openPythonFunction={openPythonFunction} />


      {/* Third grid within the first column */}


      <Grid container item xs={12} spacing={2}>
      <Grid item xs={12} sm={6}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CircuitElementInputButton toggleModal={toggleModal} />
          </Grid>
          <Grid item>
            <Variable
              openModal={openVariableModal}
              handleCloseModal={() => toggleModal('variable', false)}
              variables={variables}
              handleVariableAddition={handleVariableAddition}
              handleVariableChange={handleVariableChange}
              handleVariableEdit={handleVariableEdit}
              handleVariableBlur={handleVariableBlur}
              handleVariableDeletion={handleVariableDeletion}
              RpSelected={RpSelected}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} style={{ marginTop:'16px', marginBottom:'20px'}}>
        <SpectraIndices setIndices={setIndices} setIndicesName={setIndicesName} fileData={fileData} isFileUploaded={isFileUploaded} />
      </Grid>
    </Grid>




      <Grid container item xs={12} spacing={2} direction="row" alignItems="flex-start">
        <Grid item xs={12} sm={6} style={{ marginBottom:'20px'}}>
          <Weighting
          setWeighting={setWeighting}
          fileData={fileData}
          setIsSigmaSet={setIsSigmaSet}/>
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginBottom:'20px'}}>
          <FitOptions
            selectedFit={selectedFit}
            handleFitChange={handleFitChange}
            />
          </Grid>
        </Grid>

    {/* Add Variable and Calculate buttons */}
    <Grid container item xs={12} spacing={2} direction="column" alignItems="flex-start">
      <Grid container item xs={12} spacing={2} direction="row" >
      <Grid item xs={12} sm={6}>
        <AddButton
          variant="contained"
          color="primary"
          onClick={() => {
            if (!mismatch && isFileUploaded && isInputValid && isSigmaSet) {
              handleSubmit(selectedFit);
            }
          }}
          disabled={
            mismatch || !isFileUploaded || !isInputValid || !isSigmaSet
          }
        >
          Fit
        </AddButton>
      </Grid>


      <Grid item xs={12} sm={6}>

        <CancelButton
        variant="contained"
        color="secondary"
        onClick={cancelFit}
        disabled={!optimizationInProgress || selectedFit !== "sequential" || !uniqueId}
        // disabled={!optimizationInProgress || selectedFit !== "sequential"}
        >
        Cancel Fit
        </CancelButton>
        </Grid>
      </Grid>



      <Grid container item xs={12} spacing={2} direction="row" >
      <Grid item xs={12} sm={12}>
      {optimizationInProgress && (
        <>
          <Typography variant="body1" align="center" gutterBottom>
            Fit in progress...
          </Typography>
          <LinearProgress/>

        </>
      )}

      {optimizationComplete && !optimizationInProgress && (
        <Typography variant="body1" align="center" gutterBottom>
          {fittingCanceled ? cancelMessage : "Fit Complete"}
        </Typography>
      )}

      {errorText && <p className="error-text">{errorText}</p>}
      {cancelMessage && <p className="error-text">{cancelMessage}</p>}

      {nanDetected && errorText && <p className="error-text">{errorText}</p>}

      {/* Second column - fourth sub grid 0*/}
      <Grid item xs={12} sm={12} style={{ marginTop: '15px' }}>
        <Metrics wrss={wrss} wrms={wrms} aic={aic} />
      </Grid>



        </Grid>
      </Grid>
      {/* Add Circuit Composer*/}

    </Grid>






  </Grid>



  {/* Second column main grid */}
  <Grid item xs={12} sm={6}>


    {/* Formula Input */}

  <Grid item xs={12}>
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center', // Change this line
      alignItems: 'center',
      flexWrap: isSmallScreen ? 'wrap' : 'nowrap',
      gap: '8px',
      width: '100%',
    }}
  >
    {/* Add Nyquist Plot */}


      <SharedButton
        fullWidth
        onClick={() => toggleModal('nyquist', true)}
        component="button"
      >
        Nyquist Plot (3D)
      </SharedButton>

    <Dialog
      open={openNyquist}
      onClose={() => toggleModal('nyquist', false)}
      PaperComponent={DraggablePaper}
      PaperProps={{ handle: '.modal-title' }}
      aria-labelledby="nyquist-plot-dialog"
      aria-describedby="nyquist-plot-dialog-description"
      fullWidth
      maxWidth="md" // adjust as needed
    >
      <div className="modal-title">
        <DialogTitle>Complex Plane Plots</DialogTitle>
      </div>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
        }}
      >
        {plotData && plotData.length > 0 ? (
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} style={{ marginRight: "10px" }}>
              <NyquistPlot
                key={plotKey}
                data={plotData}
                fitData={fitData}
                indices={indices}
                indicesName={indicesName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AdmittancePlot
                key={plotKey}
                data={plotData}
                fitData={fitData}
                indices={indices}
                indicesName={indicesName} />
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body1" color="error">
            No plot data available. Please load a spectra.
          </Typography>
        )}
      </Box>
      <DialogActions>
        <Button onClick={() => toggleModal('nyquist', false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>


        {/* Add Bode Plot */}
      <SharedButton
      fullWidth
      onClick={() => toggleModal('bode', true)}
      component="button"
    >
      Bode Plot (3D)
    </SharedButton>

    <Dialog
      open={openBode}
      onClose={() => toggleModal('bode', false)}
      PaperComponent={DraggablePaper}
      PaperProps={{ handle: '.modal-title' }}
      aria-labelledby="bode-plot-dialog"
      aria-describedby="bode-plot-dialog-description"
      fullWidth
      maxWidth="md" // adjust as needed
    >
      <div className="modal-title">
        <DialogTitle>Bode Plots</DialogTitle>
      </div>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          minHeight: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
        }}
      >
        {plotData && plotData.length > 0 ? (
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} style={{ marginRight: "10px" }}>
              <BodePlot
                key={plotKey}
                data={plotData}
                fitData={fitData}
                indices={indices}
                indicesName={indicesName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <BodeMagPlot
                key={plotKey}
                data={plotData}
                fitData={fitData}
                indices={indices}
                indicesName={indicesName}
              />
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body1" color="error">
            No plot data available. Please load a spectra.
          </Typography>
        )}
      </Box>
      <DialogActions>
        <Button onClick={() => toggleModal('bode', false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>



    {/* Add Params Plot button */}

    <SharedButton
      fullWidth
      onClick={() => toggleModal('params', !paramsModalOpen)}
      component="button"
    >
      Fit Params
    </SharedButton>
    <Modal
      open={paramsModalOpen}
      onClose={() => toggleModal('params', false)}
      aria-labelledby="params-modal"
      aria-describedby="params-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          width: isSmallScreen ? '80%' : '70%',
          maxHeight: isSmallScreen ? '85%' : '70%',
          overflow: 'auto',
        }}
      >
        {fitParams && fitParams.length > 0 && fitParamsError && fitParamsError.length > 0 && paramNames && paramNames.length > 0 ? (
          <PlotParams
            paramsData={fitParams}
            errorData={fitParamsError}
            variables={variables}
            setVariables={setVariables}
            loadedFileNames={loadedFileNames}
            paramNames={paramNames}
            indices={indices}
            indicesName={indicesName}
          />
        ) : (
          <Typography variant="body1" color="error">
            No fit parameters / plot data available. Please run a fit first.
          </Typography>
        )}

        {/* Add the close IconButton here */}
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => toggleModal('params', false)}
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>

    {/* Add Reset button */}
    <ResetButton
        onClick={handleResetFormula}
        >
          Reset
        </ResetButton>

        </Box>

    </Grid>


    <Grid item xs={12} style={{ marginBottom: '16px' }}></Grid>


    {/* Second column main grid arrangement */}
  <Grid container item xs={12} direction="column" >
    {/* Second column - first sub grid */}

  {/* Second column - second sub grid 0 */}
  <Container maxWidth="lg">
  <Grid container spacing={3}>
    <Grid item xs={12} sm={12}>
    {plotData && plotData.length > 0 ? (
        <SingleSpectraPlot data={plotData} fitData={fitData} loadedFileNames={loadedFileNames} />
      ) : (
        <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center' }}>
          <InsertChartOutlinedIcon sx={{ fontSize: '72px', color: '#3f51b5' }} />
          <Typography variant="h6" align="center">
            No data has been loaded.
          </Typography>
          <Typography variant="body1" align="center">
            Please load an impedance data to display the 2D plots.
          </Typography>
        </Paper>
      )}
    </Grid>

    <Grid item xs={12} sm={12}>
    {fitData && fitParams && fitParams.length > 0 && fitParamsError && fitParamsError.length > 0 && paramNames && paramNames.length > 0 ? (
      <>
        <PreLoadedCircuitsButton
          onClick={() => toggleModal('parametersTable', true)}
        >
          Open Fit Parameters Table
        </PreLoadedCircuitsButton>

        <ParamTable
          open={openParametersTable}
          handleClose={() => toggleModal('parametersTable', false)}
          paramsData={fitParams}
          errorData={fitParamsError}
          variables={variables}
          setVariables={setVariables}
          loadedFileNames={loadedFileNames}
          paramNames={paramNames}
        />
      </>
    ) : null}
    </Grid>

    <Grid item xs={12} sm={12} style={{marginTop:'10px'}}>
      {isFileUploaded && (
        <Typography variant="body1" color="primary" style={{ fontSize: '14px', fontStyle: 'italic', wordWrap: 'break-word' }}>
          {loadedFileNames.length > 1 ? `Number of Files Loaded (${loadedFileNames.length}):` : `File Loaded: ${loadedFileNames[0]}`}
        </Typography>
      )}
    </Grid>

    <Grid item xs={12} sm={12}>
      {isSettingsLoaded && (
        <Typography variant="body1" color="primary" style={{ fontSize: '14px', fontStyle: 'italic', wordWrap: 'break-word' }}>
          Formula Loaded: {loadedFormulaName}
        </Typography>
      )}
    </Grid>

    <Grid item xs={12} sm={12}>
      {indicesName && (
        <Typography variant="body1" color="secondary" style={{ fontSize: '14px', fontStyle: 'italic' }}>
          Indices Name: {indicesName}
        </Typography>
      )}
    </Grid>
  </Grid>
</Container>
  </Grid>
    </Grid>



  </Grid>
  </Box>
      </Box>
    </Container>
  );

};

export default BatchFitter;
