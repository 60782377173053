import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const Spherical = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = 'Porous electrode with solid-state diffusion inside spherical particles';

    const circuitExpression = '(Rser + (sqrt(Rm / ((s * Cdl) + 1 / (Rct + ((Tanh(sqrt(Rd * (s * 3 * Cd)))) / ((sqrt((s * 3 * Cd) / Rd) - (1 / Rd) * Tanh(sqrt(Rd * (s * 3 * Cd))))))))) * (Coth(sqrt(Rm * ((s * Cdl) + 1 / (Rct + ((Tanh(sqrt(Rd * (s * 3 * Cd)))) / ((sqrt((s * 3 * Cd) / Rd) - (1 / Rd) * Tanh(sqrt(Rd * (s * 3 * Cd)))))))))))))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 0.1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl', initialValue: 0.2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rm', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rd', initialValue: 5.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 10.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\text{Trsph}';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                Detailed description of distributed element Trsph is given&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Trsph')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    here
                </span>,&nbsp;

            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/spherical_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/spherical.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Impedance of a <strong>porous electrode consisting of compressed spherical or semi-spherical particles of intercalation material</strong> such as
                conducting polymer, carbon (particularly MCMB) or mixed oxides like <Latex>{`$$\\text{LiCo0}_{2}$$`}</Latex>. Complete intercalation kinetics
                includes electronic or ionic conduction through the porous electrode, electron-transfer reaction on particle surface and solid-state diffusion inside the particles.
                <br/>
                Most materials used in commercial Li-ion batteries have semi-spherical particles which radius is common characteristic given by the manufacturers.
                This data can be used for quite exact calculation of solid-state diffusion coefficient from impedance data if this model is used. See&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Trsph')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Trsph
                </span>&nbsp;for details.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance in the electrolyte pathway that is not compensated for by the reference electrode
                    </li>
                    <li>
                        Other parameters have their usual meanings as given&nbsp;
                        <span onClick={() => handleNavigate('Distributed Elements', 'Paasch')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            here
                        </span>.
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};


export default Spherical;
