import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const FuelCellPorous = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = 'Porous electrode with faradaic reaction and activated adsorbtion';

    const circuitExpression = '(Rser + (sqrt(Rp / (s * Cdl + (1 / Rct) + (1 / (Ra + 1 / (s * Ca))))) / Tanh(sqrt(Rp * (s * Cdl + (1 / Rct) + (1 / (Ra + 1 / (s * Ca))))))))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 0.21, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct', initialValue: 0.5, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl', initialValue: 1.2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rp', initialValue: 2.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Ra', initialValue: 0.2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Ca', initialValue: 10.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\text{FCP}';


    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/fuelcellporous_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/fuelcellporous.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                The authors of MEISP [1] found that impedance spectra of PEM-fuel cell electrode
                (particularly H<sub>2</sub>-electrode) experimentally obtained by
                Ciureanu and Wang in [2]. can be analysed using this circuit, which considers porosity of
                electrode to describe observed depressed semicircles in the
                complex impedance plot. No CPE elements are needed, and additional
                information about specific conductivity of electrolyte in pores is obtained.
                <br/>
                This circuit can also be used for analysis of denting corrosion
                accompanied by activated adsorbtion at metall surface.
                In order to describe full-cell, two such circuits should
                be combined in series. Interpretation of such data could be difficult if
                time-constants of anode and cathode impedance overlap. To solve this problem,
                "symmetric" measurement, where hydrogen is fed to both electrodes could be used.
                In later case single circuit can be used for 2-electrode cell.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance in the electrolyte pathway that is not compensated for by the reference electrode
                    </li>
                    <li>
                        <Latex>{`$$R_{p}$$`}</Latex> is the resistance of electrolyte in pores. Specific resistance
	                    of electrolyte in pores can be obtained as: <Latex>{`$$\\rho = R_{p} \\cdot \\frac{S}{d}$$`}</Latex>,
                        where S is the geometric area of the porous electrode and d is its thickness.
                    </li>
                    <li>
                        <Latex>{`$$R_{ct}$$`}</Latex> is the charge-transfer resistance of electrochemical reaction. For recalculation into specific values see&nbsp;
                        <span onClick={() => handleNavigate('Distributed Elements', 'Interc')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            here
                        </span>,&nbsp;
                    </li>
                    <li>
                        <Latex>{`$$C_{dl}$$`}</Latex> is the double-layer capacitance.
                    </li>
                    <li>
                        <Latex>{`$$R_{a}$$`}</Latex> is the resistance associated with activated adsorption on catalyst. In case of catalyst poisoning might become negative.
                    </li>
                    <li>
                        <Latex>{`$$C_{a}$$`}</Latex> is capacitance associated with activated adsorbtion on catalyst. In case of catalyst poisoning might become negative.
                    </li>

                </ul>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] Multiple Electrochemical Impedance Spectra Parameterization (MEISP+). Version 2.0 Kumho Petrochemical Co. Ltd., Kumho Chemical Laboratories, P.O. Box 64, Yuseong, Taejeon, 305-600, Korea. http://powergraphy.com.
                    </li>
                    <li>
                        [2] M. Ciureanu and Hong Wang, J. Electrochem. Soc., 146/11 (1999) 4031
                    </li>

                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};


export default FuelCellPorous;
