

import React, { useEffect, useRef, useState } from 'react';
import Plotly from 'plotly.js-dist';
import { Box } from '@mui/material';
import _ from 'lodash';

const NyquistPlot2D = ({ data, initialValuesData }) => {
  const nyquistPlot2DRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const plotElement = document.getElementById('nyquist-plot-2d');
    const currentRef = nyquistPlot2DRef.current;

    const handleResize = () => {
      const update = { height: window.innerHeight * 0.5 };
      Plotly.update('nyquist-plot-2d', {}, update);
    };

    const updateWidth = _.debounce(() => {
      if (currentRef) {
        setWidth(currentRef.offsetWidth);
      }
    }, 200);

    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });

    resizeObserver.observe(currentRef);

    const drawNyquist = (data) => {
      if (data.length === 0) {
        Plotly.purge(document.getElementById("nyquist-plot-2d"));
        return;
      }

      const xAccessor = (d) => (d ? d.realImpedance : 0);
      const yAccessor = (d) => (d ? d.imagImpedance : 0);

      const trace = {
        x: data.map(xAccessor),
        y: data.map(yAccessor),
        mode: 'markers',
        type: 'scatter',
        name: 'Data',
        marker: {
          color: '#1f77b4',
          size: 8,
        },
        showlegend: false,
        hovertemplate:
          'Frequency: %{text:.4f} Hz<br>' +
          "Z'=%{x:.4f}, Z''=%{y:.4f}<extra></extra>",
        text: data.map((d) => d.freq),
      };

      const traceInitialValues = initialValuesData && Array.isArray(initialValuesData) && initialValuesData.length > 0 ? {
        x: initialValuesData.map(xAccessor),
        y: initialValuesData.map(yAccessor),
        mode: 'lines',
        type: 'scatter',
        name: 'Initial Values',
        line: {
          color: '#2ca02c',
          width: 2,
          dash: 'dot',
        },
        hovertemplate:
          "Z'=%{x:.2f}, Z''=%{y:.2f}<extra></extra>",
      } : null;

      const layout = {
        title: 'Nyquist Plot',
        xaxis: {
          title: {
            text: "Z\u2032 (\u03A9)",
            font: {
              family: 'Arial Unicode MS',
              size: 18, // Adjust the font size
              color: '#000000' // Adjust the font color
            }
          },
          tickfont: { // New section for tick labels
            size: 14, // Adjust the font size
            color: '#000000' // Adjust the font color
          },
          zeroline: false,
          gridcolor: '#eee',
          autorange: true,
          scaleratio: 1,
        },
        yaxis: {
          title: {
            text: "-Z\u2033 (\u03A9)",
            font: {
              family: 'Arial Unicode MS',
              size: 18, // Adjust the font size
              color: '#000000' // Adjust the font color
            }
          },
          tickfont: { // New section for tick labels
            size: 14, // Adjust the font size
            color: '#000000' // Adjust the font color
          },
          zeroline: false,
          gridcolor: '#eee',
          autorange: true,
          scaleanchor: 'x',
          scaleratio: 1,
        },
        // showlegend: (traceInitialValues && data.length>0) ? true : false,
        showlegend: (initialValuesData.length > 0 && data.length > 0) ? true : false,
        legend: {
          x: 0.1,
          y: 1.15,
          xanchor: 'left',
          yanchor: 'top',
          bgcolor: 'rgba(          255, 255, 255, 0.7)',
          font: {
            size: 10,
          },
        },
        hovermode: 'closest',
        margin: {
          l: 80,
          r: 40,
          b: 100,
          t: 100,
          pad: 4,
        },
        height: window.innerHeight * 0.5,
        width: width,
      };

      const config = {
        responsive: true,
        displayModeBar: 'hover', // Hide the display bar on hover
        displaylogo: false,
      };

      const traces = [trace];
      if (traceInitialValues) traces.push(traceInitialValues);

      window.addEventListener('resize', handleResize);

      Plotly.react(plotElement, traces, layout, config);
    };

    if (data.length === 0) {
      return;
    }

    drawNyquist(data, currentRef);

    return () => {
      Plotly.purge(plotElement);
      window.removeEventListener('resize', handleResize);
      if (resizeObserver && resizeObserver.unobserve) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [data, initialValuesData, width]);

  return (
    <div>
      <Box mt={1} mx="auto" width="100%">
        <div
          id="nyquist-plot-2d"
          ref={nyquistPlot2DRef}
        ></div>
      </Box>
    </div>
  );
};

export default NyquistPlot2D;
