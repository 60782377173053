import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const SphericalCenter = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Diffusion towards the center of a sphere';

    const circuitExpression = '(Tanh(sqrt(Rd * (s * 3 * Cd))) / (sqrt((s * 3 * Cd) / Rd) - (1 / Rd) * Tanh(sqrt(Rd * (s * 3 * Cd)))))';

    const circuitVariables = [
        { name: 'Rd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 200, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },

    ];

    const LatexExpression = 'Z(s) = $$\\frac{\\tanh \\sqrt{Rd \\cdot (s \\cdot 3 \\cdot Cd)}}{\\sqrt{\\frac{s \\cdot 3 \\cdot Cd}{Rd}} - \\frac{1}{Rd} \\cdot \\tanh \\sqrt{Rd \\cdot (s \\cdot 3 \\cdot Cd)}}$$';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                This scenario corresponds to the impedance caused by the diffusion of species from the surface of a spherical particle towards its center.
                It is particularly characteristic of the intercalation of Li into various intercalation materials, which exhibit near-spherical particle form.
                In specific instances, such as with MCMB, the particles are precisely spherical and the provided equations enable us to most accurately determine the solid-state diffusion coefficient.
                Such a case is discussed in [1]. Equations for spherical diffusion towards the center of a spherical particle are derived in [2]
                and are adapted here to express the equations in terms of electrical parameters.
                <br/>
                This case can be depicted as an inhomogeneous finite transmission line, in which the resistance is increasing and distributed capacitance is decreasing from left to right,
                proportional to the increase in the flow surface with distance from the electrode. Treating this transmission line as an approximation of a lumped network is impractical;
                formulating it directly as continuous differential equations is preferable. Therefore, no equivalent circuit is shown here.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/wsplim.png" alt="Nyquist" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Wsplim
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>

                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$\\frac{dE}{dc}, (volt~cm^{3}) = \\frac{F\\cdot n}{C_{d}} \\cdot \\frac{4}{3} \\cdot \\pi \\cdot r^{2}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$D (sec^{-1} cm^{3}) = \\frac{3 r^{2}}{C_{d} \\cdot R_{d}} $$`}</Latex>
                </Box>

                    Where r is the radius of the spherical electrode, S is the geometric electrode area in <Latex>{`$$cm^{2}$$`}</Latex>, n is the number of electrons participating in the reaction,
                    and c is the volume concentration in <Latex>{`$$mol \\cdot cm^{3}$$`}</Latex>. Surface area is given separately (and not fixed as <Latex>{`$$4 \\pi r$$`}</Latex>) for the
                    cases where only half of the sphere is open to the electolyte. Such case is realized for example in an ultramicroelectrode, where <Latex>{`$$S = 2 \\pi r$$`}</Latex>.

         </Typography>
         <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] E.Barsoukov, J.Hyun Kim, J.H.Kim, C.O.Yoon, H.Lee, Solid State Ionics, 116 (1999) 249
                    </li>
                    <li>
                        [2] T.Jacobson and K.West, Electrochimica Acta, 40/2 (1995) 255
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default SphericalCenter;
