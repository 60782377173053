import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import ParamPlot from './ParamPlot';


const PlotParams = ({ paramsData, errorData,  paramNames = [], indices = [], indicesName = ""}) => {
  const [showErrorbars, setShowErrorbars] = useState(false);
  const [showLogScale, setShowLogScale] = useState(false);

  const renderPlots = () => {
    return paramNames.map((paramName, idx) => (
      <Box key={idx} width={{ xs: '100%', sm: '50%', md: '33.33%', lg: '20%' }} minWidth={{ lg: '200px' }} p={1} height={300}>
        <ParamPlot
          paramData={paramsData.map((data) => data[idx])}
          errorData={errorData && errorData.map((data) => data[idx])}
          paramName={paramName}
          showLogScale={showLogScale}
          showErrorbars={showErrorbars}
          indices={indices}
          indicesName={indicesName}
        />
      </Box>
    ));
  };


  return (
    <div>
      <Box display="flex" justifyContent="center" mt={5} mx="auto" mb={3} flexWrap="wrap" width="100%" minHeight={100}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showErrorbars}
              onChange={(e) => setShowErrorbars(e.target.checked)}
            />
          }
          label="Show Error Bars"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showLogScale}
              onChange={(e) => setShowLogScale(e.target.checked)}
            />
          }
          label="Logarithmic Scale"
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        mt={5}
        mx="auto"
        mb={3}
        flexWrap="wrap"
        width="100%"
        maxHeight="80vh" // Add this line
        overflow="auto" // Add this line to enable scrolling if the content overflows
      >
        {renderPlots()}
      </Box>

    </div>
  );
};

export default PlotParams;
