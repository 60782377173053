// IndicesButton.js
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const IndicesButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff', // Set the background color to white
  color: theme.palette.primary.main, // Set the text color to the primary color for contrast
  padding: theme.spacing(1),
  borderRadius: 4,
  cursor: 'pointer',
  textAlign: 'center',
  width: '100%',
  height: '40px', // Set a height of 50px
  '&:hover': {
    backgroundColor: theme.palette.primary.light, // Change the hover color to a lighter shade of primary color
    color: '#fff', // Set the text color to white on hover
  },
}));

export default IndicesButton;
