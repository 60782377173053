import React from 'react';
import { Typography } from '@mui/material';

const License = () => {
    return (
        <Typography variant="body1" color="text.secondary" component="div">
            <h4>License</h4>
            <p>
                FitMyEIS, a web-based impedance fitting application, is provided under the Apache License 2.0. It is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
                By choosing to use FitMyEIS, you accept full responsibility for ensuring its compatibility with your web browser and any other software used to access FitMyEIS.
                You also acknowledge that you are solely responsible for the protection of your devices, as well as the backup of your data. The authors and developers of FitMyEIS will not be held liable for any damages you may suffer in connection with using or interacting with FitMyEIS.
            </p>
        </Typography>
    );
};

export default License;
