// import { ThemeProvider } from '@mui/material/styles';
// import theme from "./components/theme";
// import Layout from "./components/Layout";
// import HomePage from "./components/HomePage";
// // import MainPage from "./components/MainPage";
// import BatchFitter from "./components/BatchFitter";
// import {Route, Routes} from "react-router-dom"


// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//       <Routes>
//         <Route path="/" element={<Layout />}>
//             <Route path="/" element={<HomePage />} />
//             <Route path="/fit" element={<BatchFitter />} />
//           </Route>
//         </Routes>
//     </ThemeProvider>
//   );
// }

// export default App;
// import { ThemeProvider } from '@mui/material/styles';
// import theme from "./components/theme";
// import Layout from "./components/Layout";
// import HomePage from "./components/HomePage";
// import BatchFitter from "./components/BatchFitter";
// import TermsConditionsPopup from "./components/TermsConditionsPopup"; // Import the new component
// import PrivacyPolicy from "./components/PrivacyPolicy"; // Import the new component
// import {Route, Routes} from "react-router-dom"

// function App() {
//   return (
//     <ThemeProvider theme={theme}>
//       {!document.cookie.includes("termsAccepted=true") && <TermsConditionsPopup />} {/* Add the popup here */}
//       <Routes>
//         <Route path="/" element={<Layout />}>
//             <Route path="/" element={<HomePage />} />
//             <Route path="/fit" element={<BatchFitter />} />
//             <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Add this line */}
//         </Route>
//       </Routes>
//     </ThemeProvider>
//   );
// }

// export default App;
import { ThemeProvider } from '@mui/material/styles';
import theme from "./components/theme";
import Layout from "./components/Layout";
import HomePage from "./components/HomePage";
import BatchFitter from "./components/BatchFitter";
import TermsConditionsPopup from "./components/TermsConditionsPopup";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Cookies from 'js-cookie';  // You need to import the js-cookie package
import {Route, Routes, useLocation} from "react-router-dom"

function App() {
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      {location.pathname !== "/privacy-policy" && !Cookies.get('termsAccepted') && <TermsConditionsPopup />}
      <Routes>
        <Route path="/" element={<Layout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/fit" element={<BatchFitter />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
