import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { hierarchy, tree } from 'd3-hierarchy';
import './VisualTree.css';

const VisualTree = ({ circuitTree }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!circuitTree) return;

    // Clear the container
    d3.select(containerRef.current).selectAll('*').remove();

    const svg = d3.select(containerRef.current).append('g');

    // Create a tree layout with proper sizing
    const layout = tree().nodeSize([100, 100]);

    // Convert the circuitTree to a d3 hierarchy format
    const rootNode = hierarchy(circuitTree);

    // Apply the tree layout to the nodes
    const treeData = layout(rootNode);

    // Draw the links between the nodes
    svg
      .selectAll('.link')
      .data(treeData.links())
      .enter()
      .append('path')
      .attr('class', 'link')
      .attr('fill', 'none')
      .attr('stroke', 'black')
      .attr('d', d3.linkHorizontal().x((d) => d.y).y((d) => d.x));

    // Draw the nodes
    const nodes = svg
      .selectAll('.node')
      .data(treeData.descendants())
      .enter()
      .append('g')
      .attr('class', 'node')
      .attr('transform', (d) => `translate(${d.y},${d.x})`);

    const rectWidth = 80;
    const rectHeight = 30;

    nodes
      .append('rect')
      .attr('x', -rectWidth / 2)
      .attr('y', -rectHeight / 2)
      .attr('width', rectWidth)
      .attr('height', rectHeight)
      .attr('stroke', 'black')
      .attr('fill', 'white');

    nodes
      .append('text')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .text((d) => {
        if (d.data.type === 'ELEMENT') {
          return `${d.data.value}_${d.data.index}`;
        } else if (d.data.type === 'SERIES') {
          return 'Series';
        } else if (d.data.type === 'PARALLEL') {
          return 'Parallel';
        }
        return '';
      });

    // Adjust the SVG container size based on the content
    const bbox = svg.node().getBBox();
    const margin = 10; // Add a margin value here
    d3.select(containerRef.current)
        .attr('width', bbox.width + 2 * margin)
        .attr('height', bbox.height + 2 * margin)
        .attr(
        'viewBox',
        `${bbox.x - margin} ${bbox.y - margin} ${bbox.width + 2 * margin} ${bbox.height + 2 * margin}`
        );
  }, [circuitTree]);


  if (!circuitTree) {
    return null;
  }

  return (
    <div className="VisualCircuitContainer">
      <div className="SVGWrapper">
        <svg ref={containerRef}></svg>
      </div>
    </div>
  );

};

export default VisualTree;