import React, { useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

const FitOptions = ({ selectedFit, handleFitChange }) => {
  const [splineDf, setSplineDf] = useState(4);

  const handleSplineClick = () => {
    if (selectedFit === 'spline') {
      handleFitChange('spline', splineDf);
    } else {
      handleFitChange('spline', splineDf);
    }
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value !== 'spline') {
      handleFitChange(value);
    } else {
      handleSplineClick();
    }
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="fit-options-select-label">Fit Options</InputLabel>
        <Select
          labelId="fit-options-select-label"
          id="fit-options-select"
          value={selectedFit}
          onChange={handleSelectChange}
          label="Fit Options"
        >
          <MenuItem value="sequential">Fit sequential</MenuItem>
          <MenuItem value="simultaneous_zero">Fit Simultaneous - No smoothing</MenuItem>
          <MenuItem value="simultaneous">Fit Simultaneous</MenuItem>
          {/*
          The 'disabled' prop below makes the "Fit Spline" option unselectable.
          If you want to make it selectable again, remove the 'disabled' prop.
          */}
          <MenuItem value="spline" disabled>Fit Spline</MenuItem>
        </Select>
      </FormControl>
      {selectedFit === 'spline' && (
        <TextField
          label="df"
          type="number"
          inputProps={{ min: 4 }}
          value={splineDf}
          onChange={(e) => setSplineDf(Math.max(4, parseInt(e.target.value) || 4))}
          variant="outlined"
          size="small"
          sx={{ mt: 2 }}
        />
      )}
    </Box>
  );
};

export default FitOptions;
