import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const ColeCole = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Cole-Cole (ZARC) element - CPE in parallel with a resistor.';

    const circuitExpression = '(R / ((R * (pow(s, phi) * C)) + 1))';

    const LatexExpression = 'Z(s) = \\frac{R}{R \\cdot C \\cdot s^{\\phi} + 1}';

    const circuitVariables = [
        { name: 'R', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'phi', initialValue: 0.4, lowerBound: 1e-1, upperBound: 1.01, smoothing: '0.0' },

    ];

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };


    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                Electrochemical elements can rarely be described  by ideal capacitors. usually the semicircle in the Nyquist plot is not a perfect semicircle. It is more
                or less depressed or its peak is displaced. In case of a porous material such behavior can be well described quantitatively by a more meaningful distributed element.
                However, there are cases where the physical reasons of the depressed semicircle are not known. In such cases the Cole-Cole element can be used. The use of this
                element in the analysis of the impedance spectra obtained from dielectrics was proposed in [1].
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/cole_cole.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Cole
            </Typography>


            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$\\phi$$`}</Latex>
                    </li>

                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                R is a resistor parallel to a CPE element. In some cases it can be interpreted as the charge transfer resistance of an
                electrochemical reaction <Latex>{`$$R_{ct}$$`}</Latex>. For the C parameter, the is no possible direct conversion.
                In a case where <Latex>{`$$\\phi > 0.5$$`}</Latex>, C closely resembles a capacitor, when <Latex>{`$$\\phi < 0.5$$`}</Latex>, C is nearer to a resistor.
                At <Latex>{`$$\\phi = -1$$`}</Latex>, C is equivalent to an inductor.

            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] K.S.Cole and R.H.Cole, J.Chem.Phys. 9(1941) 341
                    </li>

                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default ColeCole;
