import React, { useCallback, useEffect, useRef } from 'react';
import Plotly from 'plotly.js-dist';
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const SingleSpectraPlot = ({ data = [], fitData = null, selectedIndex = 0, loadedFileNames = [] }) => {
    const nyquistPlot2DRef = useRef(null);
    const bodePlot2DRef = useRef(null);
    const [selectedDataIndex, setSelectedDataIndex] = React.useState(selectedIndex);

  const drawSingleNyquist = useCallback((data, fitData, dataIndex) => {
    if (!Array.isArray(data)) {
        console.error("Invalid data prop");
        return;
      }


    const dataset = data[dataIndex];

    const x = [];
    const y = [];

    if (dataset) {
        dataset.forEach((dataPoint) => {
          x.push(dataPoint.realImpedance);
          y.push(dataPoint.imagImpedance);
        });
      }

      const dataTrace = {
        x,
        y,
        mode: 'markers',
        type: 'scatter',
        name: loadedFileNames[dataIndex] || `Data ${dataIndex + 1}`,
        marker: {
            size: 10,
            color: 'rgba(0,0,0,0)',  // This is transparent
            line: {
              color: 'blue',  // This sets the border color of the marker
              width: 2  // This sets the border width of the marker
            }
            },
      };

      let fitTrace = null;

      // Check if fitDataset is defined
      if (fitData && fitData.length > 0) {
        const processedFitData = Array.isArray(fitData[0]) ? fitData : [fitData];
        const fitDataset = processedFitData[dataIndex] ? processedFitData[dataIndex] : null;

        if (fitDataset) {
          const xFit = [];
          const yFit = [];

          fitDataset.forEach((dataPoint) => {
            xFit.push(dataPoint.realImpedance);
            yFit.push(dataPoint.imagImpedance);
          });

          fitTrace = {
            x: xFit,
            y: yFit,
            mode: 'lines',
            type: 'scatter',
            name: 'Fit',
            line: { color: 'red' },
          };
        }
      }


    const traces = [dataTrace, fitTrace].filter(trace => trace !== null);

    const layout = {
      title: '',
      xaxis: {
        title: {
          text: "Z\u2032 (\u03A9)",
          font: {
            family: 'Arial Unicode MS',
            size: 18,
            color: '#000000'
          }
        },
        tickfont: {
          size: 14,
          color: '#000000'
        },
        zeroline: false,
        gridcolor: '#eee',
        autorange: true,
        scaleratio: 1,
      },
      yaxis: {
        title: {
          text: "-Z\u2033 (\u03A9)",
          font: {
            family: 'Arial Unicode MS',
            size: 18,
            color: '#000000'
          }
        },
        tickfont: {
          size: 14,
          color: '#000000'
        },
        zeroline: false,
        gridcolor: '#eee',
        autorange: true,
        scaleanchor: 'x',
        scaleratio: 1,
      },
      hovermode: 'closest',
      autosize: true,
      margin: {
        l: 70,
        r: 130,
        b: 50,
        t: 10,
        pad: 4,
      },
      legend: {
        xanchor: 'auto',
        yanchor: 'auto',
        bgcolor: 'rgba(255, 255, 255, 0.5)',
        bordercolor: '#D3D3D3',
        borderwidth: 1,
      }
    };

    const config = {
      responsive: true,
      displayModeBar: 'hover',
      displaylogo: false,
    };

    Plotly.react(nyquistPlot2DRef.current, traces, layout, config);
  }, [loadedFileNames]);

  const drawBodePlot = useCallback((data, fitData, dataIndex) => {
    const dataset = data[dataIndex];

    const freq = []; // frequency
    const magnitude = []; // magnitude
    const phase = []; // phase


    if (dataset) {
      dataset.forEach((dataPoint) => {
        freq.push(dataPoint.freq);
        magnitude.push(dataPoint.magnitude);
        phase.push(dataPoint.phase);
      });
    }

    const fitMagnitude = []; // fit magnitude
    const fitPhase = []; // fit phase

    if (fitData && fitData.length > 0) {
        const processedFitData = Array.isArray(fitData[0]) ? fitData : [fitData];
        const fitDataset = processedFitData[dataIndex] ? processedFitData[dataIndex] : null;

        if (fitDataset) {
        fitDataset.forEach((fitDataPoint) => {
            fitMagnitude.push(fitDataPoint.magnitude);
            fitPhase.push(fitDataPoint.phase);
        });
        }
    }
    const magnitudeTrace = {
      x: freq,
      y: magnitude,
      yaxis: 'y1',
      type: 'scatter',
      mode: 'markers', // Use markers for data
      name: "Magnitude",
      marker: {
        size: 7,
        color: 'rgba(0,0,0,0)',
        line: {
          color: 'blue',
          width: 2
        }
      },
    };

    const fitMagnitudeTrace = {
      x: freq,
      y: fitMagnitude,
      yaxis: 'y1',
      type: 'scatter',
      mode: 'lines', // Use lines for fit
      name: '',
      line: { color: 'blue' },
      showlegend: false, // Hide this trace from the legend
    };

    const phaseTrace = {
      x: freq,
      y: phase,
      yaxis: 'y2',
      type: 'scatter',
      mode: 'markers', // Use markers for data
      name: "Phase",
      marker: {
        size: 7,
        color: 'rgba(0,0,0,0)',
        line: {
          color: 'orange',
          width: 2
        }
      },
    };

    const fitPhaseTrace = {
      x: freq,
      y: fitPhase,
      yaxis: 'y2',
      type: 'scatter',
      mode: 'lines', // Use lines for fit
      name: '',
      line: { color: 'orange' },
      showlegend: false, // Hide this trace from the legend
    };

    const layout = {
        title: '',
        xaxis: {
          title: {
            text: 'Frequency (Hz)',
            font: {
              family: 'Arial Unicode MS',
              size: 18,
              color: '#000000'
            }
          },
          type: 'log',
          tickfont: {
            size: 14,
            color: '#000000'
          },
          zeroline: false,
          gridcolor: '#eee',
          autorange: true,
        },
        yaxis1: {
          title: {
            text: 'Magnitude (\u03A9)',
            font: {
              family: 'Arial Unicode MS',
              size: 18,
              color: 'blue'
            }
          },
          side: 'left',
          tickfont: {
            size: 14,
            color: 'blue'
          },
          zeroline: false,
          gridcolor: '#eee',
          autorange: true,
        },
        yaxis2: {
          title: {
            text: 'Phase (degrees)',
            font: {
              family: 'Arial Unicode MS',
              size: 18,
              color: 'orange'
            }
          },
          side: 'right',
          overlaying: 'y',
          tickfont: {
            size: 14,
            color: 'orange'
          },
          zeroline: false,
          gridcolor: '#eee',
          autorange: 'reversed', // Invert the y-axis
        },
        hovermode: 'closest',
        autosize: true,
        margin: {
          l: 70,
          r: 130,
          b: 50,
          t: 10,
          pad: 4,
        },
        legend: {
            xanchor: 'auto',
            yanchor: 'auto',
            bgcolor: 'rgba(255, 255, 255, 0.5)',
            bordercolor: '#D3D3D3',
            borderwidth: 1,
            showlegend: fitMagnitude.length > 0 || fitPhase.length > 0,
          }


      };
      const config = {
        responsive: true,
        displayModeBar: 'hover',
        displaylogo: false,
      };


      const traces = [magnitudeTrace, fitMagnitudeTrace, phaseTrace, fitPhaseTrace].filter(trace => trace !== null);


      Plotly.react(bodePlot2DRef.current, traces, layout, config);

  }, []);



  useEffect(() => {
    drawSingleNyquist(data, fitData, selectedDataIndex);
    drawBodePlot(data, fitData, selectedDataIndex);
}, [data, fitData, selectedDataIndex, drawSingleNyquist, drawBodePlot]);

  const handleChange = (event) => {
    setSelectedDataIndex(event.target.value);
  };

  return (
    <div>
      <FormControl variant="outlined" fullWidth>
        <InputLabel>Plot Data</InputLabel>
        <Select
          value={selectedDataIndex}
          onChange={handleChange}
          label="Data Index"
        >
          {data.map((_, index) => (
            <MenuItem key={index} value={index}>
              {loadedFileNames[index] || `Data ${index + 1}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box mx="auto" width="100%" mt={2}>
        <div
          id="single-nyquist-plot"
          ref={nyquistPlot2DRef}
          style={{ width: '100%', height: '300px', overflow: 'hidden' }}
        ></div>
      </Box>
      <Box mx="auto" width="100%" mt={2}>
        <div
          id="single-bode-plot"
          ref={bodePlot2DRef}
          style={{ width: '100%', height: '300px', overflow: 'hidden' }}
        ></div>
      </Box>
    </div>
  );


};

export default SingleSpectraPlot;