// ExpressionTextField.js
import { TextField } from '@mui/material';
import { styled } from '@mui/system';

const ExpressionTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  borderRadius: 4,
  padding: theme.spacing(1),
  boxSizing: 'border-box', // Add this line
}));

export default ExpressionTextField;



