
import React from 'react';
import { AppBar, Toolbar, useScrollTrigger } from '@mui/material';

export default function ColorChangeAppBar(props) {
  // This will trigger a re-render when the user scrolls.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  return (
    <AppBar
      elevation={trigger ? 4 : 0}
      style={{
        backgroundColor: trigger ? '#673ab7' : '#3f51b5', // Change color here
        transition: 'background-color 0.5s ease',
      }}
    >
      <Toolbar>
        {props.children}
      </Toolbar>
    </AppBar>
  );
}
