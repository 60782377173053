
import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const DiffSecReaction = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = 'Planar finite length reflective diffusion with secondary reaction';

    const circuitExpression = '(Rser + pow(pow((1 / (s * Cdl)), -1) + pow((Rct + (sqrt(Rd / ((s * Cd) + (1 / (Rp + pow(s * Cp, -1))))) * Coth(sqrt(Rd * ((s * Cd) + (1 / (Rp + pow(s * Cp, -1)))))))), -1), -1))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 8.00e-02, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl', initialValue: 0.001, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rd', initialValue: 7, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rp', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cp', initialValue: 3, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{\\left( sC_{dl} + \\frac{1}{R_{ct} + W_{phase}}\\right)}';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                Detailed description of distributed element Wphase is given&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Wphase')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    here
                </span>,&nbsp;

            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/diffsecreaction_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/diffsecreaction.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Impedance of an electrode <strong>covered with thin film</strong> of intercalation material which represents not only medium for ion diffusion but also
                possibility of trapping ions in form of a new phase can be described by the above expression. It was proposed by Barsoukov et al. for the description
                of Li intercalation into carbon materials. See&nbsp;
                        <span onClick={() => handleNavigate('Distributed Elements', 'Wo')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            Wphase
                        </span>&nbsp;for details. The circuit can be used irregardless of electrode geometry even in cases where the film is thin compared to the electrode dimensions.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance in the electrolyte pathway that is not compensated for by the reference electrode
                    </li>
                    <li>
                        <Latex>{`$$R_{ct}$$`}</Latex> is the charge transfer resistance of the electrochemical reaction
                    </li>
                    <li>
                        <Latex>{`$$C_{dl}$$`}</Latex> is the capacitance of the double-layer
                    </li>
                    <li>
                        Other parameters have their usual meanings as given&nbsp;
                        <span onClick={() => handleNavigate('Distributed Elements', 'Wphase')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            here
                        </span>.
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};

export default DiffSecReaction;
