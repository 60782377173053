import React, { useRef } from 'react';
import { Box, Grid } from '@mui/material';
import SharedButton from './SharedButton';

const validateSettings = (settings) => {
  const requiredProperties = [
    'weighting',
    'selectedFit',
    'fitParams',
    'fitParamsError',
    'paramNames',
    'fileData',
    'loadedFileNames',
    'plotData',
    'fitData',
    'wrss',
    'wrms',
    'aic',
    'expression',
    'formulaName',
    'variables',
  ];

  for (const property of requiredProperties) {
    if (!settings.hasOwnProperty(property)) {
      return false;
    }
  }
  return true;
};

const FitDataLoader = ({ onLoadFitData, setIsFileUploaded, setLoadedFormulaName, setExpression, setVariables, setIsSettingsLoaded }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const settings = JSON.parse(event.target.result);

        if (validateSettings(settings)) {
          onLoadFitData(settings);
          setLoadedFormulaName(file.name); // Set the loaded formula name
          setExpression(settings.expression);
          setVariables(
            settings.variables.map((variable) => ({
              ...variable,
              smoothing: variable.smoothing !== undefined ? variable.smoothing : '0.0',
            }))
          );
          setIsSettingsLoaded(true);
          setIsFileUploaded(true);
        } else {
          alert('The uploaded JSON file is not valid. Please ensure it contains all required properties.');
        }
      };
      reader.readAsText(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box position="relative">
      <Grid item xs={12}>
        <input
          ref={fileInputRef}
          type="file"
          id="load-fit-data"
          accept=".json"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <SharedButton style={{ width: '200px' }} onClick={handleButtonClick}>
          Load Project (JSON)
        </SharedButton>
      </Grid>
    </Box>
  );
};

export default FitDataLoader;
