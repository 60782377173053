import React from 'react';
import { Box } from '@mui/material';
import ExpressionTextField from './ExpressionTextField';

const Metrics = ({ wrss, wrms, aic }) => {
  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" width="100%">
        <ExpressionTextField
          label="WRSS"
          value={wrss === null ? '' : Number(wrss).toExponential(3)}
          InputProps={{ readOnly: true }}
          style={{ flexGrow: 1, margin: '0 8px' }}
        />
        <ExpressionTextField
          label="WRMS"
          value={wrms === null ? '' : Number(wrms).toExponential(3)}
          InputProps={{ readOnly: true }}
          style={{ flexGrow: 1, margin: '0 8px' }}
        />
        <ExpressionTextField
          label="AIC"
          value={aic === null ? '' : Number(aic).toExponential(3)}
          InputProps={{ readOnly: true }}
          style={{ flexGrow: 1, margin: '0 8px' }}
        />
      </Box>
    </Box>
  );
};

export default Metrics;

