import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const ActivatedAdsorption = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = 'Activated adsorption at a homogeneous porous surface';

    const circuitExpression = '(sqrt(Rd / ((s * Cd) + (1 / (Rp + pow(s * Cp, -1))))) * Coth(sqrt(Rd * ((s * Cd) + (1 / (Rp + pow(s * Cp, -1)))))))';

    const circuitVariables = [
        { name: 'Rd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rp', initialValue: 0.01, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cp', initialValue: 200, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);


    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                This distributed element can be used for description of electrochemical supercapacitors, where activation energy of electron transfer is
                considerable (metal-oxide type). Element is electically equivalent to a planar&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Wphase')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    finite-length reflective diffusion with secondary reaction
                </span>
                &nbsp;. Therefore the same function can be used in this case. However, the parameters have a different electrochemical meaning and can be transformed into specific resistance
                of a porous electrode <Latex>{`$$\\rho (\\Omega~cm)$$`}</Latex> and surface capacitance of a porous electrode <Latex>{`$$C_{s} (F/cm^{2})$$`}</Latex> as shown below.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/activated_adsorption.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{p} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{p} (\\Omega)$$`}</Latex>
                    </li>
                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                Due to electric equivalency, same function as for diffusion with secondary reaction (Wphase) can be used.
                However, the parameters have a different electrochemical meaning and can be transformed into specific resistance of porous electrode <Latex>{`$$\\rho (\\Omega~cm)$$`}</Latex>,
                specific double-layer capacitance of a porous electrode <Latex>{`$$C_{dl} (F/cm^{2})$$`}</Latex>, specific charge transfer resistance <Latex>{`$$R_{ct} (\\Omega cm^{2})$$`}</Latex>
                and specific pseudo-capacitance of activated adsorption <Latex>{`$$C_{s} (F/cm^{2})$$`}</Latex> as follows.
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\rho = \\frac{R_{d}}{d} \\cdot S$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$C_{dl} = \\frac{C_{d}}{S_{true}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$R_{ct} = R_{p} \\cdot S_{true} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$C_{s} = \\frac{C_{p}}{S_{true}} $$`}</Latex>
                </Box>
                Here <Latex>{`$$S_{true}$$`}</Latex> is the true electrochemically active area of the electrode and <Latex>{`$$d$$`}</Latex> is the thickness of theprorous layer.
                <Latex>{`$$S_{true}$$`}</Latex> can be obtained independently from BET analysis (usually an overestimation),
                or calculated based on knowledge of the true density of the particles <Latex>{`$$\\sigma_{true}$$`}</Latex>, packing density of the layer <Latex>{`$$\\sigma$$`}</Latex>, and
                the characteristic thickness <Latex>{`$$l$$`}</Latex> of the particles as shown below:
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$S_{true} = \\frac{S \\cdot d}{l} \\cdot \\frac{\\sigma}{\\sigma_{true}}$$`}</Latex>
                </Box>
                <br/>
                This relations is correct for flake-type particles. For spherical particles it can be accordingly modified.
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default ActivatedAdsorption;
