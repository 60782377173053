
import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const Fricke = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal }) => {
    const circuitName = 'Fricke effective medium model of two-phase material';

    const circuitExpression = '(Rser + pow(pow((1 / (s * C1)), -1) + pow(R1, -1) + pow((R2 + (1 / (s * C2))), -1) + pow((R3 + (1 / (s * C3))), -1) + pow((R4 + (1 / (s * C4))), -1), -1))'

    const circuitVariables = [
        { name: 'Rser', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'R1', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C1', initialValue: 0.001, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
        { name: 'R2', initialValue: 3.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C2', initialValue: 0.01, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
        { name: 'R3', initialValue: 10.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C3', initialValue: 0.1, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
        { name: 'R4', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C4', initialValue: 1.0, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{s \\cdot C_1 + \\frac{1}{R_1} + \\frac{1}{R_2 + \\frac{1}{s \\cdot C_2}} + \\frac{1}{R_3 + \\frac{1}{s \\cdot C_3}} + \\frac{1}{R_4 + \\frac{1}{s \\cdot C_4}}}';


    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/fricke_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
            Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/fricke.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>

            <Typography variant="subtitle1" color="text.secondary">
                Impedance spectrum of a two phase material produced by mixing a small fraction
                of randomly oriented elliptical particles of one dielectric into matrix of another
                dielectric can be described by this equivalent circuit. Model of this type are called
                effective medium models, first proposed by Maxwell and Wagner and extended
                to elliptical particles by Fricke [1]. Unfortunately there is
                no simple relation connecting the parameters of equivalent circuit and
                admitance function given by Fricke below:
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$Y(\\omega) = \\psi_{2} + \\frac{(\\psi_{1} - \\psi_{2}) \\cdot(1 - x_{2})}{1 + \\frac{x_{2}}{3} \\cdot \\left[ \\frac{(\\psi_{1}-\\psi_{2})}{\\phi_{1} \\cdot \\psi_{1}+\\psi_{2}} + \\frac{(\\psi_{1}-\\psi_{2})}{\\phi_{1} \\cdot \\psi_{1}+\\psi_{2}} + \\frac{(\\psi_{2}-\\psi_{2})}{\\phi_{3} \\cdot \\psi_{1}+\\psi_{2}} \\right]}$$`}</Latex>
                </Box>
                Here <Latex>{`$$\\psi_{1}$$`}</Latex> and <Latex>{`$$ \\psi_{2}$$`}</Latex> are complex conductivities of added particles and matrix correspondingly,
                <Latex>{`$$x_2$$`}</Latex> is mass fraction of added particles, <Latex>{`$$ \\phi_{2,4}$$`}</Latex>
                are form factors calculated from the semiaxes of the particle ellipsoids. For example
                for spherical particles values for form factors are (2,2,2), for spheroidal (12.2,1.16,1.16)
                for ellipsoidal (20.7, 6.9, 0.209). See original paper[1] for details.
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                Complex conductivity of the matrix <Latex>{`$$\\psi_{1}$$`}</Latex> can be obtained by numerical root
                finding, knowing that <Latex>{`$$\\psi_{2} = R_1 + i\\omega C_1$$`}</Latex> and the admitance function of this equivalent circuit is given as:
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$Y(\\omega) = \\frac{1}{R_1} + i\\omega C + \\frac{1}{R_2 + \\frac{1}{i\\omega C_2}} + \\frac{1}{R_3 + \\frac{1}{i\\omega C_3}} + \\frac{1}{R_4 + \\frac{1}{i\\omega C_4}}$$`}</Latex>
                </Box>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Physical meaning of parameters
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph component="div">
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> corresponds to the serial resistance due to contacts
                    </li>
                    <li>
                        <Latex>{`$$R_1$$`}</Latex> is the specific resistance of added particles.
                    </li>
                    <li>
                        <Latex>{`$$C_1$$`}</Latex> is dielectric constant <Latex>{`$$\\varepsilon$$`}</Latex> of added particles.
                    </li>
                    <li>
                        <Latex>{`$$R_2 - R_4$$`}</Latex> are the resistances which can be recalculated into specific
                        resistance of the matrix, considering particles mass fraction and
                        their form factors.
                    </li>
                    <li>
                        <Latex>{`$$C_2 - C_4$$`}</Latex> are the capacitances which can be recalculated into
                        dielectric constant of the matrix, considering particles mass fraction and
                        their form factors.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] H.Fricke, The Maxwell-Wagner Dispersion in a Suspension of Ellipsoids, J. Phys. Chem. 57 (1953) 934
                    </li>
                </ul>
            </Typography>
            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};


export default Fricke;
