import React from 'react';
import Plotly from 'plotly.js-dist';

const ParamPlot = ({ paramData, errorData, paramName, showLogScale, showErrorbars, indices = [], indicesName = "" }) => {
  const plotRef = React.useRef(null);

  React.useEffect(() => {
    const trace = {
      x: indices.length > 0 ? indices : paramData.map((_, idx) => idx),
      y: paramData.map((data) => data),
      name: paramName,
      type: 'scatter',
      mode: 'markers+lines',
      marker: { size: 6 },
    };

    if (showErrorbars && errorData) {
      trace.error_y = {
        type: 'data',
        array: errorData,
        visible: true,
      };
    }

    const layout = {
      title: paramName,
      xaxis: { title: indicesName || 'Index' },
      yaxis: {
        title: {
          text: paramName,
        },
        type: showLogScale ? 'log' : 'linear',
      },
      margin: { l: 70, r: 10, b: 40, t: 40 },
    };

    const config = {
      responsive: true,
      displaylogo: false,
    };

    Plotly.newPlot(plotRef.current, [trace], layout, config);
  }, [paramData, errorData, paramName, showLogScale, showErrorbars, indices, indicesName]);

  return <div ref={plotRef} style={{ width: '100%', height: '100%' }} />;
};

export default ParamPlot;
