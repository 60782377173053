import React, { useEffect, useRef } from 'react';
import Plotly from 'plotly.js-dist';
import { Box } from '@mui/material';
import './plotStyles.css';

const AdmittancePlot = ({ data = [], fitData = null, indices=[], indicesName = "" }) => {
  const admittancePlotRef = useRef(null);

  const handleResize = () => {
    const update = { height: window.innerHeight * 0.5 }; // Update the height based on the screen height
    Plotly.update(admittancePlotRef.current, {}, update);
  };


  const drawAdmittance = (data, fitData, indices, indicesName) => {

    const generatedIndices = indices.length
    ? indices
    : Array.from({ length: data.length }, (_, i) => i);

    const processedData = Array.isArray(data[0]) ? data : [data];
    if (processedData.length === 0 || processedData[0].length === 0) {
      Plotly.purge(document.getElementById('admittance-plot'));
      return;
    }


    const dataTraces = processedData.map((dataset, datasetIndex) => {
      const x = [];
      const y = [];
      const z = [];

      dataset.forEach((dataPoint) => {
        x.push(generatedIndices[datasetIndex]);
        y.push(dataPoint.realAdmittance);
        z.push(dataPoint.imagAdmittance);
      });

      return {
        x,
        y,
        z,
        mode: 'markers',
        type: 'scatter3d',
        name: processedData.length > 1 ? `Data ${datasetIndex + 1}` : 'Data',
        marker: { size: 2 },
        legendgroup: `group-${datasetIndex}`,
      };
    });



    let fitTraces = [];
    // In this modification, if data[datasetIndex] does not exist,
    // it will log an error message to the console, and the resulting trace will be null.
    // Then, the filter function will remove any null values from the fitTraces array.
    if (fitData && fitData.length > 0) {
      const processedFitData = Array.isArray(fitData[0]) ? fitData : [fitData];
      fitTraces = processedFitData.map((dataset, datasetIndex) => {
        if (!data[datasetIndex]) {
          console.error(`Data at index ${datasetIndex} not found.`);
          return null;
        }

        const x = [];
        const y = [];
        const z = [];

        dataset.forEach((dataPoint) => {
          x.push(generatedIndices[datasetIndex]);
          y.push(dataPoint.realAdmittance);
          z.push(dataPoint.imagAdmittance);
        });

        return {
          x,
          y,
          z,
          mode: 'lines',
          type: 'scatter3d',
          name: `Fit ${datasetIndex + 1}`,
          line: { color: 'red' },
          legendgroup: `group-${datasetIndex}`,
        };
      }).filter(trace => trace !== null);
    }


    const traces = [...dataTraces, ...fitTraces];

    const layout = {
      height: window.innerHeight * 0.5, // Adjust the height based on the screen height
      width: '100%',
      title: 'Admittance Plot',
      scene: {
        xaxis: {
          title: indicesName ? indicesName : 'Index',
        },
        yaxis: {
          title: {
            text: 'Y′ (S)',
            font: {
              family: 'Arial Unicode MS',
            },
          },
        },
        zaxis: {
          title: {
            text: 'Y″ (S)',
            font: {
              family: 'Arial Unicode MS',
            },
          },
        },
      },
      showlegend: true,
      legend: {
        x: 0.9,
        y: 0.9,
        xanchor: 'right',
        yanchor: 'top',
        bgcolor: 'rgba(255, 255, 255, 0.7)',
        font: {
          size: 10,
        },
        itemclick: 'toggleothers',
        itemdoubleclick: function (data) {
          // Reset visibility to original state on legend double-click
          const resetVisibility = traces.map((_, index) => {
            return index < dataTraces.length || (index >= dataTraces.length && index < dataTraces.length * 2);
          });

          Plotly.update('admittance-plot', { visible: resetVisibility });
          return false; // prevent the default legend double-click behavior
        },
      },
      hovermode: 'closest',
      autosize: true,
      margin: {
        l: 20, // Reduce left margin
        r: 20, // Reduce right margin
        b: 20, // Reduce bottom margin
        t: 30, // Reduce top margin
        pad: 4,
      },
    };
    const config = {
      responsive: true,
      displayModeBar: "hover",
      displaylogo: false,
    };

    const plotElement = document.getElementById('admittance-plot');
    Plotly.react(plotElement, traces, layout, config);

  };

  useEffect(() => {
    if (data.length === 0 || data[0].length === 0) {
      return;
    }
    drawAdmittance(data, fitData, indices, indicesName);


    window.addEventListener('resize', handleResize);
    const currentRef = admittancePlotRef.current;



    return () => {
      currentRef.removeAllListeners('plotly_click');
      Plotly.purge(currentRef);
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, [data, fitData, indices, indicesName]);
  return (
    <div>
      <Box mx="auto" width="100%">
        <div
          id="admittance-plot"
          ref={admittancePlotRef}
          style={{ width: '100%', height: '100%' }}
        ></div>
      </Box>
    </div>
  );
};
export default AdmittancePlot;
