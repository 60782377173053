import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
// import Latex from 'react-latex';
import 'katex/dist/katex.min.css';

const TutorialVisualizingData = ({ closeModal, selectInnerSection, selectInnerSubsection }) => {

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >

            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                1. Complex plane plots
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                FitMyEIS presents two complex plane plots in 3D; the Nyquist and admittance plots.
                When multiple data files are loaded, the data is plotted in different colors to distinguish them. Clicking a particular legend toggles the visibility of the plot of the corresponding data.
                The plots can be rotated by clicking and dragging the mouse. The mouse wheel can be used to zoom in and out of the plots.
                When a fit has not been performed, Only the data is plotted. However, when a fit has been performed, the fit (red line) is plotted along with the data.
                The x-axis corresponds to the index of the spectra unless an index file (e.g an array of voltages, termperature or time values) is loaded in which case the x-axis corresponds to the values in the index file.
            </Typography>
            <Divider/>

            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                2. Bode plots
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                FitMyEIS presents two Bode plots in 3D; the Bode phase and Bode magnitude plots.
                When multiple data files are loaded, the data is plotted in different colors to distinguish them. Clicking a particular legend toggles the visibility of the plot of the corresponding data.
                The plots can be rotated by clicking and dragging the mouse. The mouse wheel can be used to zoom in and out of the plots.
                When a fit has not been performed, Only the data is plotted. However, when a fit has been performed, the fit (red line) is plotted along with the data.  </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                3. Parameters plot
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                The plot of the resulting parameters are only shown when a fit has been performed.This is done by clicking the 'Fit Params' button.  The values of the parameters can be revealed by hovering the mouse over the points.
                Checkboxes are provided to toggle the axis from normal to log-scale and also to show the standard error of the parameters.
                 </Typography>
                 <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                4. Fit parameters table
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                A table of the resulting parameters becomes available once a fit has been performed. The values of the parameters can be accessed by clicking on the "Open Fit Parameters Table" beneath the plots.
                 </Typography>

        </Box>
    );
};


export default TutorialVisualizingData;
