// Operators.js
import React from 'react';
import { Button, Grid, Typography } from '@mui/material';

const Operators = ({ theme, handleOperatorButtonClick, handleExpressionChange, operators }) => {

  return (
    <Grid item xs={12} sm={12} style={{ marginTop: '20px' }}>
      <Typography variant="body1">Operators:</Typography>
      <Grid container spacing={1}>
        {operators.map((operator, index) => (
          <Grid item xs={4} sm={3} md={2} key={index}>
            <Button
              variant="outlined"
              onClick={() => handleOperatorButtonClick(operator)}
              onChange={handleExpressionChange}
              sx={{
                textTransform: 'none',
                fontSize: '12px', // Set a smaller font size
                padding: '4px 8px', // Set smaller padding
                minWidth: 'auto', // Override the default minWidth
                width: '100%', // Make the button take the full width of the container
                borderColor: theme.palette.primary.main, // Set the border color to the primary color
                color: theme.palette.primary.main, // Set the text color to the primary color
                '&:hover': {
                  backgroundColor: theme.palette.primary.light, // Set the background color on hover
                  borderColor: theme.palette.primary.dark, // Set the border color on hover
                  color: theme.palette.common.white, // Set the text color on hover
                },
              }}
            >
              {operator}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Operators;
