import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const PolycrystallineSolid = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = 'Polycrystalline ionic conductor';

    const circuitExpression = '(Rser + pow(pow((1 / (s * C1)), -1) + pow((1 / (pow(s, phi1) * Ccpe1)), -1) + pow(R1, -1), -1) + pow(pow((1 / (s * C2)), -1) + pow((1 / (pow(s, phi2) * Ccpe2)), -1) + pow(R2, -1), -1))';


    const circuitVariables = [
        { name: 'Rser', initialValue: 8.0e-2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Ccpe1', initialValue: 0.001, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'phi1', initialValue: 0.8, lowerBound: 1e-1, upperBound: 1.01, smoothing: '0.0' },
        { name: 'R1', initialValue: 0.5, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Ccpe2', initialValue: 0.1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'phi2', initialValue: 0.6, lowerBound: 1e-1, upperBound: 1.01, smoothing: '0.0' },
        { name: 'R2', initialValue: 0.8, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C1', initialValue: 0.0001, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C2', initialValue: 0.01, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{s \\cdot C_{1} + s^{\\phi_{1}} \\cdot C_{cpe_{1}} + \\frac{1}{R_{1}}} + \\frac{1}{s \\cdot C_{2} + s^{\\phi_{2}} \\cdot C_{cpe_{2}} + \\frac{1}{R_{2}}}';





    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                Detailed description of distributed element CPE is given&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'CPE')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    here
                </span>,&nbsp;

            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/polycrystallinesolid_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/polycrystallinesolid.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Impedance of a <strong>Impedance of polycrystalline ion-conducting materials such as LISICON</strong>.
                exhibiting 2 depressed semicircles with anomalous dispersion in <Latex>{`$$Z^{\\prime}, Z^{\\prime\\prime}$$`}</Latex>-presentation
                High frequency semicircle is attributed in [1] to intergrain-resistance and disappears at high temperatures and in very dense samples. See&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'CPE')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    here
                </span>&nbsp;for details on CPE-distributed element.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance in the electrolyte pathway that is not compensated for by the reference electrode
                    </li>
                    <li>
                        <Latex>{`$$R_{1}$$`}</Latex> is the resistance of intergrain boundaries
                    </li>
                    <li>
                        <Latex>{`$$C_{1}$$`}</Latex> is capacitance located between intergrain boundaries
                    </li>
                    <li>
                        <Latex>{`$$\\text{CPE}_{1}$$`}</Latex> represents the frequency-dependent capacitance at intergrain boundary. For cases when <Latex>{`$$\\phi_{1}=1$$`}</Latex>,
                        <Latex>{`$$C_{cpe_{1}}$$`}</Latex> simplifies to an ideal capacitor which is related to the orientation of dipoles on the intergrain boundary.
                    </li>
                    <li>
                        <Latex>{`$$R_{2}$$`}</Latex> is the resistance of the bulk particles of ionic conductor
                    </li>
                    <li>
                        <Latex>{`$$C_{2}$$`}</Latex> is capacitance due to dielectric polarisation inside bulk particles of ionic conductor
                    </li>
                    <li>
                        <Latex>{`$$\\text{CPE}_{2}$$`}</Latex> represents the frequency-dependent capacitance at intergrain boundary. For cases when <Latex>{`$$\\phi=1$$`}</Latex>,
                        <Latex>{`$$C_{cpe_{2}}$$`}</Latex> simplifies to an ideal capacitor which is related to the dipole orientation inside bulk particles of material.
                    </li>
                    <li>
                        Other parameters have their usual meanings as given&nbsp;
                        <span onClick={() => handleNavigate('Distributed Elements', 'CPE')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            here
                        </span>.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] I.D.Raistrick, C. Ho, and R.A.Huggins, Ionic Conductivity of Some Lithium Silicates and Aluminosilicates, J. Electrochem. Soc. 123 (1976) 1469
                    </li>

                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};


export default PolycrystallineSolid;
