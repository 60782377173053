
import React, { useState, useRef } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { ArrowDropUp as ArrowUpIcon, ArrowDropDown as ArrowDownIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import ColorChangeAppBar from './ColorChangeAppBar';

export default function Header() {

  const [menuOpen, setMenuOpen] = useState(null);
  const menuIconRef = useRef(null);

  const handleMenuOpen = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  return (
    <ColorChangeAppBar>
        <Typography variant="h6" component={Link} to="/" sx={{ flexGrow: 1, textDecoration: 'none', color: '#fff'}}>
          FitMyEIS
        </Typography>
        <div style={{ flexGrow: 1 }} /> {/* This div is to push the IconButton to the right */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuOpen}
          ref={menuIconRef}
        >
          {menuOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </IconButton>

        <Menu
          id="simple-menu"
          anchorEl={menuOpen}
          open={Boolean(menuOpen)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>
            <Link to="/fit" style={{ textDecoration: 'none', color: 'inherit' }}>
              Fit
            </Link>
          </MenuItem>
          {/* Add more menu items here */}
        </Menu>
    </ColorChangeAppBar>
  );
};
