import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const Paasch = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);


    const circuitName = 'Electrochemical reaction at a porous surface with subsequent finite-length diffusion (Paasch-Micka impedance)';

    const circuitExpression = '(sqrt(Rm / ((s * Cdl) + 1 / (Rct + (sqrt(Rd / (s * Cd)) * Coth(sqrt(Rd * (s * Cd))))))) * Coth(sqrt(Rm * ((s * Cdl) + 1 / (Rct + (sqrt(Rd / (s * Cd)) * Coth(sqrt(Rd * (s * Cd)))))))))';

    const LatexExpression = '\\sqrt{\\frac{R_m}{sC_{dl} + \\frac{1}{R_{ct} + \\sqrt{\\frac{R_d}{sC_d}}\\coth\\left(\\sqrt{R_d sC_d}\\right)}}}\\coth\\left[\\sqrt{R_m\\left(sC_{dl} + \\frac{1}{R_{ct} + \\sqrt{\\frac{R_d}{sC_d}}\\coth\\left(\\sqrt{R_d sC_d}\\right)}\\right)}\\right]';

    const circuitVariables = [
        { name: 'Rm', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct', initialValue: 0.01, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rd', initialValue: 0.1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 200, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                This case is typical for conducting polymers and for some intercalation materials where no phase separation occurs during intercalation.
                The electrochemical kinetic of such system can be modeled with a transmission line which distributed capacitance is in parallel with a charge transfer resistance
                in series with a&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Wo')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                Wo
                </span>&nbsp;
                element. In the case of flake-like particles, planar diffusion approximation can be used. Theoretical treatment of such situation was given by Paasch et al.[1] and it was
                applied by Popkirov et al.[2] to conducting polymers.

            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/paasch_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />


            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/paasch.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Paasch
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function in terms of electric parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">

                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z(s) = \\sqrt{\\frac{R_{m}}{sC_{dl} + z_{d}(s)}}\\cdot coth\\left[\\sqrt{R_{m} \\cdot \\left(sC_{dl} + \\frac{1}{R_{ct} + Z_{d}(s)} \\right)} \\right]$$`}</Latex>
                </Box>
                <br/>
                    where
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z_{d}(s) = \\sqrt{R_{d}C_{d}}\\cdot\\left(\\sqrt{\\frac{R_{d}}{C_{d}}} \\right)$$`}</Latex>
                </Box>
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{m} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{ct} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{dl} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                </ul>
            </Typography>


            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                Equivalent circuit parameters can be transformed into specific resistance of porous electrode <Latex>{`$$\\rho (\\Omega~cm)$$`}</Latex>,
                specific double-layer capacitance of porous electrode <Latex>{`$$C_{dl} (F/cm^{2})$$`}</Latex>, specific charge transfer resistance <Latex>{`$$R_{ct} (\\Omega cm^{2})$$`}</Latex>
                and, solid-state diffusion coefficient of intercalation species in particles <Latex>{`$$D (sec^{-1}~cm^{2})$$`}</Latex>  and emf-relation <Latex>{`$$\\frac{dE}{dc} (volt~cm^{3})$$`}</Latex> as follows.
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\rho = \\frac{R_{m}}{d} \\cdot S$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$C_{dl} = \\frac{C_{d}}{S_{true}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\frac{dE}{dc} = \\frac{F\\cdot n \\cdot l \\cdot S_{true}}{C_{d}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$D = \\frac{l^{2}}{c_{d}\\cdot R_{d}} $$`}</Latex>
                </Box>
                Here <Latex>{`$$S$$`}</Latex> is the geometric electrode surface, <Latex>{`$$d$$`}</Latex> is the thickness of the porous layer. <Latex>{`$$S_{true}$$`}</Latex> is
                the true electrochemically active area of particles, <Latex>{`$$n$$`}</Latex> - the number of transferred electrons, <Latex>{`$$l$$`}</Latex>, the
                characteristic diffusion length (half-thickness of flake-particle) and <Latex>{`$$c$$`}</Latex> is the concentration of diffusing species.
                <br/>
                <Latex>{`$$S_{true}$$`}</Latex> can be obtained independently from BET analysis (usually an overestimation), or calculated based on knowledge of
                the true density of the particles <Latex>{`$$\\sigma_{true}$$`}</Latex>, packing density of the layer <Latex>{`$$\\sigma$$`}</Latex>, and
                the characteristic thickness <Latex>{`$$l$$`}</Latex> of the flake-like particles as shown below:
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$S_{true} = \\frac{S \\cdot d}{l} \\cdot \\frac{\\sigma}{\\sigma_{true}}$$`}</Latex>
                </Box>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] G.Paasch, K.Micka, P.Gersdorf, Electochim. Acta 38 (1993) 2653
                    </li>
                    <li>
                        [2] G.S.Popkirov, E.Barsoukov, R.N.Schindler, J.Electroanal.Chem. 425 (1997) 209
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default Paasch;
