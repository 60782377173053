// import React from 'react';
// import Button from '@mui/material/Button';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import { useMediaQuery, useTheme } from '@mui/material';

// const CircuitElementInputButton = ({ toggleModal }) => {
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

//   return (
//     <Button
//       variant="outlined"
//       color="primary"
//       onClick={() => toggleModal('variable', true)}
//       startIcon={<AddCircleOutlineIcon />}
//       style={{
//         marginTop: theme.spacing(2),
//         marginBottom: theme.spacing(2),
//         fontSize: isSmallScreen ? '0.7rem' : '1rem', // Adjust font size based on screen size
//         width: '100%', // Set the width to 100%
//         height: 'auto', // Set the height to auto
//       }}
//     >
//       Circuit Element Input
//     </Button>
//   );
// };

// export default CircuitElementInputButton;
import React from 'react';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useMediaQuery, useTheme } from '@mui/material';

const CircuitElementInputButton = ({ toggleModal }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Button
      variant="outlined"
      sx={{
        backgroundColor: theme.palette.secondary.main, // Set the background color
        color: theme.palette.common.white, // Set the text color
        '&:hover': {
          backgroundColor: theme.palette.secondary.dark, // Set the background color on hover
        },
      }}
      onClick={() => toggleModal('variable', true)}
      startIcon={<AddCircleOutlineIcon />}
      style={{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontSize: isSmallScreen ? '0.7rem' : '1rem', // Adjust font size based on screen size
        width: '100%', // Set the width to 100%
        height: 'auto', // Set the height to auto
      }}
    >
      Edit Parameters
    </Button>
  );
};

export default CircuitElementInputButton;
