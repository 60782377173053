import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const Gerischer = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);


    const circuitName = 'Gerischer element.';

    const circuitExpression = '(sqrt(Rd / (s * Cd)) * Tanh(sqrt(Rd * (s * Cd))))';


    const circuitVariables = [
        { name: 'Rd', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 10, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = $$\\sqrt{\\frac{Rd}{s \\cdot Cd}} \\cdot \\tanh \\sqrt{Rd \\cdot (s \\cdot Cd)}$$';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                The response of the Gerischer element is equivalent to that of a &nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Ws')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    finite-length transmissive Warburg impedance
                </span>.
            </Typography>


            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/warburg_short.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                G
            </Typography>
            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default Gerischer;
