
// import React from 'react';
// import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';
// import { Typography } from '@mui/material';
// import { styled } from '@mui/system';
// import { useNavigate } from 'react-router-dom';
// import Header from './Header';
// import EISAnimation from './EISAnimation';
// import './Homepage.css';

// const Hero = styled(Box)(({ theme }) => ({
//   minHeight: '100vh',
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'flex-start',
//   alignItems: 'center',
//   position: 'relative',
//   overflow: 'hidden',
//   paddingTop: theme.spacing(5),
//   paddingBottom: theme.spacing(2),
// }));

// const AnimationContainer = styled(Box)(({ theme }) => ({
//   position: 'relative',
//   zIndex: 1,
// }));

// const Background = styled('div')(({ theme, image }) => ({
//   backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${image})`,
//   backgroundPosition: 'center',
//   backgroundSize: 'cover',
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   right: 0,
//   bottom: 0,
// }));

// const Content = styled(Box)(({ theme }) => ({
//   position: 'relative',
//   zIndex: 2,
//   textAlign: 'center',
//   color: 'black',
//   maxWidth: '100%',
//   padding: theme.spacing(4),
// }));

// const StyledButton = styled(Button)(({ theme }) => ({
//   backgroundColor: '#008000', // green
//   color: '#ffffff', // white
//   borderRadius: '20px',
//   '&:hover': {
//     backgroundColor: '#0000ff', // blue
//     color: '#ffffff', // white
//   },
//   '&:active': {
//     backgroundColor: '#ffffff', // white
//     color: '#008000', // green
//   },
// }));

// const HomePage = () => {
//   const navigate = useNavigate();

//   const handleStartFitting = () => {
//     navigate('/fit');
//   };

//   return (
//     <Hero>
//       <Header />
//       <Background image="/background_images/background_image_01.png" />

//       <Content>
//         <Typography variant="h2" component="h1" gutterBottom sx={{fontWeight: 700}}>
//           Welcome to FitMyEIS!
//         </Typography>
//         <Typography variant="h5" component="p" gutterBottom sx={{fontWeight: 400}}>
//           The state-of-the-art solution for fitting Electrochemical Impedance Spectroscopy (EIS) Data
//         </Typography>
//       </Content>

//       <Content>
//         <StyledButton variant="contained" onClick={handleStartFitting}>
//           Start Fitting your Data
//         </StyledButton>
//       </Content>

//       <AnimationContainer>
//         <EISAnimation
//           width={500}
//           height={400}
//           numDots={170}
//           radius={0.5}
//           amplitude={20}
//           frequency={10}
//           phaseShift={0}
//           verticalShift={20}
//         />
//       </AnimationContainer>

//     </Hero>
//   );
// }

// export default HomePage;



import React, { Suspense } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import './Homepage.css';
import Spinner from './Spinner';

const EISAnimation = React.lazy(() => import('./EISAnimation'));

const Hero = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(2),
}));

const AnimationContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
}));

const Background = styled('div')(({ theme, image }) => ({
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${image})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
}));

const Content = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  textAlign: 'center',
  color: 'black',
  maxWidth: '100%',
  padding: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#008000', // green
  color: '#ffffff', // white
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#0000ff', // blue
    color: '#ffffff', // white
  },
  '&:active': {
    backgroundColor: '#ffffff', // white
    color: '#008000', // green
  },
}));

const HomePage = () => {
  const navigate = useNavigate();

  const handleStartFitting = () => {
    navigate('/fit');
  };

  return (
    <Hero>
      <Header />
      <Background image="/background_images/background_image_01.png" />

      <Content>
        <Typography variant="h2" component="h1" gutterBottom sx={{fontWeight: 700}}>
          Welcome to FitMyEIS!
        </Typography>
        <Typography variant="h5" component="p" gutterBottom sx={{fontWeight: 400}}>
          The state-of-the-art solution for fitting Electrochemical Impedance Spectroscopy (EIS) Data
        </Typography>
      </Content>

      <Content>
        <StyledButton variant="contained" onClick={handleStartFitting}>
          Start Fitting your Data
        </StyledButton>
      </Content>

      <AnimationContainer>
        <Suspense fallback={<Spinner />}>
          <EISAnimation
            width={500}
            height={400}
            numDots={170}
            radius={0.5}
            amplitude={20}
            frequency={10}
            phaseShift={0}
            verticalShift={20}
          />
        </Suspense>
      </AnimationContainer>

    </Hero>
  );
}


export default HomePage;
