import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const IntercalationImpedance = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Finite length diffusion with secondary reaction (intercalation impedance)';

    const circuitExpression = '(sqrt(Rd / ((s * Cd) + (1 / (Rp + pow(s * Cp, -1))))) * Coth(sqrt(Rd * ((s * Cd) + (1 / (Rp + pow(s * Cp, -1)))))))';

    const circuitVariables = [
        { name: 'Rd', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 10, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rp', initialValue: 3, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cp', initialValue: 100, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = \\sqrt{\\frac{Rd}{s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}}} \\cdot \\coth\\left(\\sqrt{Rd \\cdot \\left[s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}\\right]}\\right)';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                Typical reflective finite length diffusion behavior at intermediate frequencies is ususally no longer valid at very low frequencies
                in case of intercalation materials with new phase formation. To describe intercalation impedance correctly,
                secindary reaction of new phase nucleation should be considered along the diffusion path as shown in the [1].
                In this case the impedance is analogous to that of an open circuit terminated transmission, which distributed conductance is in parallel with phase building resistance (<Latex>{`$$R_{p}$$`}</Latex>)
                and capacitance due to charge storage in a new phase (<Latex>{`$$C_{p}$$`}</Latex>).

            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/wphase_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/wphase.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Wphase
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{p} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{p} (\\Omega)$$`}</Latex>
                    </li>
                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$\\frac{dE}{dc_{s}}, (volt~cm^{3}) = \\frac{F\\cdot n \\cdot d \\cdot S}{C_{p}}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$k, (sec^{-1}) = \\frac{R \\cdot T}{S \\cdot d \\cdot R_{p} \\cdot n^{2} \\cdot F^{2} \\cdot c_{s} \\cdot \\exp \\left(\\frac{\\alpha \\cdot F \\cdot E_{0}}{R \\cdot T} \\right)} $$`}</Latex>
                </Box>
                <div>
                    Where d is the thickness of the diffusion layer (length of the transmission line), S is the geometric electrode area in <Latex>{`$$cm^{2}$$`}</Latex>, n is the number of electrons participating in the reaction, and c is the volume concentration in <Latex>{`$$mol \\cdot cm^{3}$$`}</Latex>.
                </div>
                <br />
                    <div>Here, <Latex>{`$$\\frac{dE}{dc_{s}}$$`}</Latex> is the voltage quotient of concentration of secondary product,</div> and <Latex>{`$$k$$`}</Latex> is the rate constant of the secondary reaction.
         </Typography>
         <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] E.Barsoukov, J.H.Kim, D.H.Kim, K.S.Hwang, C.O.Yoon and H.Lee, J.New Materials for Electrochemical Syatems, 3, 301
                    </li>
                </ul>
            </Typography>


            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default IntercalationImpedance;
