import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const CylindricalInfinite = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Cylindrical diffusion toward infinite volume';

    const circuitExpression = '(((K0(sqrt(2 * Rd * (s * Cd)))) / ((sqrt(2 * Rd * (s * Cd))) * K1(sqrt(2 * Rd * (s * Cd))))) * Rd)';

    const circuitVariables = [
        { name: 'Rd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 0.01, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },

    ];

    const LatexExpression = 'Z(s) = \\frac{K_{0}(\\sqrt{2 \\cdot Rd \\cdot (s \\cdot Cd)})}{\\sqrt{2 \\cdot Rd \\cdot (s \\cdot Cd)} \\cdot K_{1}(\\sqrt{2 \\cdot Rd \\cdot (s \\cdot Cd)})} \\cdot Rd';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                This closely corresponds to the impedance of a conductive rod dipped into a large volume of an electrolyte. It can also be important as one of
                the building blocks of an impedance of a porous electrode composed of a thin wire or mesh. Used equations are derived from [1]
                and are modified here to express them in terms of electric parameters.
                <br/>
                This case can be represented as an inhomogeneous infinite transmission line, in which the resistance is decreasing and distributed capacitances is increasing from left to right
                proportionally to the increase of flow surface with distance from the electrode. Treating this transmission line as an approximation of a lumped network is impractical;
                formulating it directly as continuous differential equations is preferable. Therefore, no equivalent circuit is shown here.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/wcyinf.png" alt="Nyquist" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
                <br/>
                <br/>
                Here <Latex>{`$$K_{0}$$`}</Latex> and <Latex>{`$$K_{1}$$`}</Latex> are modified Bessel functions of the second kind of order 0 and 1 respectively.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Wcyinf
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>

                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$\\frac{dE}{dc}, (volt~cm^{3}) = \\frac{F\\cdot n \\cdot \\pi r^{2}h}{C_{d}}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$D (sec^{-1} cm^{3}) = \\frac{r^{2}}{2 C_{d} \\cdot R_{d}} $$`}</Latex>
                </Box>

                    Where r is the radius of the cylindrical rod, h is the length of the cylindrical rod in <Latex>{`$$cm^{2}$$`}</Latex>, n is the number of electrons participating in the reaction,
                    and c is the volume concentration of diffusing species in <Latex>{`$$mol \\cdot cm^{3}$$`}</Latex>.

         </Typography>
         <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] T.Jacobsen and K.West, Electrochimica Acta, 40/2 (1995) 255
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default CylindricalInfinite;
