import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // You can customize the primary color here
      dark: '#1565c0', // You can customize the dark variant of the primary color here
    },
  },
  typography: {
    fontFamily: [
      'Arial',
      'serif',
    ].join(','),
  },
});

export default theme;
