import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const Interc = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);


    const circuitName = 'Electrochemical reaction at a porous surface hindered by a passivating layer, with subsequent finite-length diffusion followed by a secondary reaction (Li-ion intercalation electrode impedance).';

    const circuitExpression = '(sqrt(Rm * ((1 / ((s * Cdl) + 1 / (Rct + (sqrt(Rd / ((s * Cd) + 1 / (Rp + (1 / (s * Cp))))) * Coth(sqrt(Rd * ((s * Cd) + 1 / (Rp + (1 / (s * Cp)))))))))) + (1 / ((s * C1) + 1 / R1)))) * Coth(sqrt(Rm / ((1 / ((s * Cdl) + 1 / (Rct + (sqrt(Rd / ((s * Cd) + 1 / (Rp + (1 / (s * Cp))))) * Coth(sqrt(Rd * ((s * Cd) + 1 / (Rp + (1 / (s * Cp)))))))))) + (1 / ((s * C1) + 1 / R1))))))';

    const LatexExpression = 'Z(s) = \\sqrt{R_{m} \\cdot \\frac{1}{sC_{dl} + \\frac{1}{R_{ct} + \\sqrt{\\frac{Rd}{s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}}} \\cdot \\coth\\left(\\sqrt{Rd \\cdot \\left[s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}\\right]}\\right)}} + \\frac{1}{sC_{1} + \\frac{1}{R_{1}}}} \\coth\\left[ \\sqrt{\\frac{R_{m}}{\\frac{1}{sC_{dl} + \\frac{1}{R_{ct} + \\sqrt{\\frac{Rd}{s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}}} \\cdot \\coth\\left(\\sqrt{Rd \\cdot \\left[s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}\\right]}\\right)}} + \\frac{1}{sC_{1} + \\frac{1}{R_{1}}}}} \\right]';

    const circuitVariables = [
        { name: 'Rm', initialValue: 2.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct', initialValue: 0.2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 100, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rp', initialValue: 3.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cp', initialValue: 500, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'R1', initialValue: 0.02, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C1', initialValue: 0.1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                In case of Li-intercalation materials, in addition to electrochemical processes, such a those described under the element&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Trphase')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Trphase
                </span>,&nbsp;
                it is necessary to consider the hindrance provided by the passivating layer at the particle interface. This layer is built at contact between organic electrolytes and
                Li-intercalation anodes due to very low potentials at which such anodes operate, making all organic materials thermodynamically unstable with them [1]. Similar
                layer has been observed at intercalation cathodes operating over 3.5V vs Li. A model considering this process has been proposed by Barsoukov et al. [2]. This
                model is also used for creating non-linear equivalent circuit valid for time-domain simulation [3].
                <br/>
                It is worth noting that confident estimation of low-frequency related parameters (<Latex>{`$$R_{p}$$`}</Latex>, <Latex>{`$$C_{p}$$`}</Latex>, and <Latex>{`$$R_{d}$$`}</Latex>) requires
                very wide-frequency impedance spectrum, usually to <Latex>{`$$100~ \\mu Hz$$`}</Latex>. Such spectrum is impractical to measure even with real-time impedance spectrometer
                where all frequencies are measured simultaneously (duration is still 2hrs). To circumvent this problem, it is possible to use information from time-domain OCV/charge measurements to
                estimate the sum of <Latex>{`$$R_{p}$$`}</Latex> and <Latex>{`$$C_{p}$$`}</Latex>.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/interc_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />
            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/interc.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Interc
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function in terms of electric parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">

                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z(s) = \\sqrt{R_{m} \\cdot Z_{s}(s)} \\coth\\left[ \\sqrt{\\frac{R_{m}}{Z_{s}(s)}} \\right]$$`}</Latex>
                </Box>
                <br/>
                    where
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z_{s}(s) = \\frac{1}{sC_{dl} + \\frac{1}{R_{ct} + Z_{d}(s)}} + \\frac{1}{sC_{1} + \\frac{1}{R_{1}}}$$`}</Latex>
                </Box>
                <br/>
                    and
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z_{d}(s) = \\sqrt{\\frac{Rd}{s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}}} \\cdot \\coth\\left(\\sqrt{Rd \\cdot \\left[s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}\\right]}\\right)$$`}</Latex>
                </Box>
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{m} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{ct} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{dl} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{p} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{p} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{1} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{1} (F)$$`}</Latex>
                    </li>
                </ul>
            </Typography>


            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                Equivalent circuit parameters can be transformed into specific resistance of passivating layer <Latex>{`$$R_{1}^{\\prime} (\\Omega~cm)$$`}</Latex>,
                specific capacitance of passivating layer <Latex>{`$$C_{1}^{\\prime} (F/cm^{2})$$`}</Latex>, specific charge transfer resistance <Latex>{`$$R_{ct} (\\Omega cm^{2})$$`}</Latex>, specific
                double-layer capacitance of porous electrode <Latex>{`$$C_{dl}^{\\prime} (F/cm^{2})$$`}</Latex>, specific resistance of porous electrode <Latex>{`$$\\rho (\\Omega~cm)$$`}</Latex>,
                and, solid-state diffusion coefficient of intercalation species in particles <Latex>{`$$D (sec^{-1}~cm^{2})$$`}</Latex>, emf-relation due to homogeneous
                intercalation <Latex>{`$$\\frac{dE}{dc} (volt~cm^{3})$$`}</Latex>, emf-relation due to new phase formation
                intercalation <Latex>{`$$\\frac{dE_{p}}{dc_{p}} (volt~cm^{3})$$`}</Latex> and volume-specific resistance of the secondary reaction resulting from a
                new phase formation <Latex>{`$$R^{\\prime}_{p} (\\Omega~cm^{3})$$`}</Latex>as follows.
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$R_{1}^{\\prime} = R_{1} \\cdot S_{true} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$C_{1}^{\\prime} = \\frac{C_{1}}{S_{true}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$R_{ct}^{\\prime} = R_{ct} \\cdot S_{true} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$C_{dl}^{\\prime} = \\frac{C_{dl}}{S_{true}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\rho = \\frac{R_{m}}{d} \\cdot S$$`}</Latex>
                </Box>

                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\frac{dE}{dc} = \\frac{F\\cdot n \\cdot l \\cdot S_{true}}{C_{d}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$D = \\frac{l^{2}}{C_{d}\\cdot R_{d}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\frac{dE_{p}}{dc_{p}} = \\frac{F\\cdot n \\cdot l \\cdot S_{true}}{C_{p}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$R_{p}^{\\prime} = R_{p} \\cdot S_{true} \\cdot l $$`}</Latex>
                </Box>

                Here <Latex>{`$$S$$`}</Latex> is the geometric electrode surface, <Latex>{`$$d$$`}</Latex> is the thickness of the porous layer. <Latex>{`$$S_{true}$$`}</Latex> is
                the true electrochemically active area of particles, <Latex>{`$$n$$`}</Latex> - the number of transferred electrons, <Latex>{`$$l$$`}</Latex>, the characteristic
                diffusion length (half-thickness of flake-particle), <Latex>{`$$c$$`}</Latex> is the concentration of diffusing species and <Latex>{`$$c_{p}$$`}</Latex> is the
                concentration of particles separated as a different phase.
                <br/>
                <Latex>{`$$S_{true}$$`}</Latex> can be obtained independently from BET analysis (usually an overestimation), or calculated based on knowledge of
                the true density of the particles <Latex>{`$$\\sigma_{true}$$`}</Latex>, packing density of the layer <Latex>{`$$\\sigma$$`}</Latex>, and
                the characteristic thickness <Latex>{`$$l$$`}</Latex> of the flake-particles as shown below:
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$S_{true} = \\frac{S \\cdot d}{l} \\cdot \\frac{\\sigma}{\\sigma_{true}}$$`}</Latex>
                </Box>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] E.Peled, J.Electrochem.Soc 126 (1979), 2047
                    </li>
                    <li>
                        [2] E.Barsoukov, J.Hyun Kim, D.H.Kim, K.S.Hwang, C.O.Yoon, H.Lee, J.New Materials for Electrochemical Systems, 3, 301
                    </li>
                    <li>
                        [3] E.Barsoukov, J.H Kim, C.O.Yoon, H.Lee, J.Power Sources 83 (1999), 61
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default Interc;
