
import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const IonicElectronicConductor = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = 'Porous electrode with ionic and electronic conductivity ';

    const circuitExpression = '(Rser + ((1 + Tanh(sqrt((Ri + Re) / (4 * (1 / ((s * Cd) + 1 / (Rp + 1 / (s * Cp))))))) / (sqrt((Ri + Re) / (4 * (1 / ((s * Cd) + 1 / (Rp + 1 / (s * Cp)))))))) * ((Ri * Re) / (Ri + Re)) + sqrt(Ri * Re * (1 / ((s * Cd) + 1 / (Rp + 1 / (s * Cp))))) * Coth(2 * (sqrt((Ri + Re) / (4 * (1 / ((s * Cd) + 1 / (Rp + 1 / (s * Cp))))))))))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 0.0001, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rp', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Re', initialValue: 5.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Ri', initialValue: 5.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cp', initialValue: 2.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\text{Fletch}';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                Detailed description of distributed element Fletch is given&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Fletch')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    here
                </span>,&nbsp;

            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/ionicelectronicadsorption_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/ionicelectronicadsorption.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Impedance of a <strong>porous electrode with homogeneous distribution of surface along the electrode thickness in presence of activated adsorbtion</strong>. Both
                ionic conductivity of electrolyte in pores and electronic conductivity between particles is considered. Usually such situation is realized in composite electrodes,
                consisting of compressed particles, such as oxide-type intercalation cathodes. See&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Fletch')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Fletch
                </span>&nbsp;for details.
                In many cases the separate effects of both conductivities are difficult to distinquish in the experimental spectrum, therefore use of the&nbsp;
                <span onClick={() => handleNavigate('Porous Electrodes', 'Activated adsorption circuit')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    simplified model
                </span>&nbsp;neglecting one of them is justified.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance in the electrolyte pathway that is not compensated for by the reference electrode
                    </li>
                    <li>
                        Other parameters have their usual meanings as given&nbsp;
                        <span onClick={() => handleNavigate('Distributed Elements', 'Fletch')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            here
                        </span>.
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};

export default IonicElectronicConductor;
