
/////////////////////////////////////
import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Grid, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { getImpedance, parseCircuit } from './circuitUtils';
import VisualCircuit from './VisualCircuit';
import VisualTree from './VisualTree';
import './CircuitComposer.css'
import { styled } from '@mui/system';
import DraggablePaper from './DraggablePaper';
import ExpressionTextField from './ExpressionTextField';
import SharedButton from './SharedButton';
import ResetButton from './ResetButton';


const CircuitTextWrapper = styled(Box)({
  maxHeight: '60px',
  overflowY: 'auto',
});

const ImpedanceWrapper = styled(Box)({
  maxHeight: '60px',
  overflowY: 'auto',
});

const elements = ['R', 'L', 'C', 'CPE', 'W', 'Wo', 'Ws', 'G', 'Wphase', 'Wgen', 'Wspinf', 'Wsplim', 'Wcyinf', 'Wcylim', 'Paasch', 'Fletch', 'Trsph', 'Trphase', 'Interc', 'Transhf', 'Cole', 'DavCole', 'HavNeg', 'NUD', 'FCP', 'Armstr'];
const elementNames = [
  'Resistor',
  'Inductor',
  'Capacitor',
  'Constant phase element',
  'Warburg',
  'Warburg open',
  'Warburg short',
  'Gerischer element',
  'Planar finite length diffusion with secondary reaction',
  'Finite length diffusion with general boundary',
  'Spherical infinite diffusion',
  'Diffusion towards the center of a sphere',
  'Cylindrical Diffusion towards infinite volume',
  'Diffusion toward the center of a cylindrical rod',
  'intercalation at porous electrode followed by diffusion inside particles',
  'Activated adsorption at a porous electrode with ionic and electronic conductivity',
  'Intercalation at a porous electrode with diffusion inside spherical particles',
  'Intercalation at a porous electrode with diffusion inside particles with secondary reaction',
  'intercalation at a passivated porous electrode, diffusion inside particles with secondary reaction',
  'Intercalation at a porous electrode, only for high frequencies',
  'Cole-Cole (ZARC element)',
  'Generalized Davidson-Cole response',
  'Havriliak-Negami (HN) element',
  'Non-uniform diffusion',
  'Fuel-cell/porous',
  'Armstrong-Edmondson',
];

const CircuitComposer = ({openComposer, setOpenComposer, setExpression, setCircuit, initialCircuitString, clearCircuit, setLoadedFormulaName}) => {
  const [circuitString, setCircuitString] = useState('');
  const [elementCounters, setElementCounters] = useState({
    R: 0,
    L: 0,
    C: 0,
    CPE: 0,
    W: 0,
    Wo: 0,
    Ws: 0,
    G: 0,
    Wphase: 0,
    Wgen: 0,
    Wspinf: 0,
    Wsplim: 0,
    Wcyinf: 0,
    Wcylim: 0,
    Paasch: 0,
    Fletch: 0,
    Trsph: 0,
    Trphase: 0,
    Interc: 0,
    Transhf: 0,
    Cole: 0,
    DavCole: 0,
    HavNeg: 0,
    NUD: 0,
    FCP: 0,
    Armstr: 0,
  });


  const [circuitTree, setCircuitTree] = useState(null);
  const [openCircuitModal, setOpenCircuitModal] = useState(false);
  const [openTreeModal, setOpenTreeModal] = useState(false);
  const [impedances, setImpedances] = useState([]);
  const [currentCircuit, setCurrentCircuit] = useState('');
  const [error, setError] = useState(null);



  const inputRef = useRef(null);

  const handleOpenCircuitModal = () => {
    setOpenCircuitModal(true);
  };

  const handleCloseCircuitModal = () => {
    setOpenCircuitModal(false);
  };

  const handleOpenTreeModal = () => {
    setOpenTreeModal(true);
  };

  const handleCloseTreeModal = () => {
    setOpenTreeModal(false);
  };

  useEffect(() => {
    if (circuitString === '') {
      setCurrentCircuit('');

    } else {
      const { impedance: updatedCircuit, circuitTree: newCircuitTree, error } = parseCircuit(circuitString);

      if (error) {
        setError(error);
      } else {
        setError(null);
        setCurrentCircuit(updatedCircuit);
        setCircuitTree(newCircuitTree);
      }

      // Update the impedances array based on the current circuit string
      const newImpedances = [];
      for (const elementType of elements) {
        const elementMatches = circuitString.match(new RegExp(`\\b${elementType}_\\d+\\b`, 'g')) || [];
        for (const elementMatch of elementMatches) {
          const index = parseInt(elementMatch.split('_')[1], 10);
          newImpedances.push({
            element: elementMatch,
            impedance: getImpedance(elementType, index, 's'),
          });
        }
      }
      setImpedances(newImpedances);
    }
  }, [circuitString, setCurrentCircuit]);


  useEffect(() => {
    setCircuitString(initialCircuitString);
  }, [initialCircuitString]);


  const handleChange = (event) => {
    setCircuitString(event.target.value);
  };



  const handleSubmit = () => {
    const { impedance: impedanceExpression } =  parseCircuit(circuitString);

    // const impedanceExpression = parseCircuit(circuitString);
    setCurrentCircuit(impedanceExpression);
    setCircuit(circuitString);
    setExpression(impedanceExpression);
    setLoadedFormulaName('');
    //close modal on submit
    setOpenComposer(false);
  };


  const handleElementClick = (element) => {
    const currentCount = (circuitString.match(new RegExp(`\\b${element}_\\d+\\b`, 'g')) || []).length;
    const updatedCounters = { ...elementCounters, [element]: currentCount + 1 };
    setElementCounters(updatedCounters);

    const cursorPosition = inputRef.current.selectionStart;
    const beforeCursor = circuitString.slice(0, cursorPosition);
    const afterCursor = circuitString.slice(cursorPosition);

    const newElement = `${element}_${currentCount}`;
    setCircuitString(`${beforeCursor}${newElement}${afterCursor}`);

    // Set the cursor position after the new element
    const newCursorPosition = cursorPosition + newElement.length;
    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };


  const handleFunctionClick = (func) => {
    const cursorPosition = inputRef.current.selectionStart;
    const beforeCursor = circuitString.slice(0, cursorPosition);
    const afterCursor = circuitString.slice(cursorPosition);

    const newFunc = `${func}`;
    setCircuitString(`${beforeCursor}${newFunc}${afterCursor}`);

    let newCursorPosition;
    if (func === ',') {
      // Set the cursor position after the comma
      newCursorPosition = cursorPosition + newFunc.length;
    } else {
      // Set the cursor position inside the parentheses (if needed)
      newCursorPosition = cursorPosition + newFunc.length - 1;
    }

    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  };

  const handleClear = () => {
    clearCircuit();
    setCircuitString('');
    setCurrentCircuit('');
    setCircuitTree(null);
  };



  return (
    <Box>
      <Dialog
        open={openComposer}
        onClose={() => setOpenComposer(false)}
        PaperComponent={DraggablePaper}
        PaperProps={{ handle: '.modal-title' }}
        maxWidth='md'
        fullWidth
      >
        <div className="modal-title">
          <DialogTitle>Composer</DialogTitle>
        </div>
        <DialogContent>
          <Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sx={{ marginBottom: 1 }}>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" style={{ fontSize: '0.8rem', fontStyle: 'italic' }}>
                    For example, the Randles circuit shown will be represented as: ser(R_0, par(C_0, ser(R_1, W_0)))
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <img src="/circuit_images/randles_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '50%', marginBottom: '20px' }} />
                </Grid>
              </Grid>

              <Typography variant="body2" style={{ fontSize: '0.7rem', color: '#FFA500', fontStyle: 'italic' }}>
                "ser" represents a series combination while "par" represents a parallel combination of elements.
              </Typography>
                <ExpressionTextField
                  inputRef={inputRef}
                  id="circuit-input"
                  label="Circuit"
                  value={circuitString}
                  onChange={handleChange}
                  multiline
                  fullWidth
                  variant="outlined"
                  rows={2}
                  inputProps={{
                    spellCheck: 'false',
                    style: {
                      overflow: 'auto', // Enable scrolling when the content overflows
                    },
                  }}
                />
                {error && <Typography variant="body2" color="error">{error}</Typography>}
              </Grid>

              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} style={{ maxHeight: '150px', overflowY: 'auto' }}>
                  {/* Additional buttons for ser(), par(), and comma */}
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => handleFunctionClick("ser()")} sx={{ textTransform: "none"}}>
                      ser()
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => handleFunctionClick("par()")} sx={{ textTransform: "none" }}>
                      par()
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="secondary" onClick={() => handleFunctionClick(",")} sx={{ textTransform: "none", fontSize: '1.0rem' }}>
                      ,
                    </Button>
                  </Grid>
                    {elements.map((element, index) => (
                      <Grid item key={index}>
                        <Tooltip title={elementNames[index]} enterDelay={500} leaveDelay={200}>
                          <Button variant="outlined" onClick={() => handleElementClick(element)} sx={{ textTransform: 'none' }}>
                            {element}
                          </Button>
                        </Tooltip>
                      </Grid>
                    ))}
                </Grid>

                  <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={6} sx={{ marginTop: 1, marginBottom: 2 }}>
                    <Typography variant="h6">Element Impedance Expression:</Typography>
                    <ImpedanceWrapper style={{ maxHeight: '100px', overflow: 'auto' }}>
                      {impedances.map((impedance, index) =>
                        circuitString.includes(impedance.element) && (
                          <div key={index}>
                            Z{impedance.element}: {impedance.impedance}
                          </div>
                        )
                      )}
                    </ImpedanceWrapper>
                  </Grid>

                  <Grid item xs={12} sm={6} sx={{ marginTop: 1, marginBottom: 2 }}>
                    <Typography variant="h6">Circuit Impedance Expression:</Typography>
                    <CircuitTextWrapper style={{ maxHeight: '100px', overflow: 'auto' }}>{currentCircuit}</CircuitTextWrapper>
                </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <SharedButton variant="contained" color="primary" onClick={handleSubmit}>Submit</SharedButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <ResetButton variant="outlined" color="secondary" onClick={handleClear}>Clear</ResetButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SharedButton variant="outlined" color="primary" onClick={handleOpenCircuitModal}>View Circuit</SharedButton>
                      <Dialog
                        open={openCircuitModal}
                        onClose={handleCloseCircuitModal}
                        PaperComponent={DraggablePaper}
                        aria-labelledby="draggable-dialog-title"
                      >
                        <div className="modal-title">
                          <DialogTitle>Circuit Schematic</DialogTitle>
                        </div>
                        <DialogContent style={{maxHeight: '500px', overflowY: 'auto'}}>
                          {circuitTree && <VisualCircuit circuitTree={circuitTree} />}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseCircuitModal} color="primary">Close</Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SharedButton variant="outlined" color="primary" onClick={handleOpenTreeModal}>View Tree</SharedButton>
                      <Dialog
                        open={openTreeModal}
                        onClose={handleCloseTreeModal}
                        PaperComponent={DraggablePaper}
                        aria-labelledby="draggable-dialog-title"
                      >
                        <div className="modal-title">
                          <DialogTitle>Tree Schematic</DialogTitle>
                        </div>
                        <DialogContent style={{maxHeight: '500px', overflowY: 'auto'}}>
                          {circuitTree && <VisualTree circuitTree={circuitTree} />}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseTreeModal} color="primary">Close</Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Grid>
                </Grid>

            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenComposer(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  </Box>
  );
};

export default CircuitComposer;
