import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const ConstantPhaseElement = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Simplified case valid for spectra restricted to high-frequencies.';

    const circuitExpression = '(1 / (pow(s, phi) * Q))';

    const LatexExpression = 'Z(s) = \\frac{1}{C \\cdot s^{\\phi}}';

    const circuitVariables = [
        { name: 'Q', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'phi', initialValue: 0.4, lowerBound: 1e-1, upperBound: 1.01, smoothing: '0.0' },

    ];

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };


    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                Frequency dependent impedance can be described by a constant phase element (CPE). This dependence occurs in many cases of inhomogeneous porous layer and in solid-state conductors.
                No physical process can result in such a dependency to be valid from 0 to infinite frequency (except for special values of <Latex>{`$$\\phi$$`}</Latex>), because
                such a spectrum will not be compatible with the Kramer-Kronig relations. However, the CPE can be strictly valid for some restricted frequency regions.
                The use of CPE for analysis is recommended only if there is no way to obtain physically relevant model of the process since the <Latex>{`$$\\phi$$`}</Latex> factor
                does not have a physical meaning and so information which could be obtained for the spectrum (for instance the profile of inhomogneity) is lost.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/cpe.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                CPE
            </Typography>


            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$C (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$\\phi$$`}</Latex>
                    </li>

                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                No direct conversion is possible. When <Latex>{`$$\\phi > 0.5$$`}</Latex>, C closely resembles a capacitor,
                and when <Latex>{`$$\\phi < 0.5$$`}</Latex>, C is nearer to a resistor. At <Latex>{`$$\\phi = -1$$`}</Latex>, C is equivalent to an inductor.

            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default ConstantPhaseElement;
