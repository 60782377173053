
import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const TwoPhaseMaterial = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal }) => {
    const circuitName = 'Easy path model by Schoeler';

    const circuitExpression = '(Rser + pow(pow((R1 + (1 / (s * C1))), -1) + pow(R2, -1) + pow((1 / (s * C2)), -1), -1))'

    const circuitVariables = [
        { name: 'Rser', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'R1', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C1', initialValue: 0.01, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
        { name: 'R2', initialValue: 3, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C2', initialValue: 0.001, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{\\frac{1}{R_1 + \\frac{1}{s \\cdot C_1}} + \\frac{1}{R_2} + s \\cdot C_2}';


    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/two_phase_material_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
            Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/two_phase_material.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>

            <Typography variant="subtitle1" color="text.secondary">
                Easy-path model of two-component ceramic considering presence of
                both high-conductivity and low-conductivity (blocked) path-ways between
                the grains. This equivalent circuit was proposed by Schoeler [1].
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Physical meaning of parameters
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph component="div">
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> corresponds to the serial resistance due to contacts
                    </li>
                    <li>
                        <Latex>{`$$R_1$$`}</Latex> is resistance between grains with poor contact (capacitively blocked).
                    </li>
                    <li>
                        <Latex>{`$$C_1$$`}</Latex> is capacitance of insulating layer between grains.
                    </li>
                    <li>
                        <Latex>{`$$R_2$$`}</Latex> is bulk resistance of grains, assuming conducting path
		                    between grains at places of good contact (easy path).
                    </li>
                    <li>
                        <Latex>{`$$C_2$$`}</Latex> bulk capacitance due to dielectric relaxation in the bulk of the grains.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] E.J.L.Schoeler, Ph.D. thesis, Institut National Plytechnique de Grenoble (1979)
                    </li>
                </ul>
            </Typography>
            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

    };


export default TwoPhaseMaterial;
