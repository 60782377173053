
import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const StrongAdsorption = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal }) => {
    const circuitName = 'Double layer with strong adsorption';

    const circuitExpression = '(Rser + pow(pow((R1 + pow(pow(R2, -1) + pow((1 / (s * C2)), -1), -1)), -1) + pow((1 / (s * C1)), -1), -1))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'R1', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C1', initialValue: 0.001, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
        { name: 'R2', initialValue: 5, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C2', initialValue: 0.01, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{\\frac{1}{R_1 + \\frac{1}{\\frac{1}{R_2} + s \\cdot C_2}} + s \\cdot C_1}';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/strong_adsorption_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/strong_adsorption.png" alt="Typical spectrum" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>


            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                If intermediate of electrochemical reaction exhibits <strong>strong adsorbtion</strong> to the electrode surface, high-frequency impedance of
                electrochemical double-layer can be expressed by this circuit, as proposed by Grahame [1] and Gerisher et al. [2]. At lower frequencies mass transport has to be considered.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Physical meaning of parameters
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <ul>
                    <li><Latex>{`$$C_{1}$$`}</Latex> is double-layer capacitance</li>
                    <li><Latex>{`$$C_{2}$$`}</Latex> is associated with maximal amount of adsorbed specie</li>
                    <li><Latex>{`$$R_{1}$$`}</Latex> is charge transfer resistance, which is often denoted as R<sub>ct</sub>.</li>
                    <li><Latex>{`$$R_{2}$$`}</Latex> is resistance associated with activation energy of the adsorption/desorption</li>
                </ul>
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>References:</h3>
                <ul>
                    <li>[1] D.C.Grahame, Mathematicqal Theory of the Faradaic Admittance, J. Electrochem. Soc. 99 (1952), 370</li>
                    <li>[2] H. Gerisher and W. Mehl, Zum Mechanismus der Kathodischen Wasserstoffabscheidung an Quecksilber, Silber, und Kupfer, Z. Electrochem. 59 (1955) 1049</li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
    };


export default StrongAdsorption;
