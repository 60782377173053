import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const Debye = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal }) => {
    const circuitName = 'Debye equivalent circuit';

    const circuitExpression = '(Rser + pow(pow((R1 + (1 / (s * C2))), -1) + pow((1 / (s * C1)), -1), -1))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 8.0e-2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'R1', initialValue: 1000, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C1', initialValue: 0.1, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
        { name: 'C2', initialValue: 0.1, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{sC_{1} + \\frac{1}{R_{1} + \\frac{1}{sC_{2}}}}';


    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit

    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/debye_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/debye.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Dielectric relaxation in materials with single time constant can be described by this equivalent circuit, originally proposed by Debye [1].
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Physical meaning of parameters
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph component="div">
                <ul>
                    <li>
                        <Latex>{`$$C_1$$`}</Latex> corresponds to instantaneous polarisation due to deformation of electronic shells. It is related to dielectric constant of material
                        as <Latex>{`$$\\varepsilon = \\frac{C_1}{\\varepsilon_{0}}$$`}</Latex>.
                    </li>
                    <li>
                        <Latex>{`$$C_2$$`}</Latex> corresponds to orientation of dipoles in the electric field. It is related to the static dielectric constant of material
                        as <Latex>{`$$\\varepsilon_{s} = \\frac{C_2}{\\varepsilon_{0}} + \\varepsilon$$`}</Latex>.
                    </li>
                    <li>
                        <Latex>{`$$R_1$$`}</Latex> represents dielectric losses. It is related to the time-constant of relaxation as as <Latex>{`$$\\tau = R C_{2}$$`}</Latex>.
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};

export default Debye;
