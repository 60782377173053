import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SharedButton from './SharedButton'; // Adjust the import path if needed
import DraggablePaper from './DraggablePaper'; // Make sure to import your DraggablePaper component

const PythonFunctionBox = ({ pythonFunction, mismatch, toggleModal, openPythonFunction }) => {
  return (
    <div>
      <SharedButton
        sx={{
          mt: 1,
          '&.Mui-disabled': {
              backgroundColor: '#d3d3d3', // Light gray background
              color: '#808080' // Dark gray text
          }
          }}
        fullWidth
        onClick={() => toggleModal('pythonFunction', true)}
        component="button"
        disabled={mismatch} // Disable the button if there is a mismatch
      >
        Show Resulting Python Function
      </SharedButton>
      <Dialog
        open={openPythonFunction}
        onClose={() => toggleModal('pythonFunction', false)}
        PaperComponent={DraggablePaper}
        PaperProps={{ handle: '.modal-title' }}
        aria-labelledby="python-function-dialog"
        aria-describedby="python-function-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <div className="modal-title">
          <DialogTitle>Resulting Python Function</DialogTitle>
        </div>
        <DialogContent
          dividers
          sx={{
            overflow: 'auto', // Enables scrolling if content overflows
          }}
        >
          <Box
            style={{
              fontFamily: 'monospace',
              whiteSpace: 'pre',
              padding: '1rem',
              borderRadius: '4px',
              fontSize: '0.8125rem',
            }}
          >
            {!mismatch ? pythonFunction : ''}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal('pythonFunction', false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PythonFunctionBox;





/* A better approach would be to style the HighlightedExpressionContainer component
to resemble the TextField appearance, as previously suggested. To achieve
the desired effect of having the label float above the text when it is not empty,
you can use the InputLabel component from MUI in conjunction with the Box component. */