import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const FuelCellCPE = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {


    const circuitName = 'Simplified equivalent of fuel-cell electrode';

    const circuitExpression = '(Rser + pow(pow((1 / (pow(s, phi_dl) * Cdl)), -1) + pow((Rct + pow(pow(Ra, -1) + pow((1 / (pow(s, phi_a) * Ca)), -1), -1)), -1), -1))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 0.21, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct', initialValue: 0.02, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Ra', initialValue: 0.03, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl', initialValue: 0.15, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'phi_dl', initialValue: 0.7, lowerBound: 1e-1, upperBound: 1.01, smoothing: '0.0' },
        { name: 'Ca', initialValue: 10.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'phi_a', initialValue: 0.7, lowerBound: 1e-1, upperBound: 1.01, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{\\frac{1}{{s^{\\phi_{dl}} \\cdot C_{dl}}} + \\frac{1}{R_{ct} + \\frac{1}{\\frac{1}{R_{a}} + \\frac{1}{{s^{\\phi_{a}} \\cdot C_{a}}}}}}';


    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };


    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/fuelcellcpe_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/fuelcellcpe.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Impedance of a <strong>PEM-fuel cell electrode</strong> (particularly <Latex>{`$$H_{2}$$`}</Latex>-electrode) can be approximated by this circuit, as suggested by
	            Ciureanu and Wang in [1]. CPE elements are introduced because arcs in complex impedance plot are usually depressed.
                In order to describe full-cell, two such circuits should be combined in series. Interpretation of such data could be difficult if
                time-constants of anode and cathode impedance overlap. To solve this problem, "symmetric" measurement, where hydrogen is fed to both electrodes could be used.
                In later case single circuit can be used for 2-electrode cell.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance in the electrolyte pathway that is not compensated for by the reference electrode
                    </li>
                    <li>
                        <Latex>{`$$R_{ct}$$`}</Latex> is the charge-transfer resistance of electrochemical reaction
                    </li>
                    <li>
                        <Latex>{`$$C_{dl}$$`}</Latex> and <Latex>{`$$\\phi_{dl}$$`}</Latex> are the two parameters of the constant phase element.
                        In the limiting case where <Latex>{`$$\\phi_{dl}$$`}</Latex> approaches 1, C<sub>dl</sub> is
                        double-layer capacitance. <Latex>{`$$\\phi$$`}</Latex> is the factor changing properties of CPE from capacitive
                        (<Latex>{`$$\\phi = 1$$`}</Latex>) to resistive (<Latex>{`$$\\phi = 0$$`}</Latex>).
                    </li>
                    <li>
                        <Latex>{`$$R_{a}$$`}</Latex> is the resistance associated with activated adsorption on catalyst. In case of catalyst poisoning might become negative.
                    </li>
                    <li>
                        <Latex>{`$$C_{a}$$`}</Latex> and <Latex>{`$$\\phi_{a}$$`}</Latex> are the two parameters of the constant phase element.
                        In the limiting case where <Latex>{`$$\\phi_{a}$$`}</Latex> approaches 1, C<sub>a</sub> is
                        capacitance associated with activated adsorption on catalyst. In case of catalyst poisoning might become negative.
                        <Latex>{`$$\\phi$$`}</Latex> is the factor changing properties of CPE from capacitive
                        (<Latex>{`$$\\phi = 1$$`}</Latex>) to resistive (<Latex>{`$$\\phi = 0$$`}</Latex>).
                    </li>

                </ul>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] M. Ciureanu and Hong Wang, J. Electrochem. Soc., 146/11 (1999) 4031
                    </li>

                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};


export default FuelCellCPE;
