
// import React from 'react';
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
// import { styled } from '@mui/system';

// const StickyFooter = styled(Box)(({ theme }) => ({
//   position: 'fixed',
//   bottom: 0,
//   width: '100%',
//   p: 2,
//   mt: 'auto',
//   backgroundColor: theme.palette.primary.main,
//   color: 'white',
// }));

// const Footer = () => {
//   return (
//     <StickyFooter>
//       <Typography variant="body2" align="center">
//         Copyright © {new Date().getFullYear()} - All right reserved by FitmyEIS
//       </Typography>
//     </StickyFooter>
//   );
// };

// export default Footer;
import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';

const StickyFooter = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  p: 2,
  mt: 'auto',
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  zIndex: 1301, // make it the same as AppBar
}));

const Footer = () => {
  return (
    <StickyFooter>
      <Typography variant="body2" align="center">
        Copyright © {new Date().getFullYear()} - All right reserved by FitMyEIS
      </Typography>
    </StickyFooter>
  );
};

export default Footer;
