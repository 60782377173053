import React from 'react';
import { Typography } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <Typography variant="body1" color="text.secondary" component="div">
            <h4>Privacy Policy</h4>
            <p>
                By using FitMyEIS, you agree to the terms of this Privacy Policy.
            </p>

            <p>
                At FitMyEIS, your privacy is our priority, and we are committed to safeguarding it. FitMyEIS is a web-based impedance fitting application that processes data primarily on your local device. Most operations and computations are performed client-side, in your browser, and do not communicate with our servers.
            </p>

            <p>
                Only when you click the "Fit" button do we make a request to our servers. This action sends your data temporarily to our servers for the computation of results. Once the results have been computed, this data is promptly discarded. No permanent storage of your data takes place at any point.
            </p>

            <p>
                No personal data is requested or stored by us at any time. We solely handle the data you provide for the "Fit" operation, and it is not shared with any third parties. Connection logs maintained by us are strictly for internal performance monitoring and usage statistics.
            </p>

            <p>
                These logs contain:
            </p>

            <ol>
                <li>The IP address of the client making the request (anonymised).</li>
                <li>The timestamp of the request.</li>
                <li>The HTTP method and the specific endpoint or resource being requested.</li>
                <li>The HTTP status code indicating the result of the request.</li>
                <li>The size of the data sent in response.</li>
                <li>The "referer" - i.e., the URL from which the request was made.</li>
                <li>The User-Agent string that identifies the client's browser and operating system.</li>
            </ol>

            <p>
                While our servers are located in Europe, no data transfer to third parties occurs. If this ever changes, we will take necessary measures to ensure appropriate safeguards.
            </p>

            <p>
                We are dedicated to preserving your right to privacy and commit to transparency in all our data practices. This privacy notice is available orally upon request to ensure comprehension and to aid visually impaired users.
            </p>
        </Typography>
    );
};

export default PrivacyPolicy;
