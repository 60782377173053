
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { alpha } from '@mui/material/styles';
import { Button } from '@mui/material';


const StyledSharedButton = styled(Button)(({ theme }) => ({
  fontSize: '12px',
  color: '#fff', // Set the text color to white
  backgroundColor: theme.palette.primary.main, // Use the same background color as AddButton
  borderRadius: '4px',
  padding: '6px 12px',
  textDecoration: 'none',
  textAlign: 'center',
  display: 'inline-block',
  width: '100%',
  minWidth: '100px', // Set a minimum width for the buttons
  minHeight: '40px',
  marginBottom: '10px',
  transition: 'all 0.3s',
  boxShadow: theme.shadows[2],
  fontWeight: 'bold',
  textRendering: 'optimizeLegibility',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',

  '&:hover': {
    backgroundColor: theme.palette.primary.dark, // Use the same hover background color as AddButton
    color: '#fff', // Keep the text color white on hover
    boxShadow: theme.shadows[4],
  },

  '&:active': {
    backgroundColor: alpha(theme.palette.primary.dark, 0.5),
    boxShadow: theme.shadows[1],
  },
}));

const SharedButton = ({ height = '40px', icon: IconComponent, ...props }) => (
  <StyledSharedButton {...props} height={height} startIcon={IconComponent && <IconComponent />}>
    {props.children}
  </StyledSharedButton>
);

SharedButton.propTypes = {
  height: PropTypes.string,
  icon: PropTypes.elementType,
};

export default SharedButton;
