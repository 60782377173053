// ComposerButton.js
// ComposerButton.js
import { styled } from '@mui/system';
import SharedButton from './SharedButton';
import { alpha } from '@mui/material/styles';

const StyledComposerButton = styled(SharedButton)(({ theme }) => ({
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.1),
  width: '100%',
  '&:hover': {
    backgroundColor: alpha(theme.palette.success.dark, 0.2),
    color: theme.palette.success.dark,
  },
  '&:active': {
    backgroundColor: alpha(theme.palette.success.dark, 0.3),
  },
}));

const ComposerButton = (props) => <StyledComposerButton {...props} />;

export default ComposerButton;
