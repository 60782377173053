import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const SphericalInfinite = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Diffusion from a spherical electrode into infinite solution';

    const circuitExpression = '(Rd / (1 + sqrt(Rd * (s * 3 * Cd))))';

    const circuitVariables = [
        { name: 'Rd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 0.01, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },

    ];

    const LatexExpression = 'Z(s) = $$\\frac{Rd}{1 + \\sqrt{Rd \\cdot (s \\cdot 3 \\cdot Cd)}}$$';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                This case aligns closely with the impedance of a mercury drop in polarographic experiments.
                It may also serve as a critical component in the impedance of a composite electrochemical catalyst,
                where catalytic particles are spherical and relatively distant from each other, as seen in fuel cells.
                Another noteworthy case is the ultramicroelectrode, where the half-sphere approximation is suitable for handling diffusion impedance.
                Equations for spherical diffusion towards infinite solution, as derived in [1], are adapted here to express equations in terms of electrical parameters.
                <br/>
                This case can be depicted as an inhomogeneous infinite transmission line, in which the resistance decreases and distributed capacitance increases from left to right,
                proportional to the increase in the flow surface with distance from the electrode. Treating this transmission line as an approximation of a lumped network is impractical;
                formulating it directly as continuous differential equations is preferable. Therefore, no equivalent circuit is shown here.

            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/wspinf.png" alt="Nyquist" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Wspinf
            </Typography>



            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>

                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$\\frac{dE}{dc}, (volt~cm^{3}) = \\frac{F\\cdot n \\cdot r \\cdot S}{C_{d}}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$D (sec^{-1} cm^{3}) = \\frac{r^{2}}{C_{d} \\cdot R_{d}} $$`}</Latex>
                </Box>
                <div>
                    Where r is the radius of the spherical electrode, S is the geometric electrode area in <Latex>{`$$cm^{2}$$`}</Latex>, n is the number of electrons participating in the reaction,
                    and c is the volume concentration in <Latex>{`$$mol \\cdot cm^{3}$$`}</Latex>. Surface area is given separately (and not fixed as <Latex>{`$$4 \\pi r$$`}</Latex>) for the
                    cases where only half of the sphere is open to the electolyte. Such case is realized for example in an ultramicroelectrode, where <Latex>{`$$S = 2 \\pi r$$`}</Latex>.
                </div>
         </Typography>
         <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] T.Jacobsen and K.West, Electrochimica Acta, 40/2 (1995) 255
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default SphericalInfinite;
