
import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';

const ClickableText = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.text.primary,
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.text.secondary,
  },
}));

const RCElement = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
  const [navigating, setNavigating] = useState(false);

  const circuitName = 'RC Element';

  const circuitExpression = 'Rser + pow(pow(R1, -1) + pow((1 / (s * C1)), -1), -1)';

  const circuitVariables = [
    { name: 'Rser', initialValue: 0.5, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    { name: 'R1', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    { name: 'C1', initialValue: 1, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
  ];

  const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{\\frac{1}{R_1} + (s \\cdot C_1)}';

  const handleSelect = () => {
    setCircuitExpression(circuitExpression);
    setCircuitVariables(circuitVariables);
    setLoadedFormulaName(circuitName);
    setIsSettingsLoaded(true);
    closeModal(); // Close the circuit modal
  };

  const handleNavigate = (innerGroup, innerCircuit) => {
    setNavigating(true);
    selectInnerGroup(innerGroup);
    selectInnerCircuit(innerCircuit);
  };

  useEffect(() => {
    if (navigating) {
      closeModal();
    }
  }, [navigating, closeModal]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Typography variant="h5" color="text.primary" gutterBottom>
        {circuitName}
      </Typography>
      <Divider />
      <Typography variant="h6" color="text.primary" gutterBottom>
        Schematic Presentation
      </Typography>
      <img src="/circuit_images/rc_element_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

      <Typography variant="h6" color="text.primary" gutterBottom>
        Typical spectrum in complex presentation
      </Typography>
      <img src="/circuit_images/rc_element.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

      <Typography variant="h6" color="text.primary" gutterBottom>
        Impedance function
      </Typography>
      <Typography variant="body1" color="text.secondary">
        <Latex>{`$${LatexExpression}$`}</Latex>
      </Typography>

      <Typography variant="h6" color="text.primary" gutterBottom>
        Applications
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph>
        This circuit is used to describe the electrochemical layer at high frequencies where diffusion can be neglected.
      </Typography>

      <Typography variant="h6" color="text.primary" gutterBottom>
        Physical meaning of parameters
      </Typography>
      <Typography variant="body1" color="text.secondary" paragraph component="div">
        <ul>
          <li>
            <Latex>{`$$C_1$$`}</Latex> is the capacitance of the double layer built at the electrode/electrolyte interface.
          </li>
          <li>
            <Latex>{`$$R_1$$`}</Latex> is the charge transfer resistance (also called the <Latex>{`$$R_{ct}$$`}</Latex>). It can be converted
            into such kinetic parameters as the exchange current density using the equation below:
            <Box display="flex" justifyContent="center" my={2}>
              <Latex>{`$$i_0 = \\frac{R \\cdot T}{{n \\cdot F \\cdot R_{ct}}}$$`}</Latex>
            </Box>
            where <Latex>{`$$i_0$$`}</Latex> is the exchange current density,&nbsp;
            <Latex>{`$$R$$`}</Latex> is the gas constant, <Latex>{`$$T$$`}</Latex> is the temperature, <Latex>{`$$n$$`}</Latex> is the number of electrons
            transferred in the reaction, and <Latex>{`$$F$$`}</Latex> is the Faraday constant.
          </li>
        </ul>
      </Typography>

      <ClickableText variant="h6" color="text.primary" onClick={() => handleNavigate('Diffusion effects')}>
        Go to TwoRCElements
      </ClickableText>

      <Box paddingBottom={1} paddingTop={1}>
        <Button variant="contained" color="primary" onClick={handleSelect}>
          Select Circuit
        </Button>
      </Box>
    </Box>
  );
};

export default RCElement;
