import React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material/styles';
import SharedButton from './SharedButton';

const StyledResetButton = styled(SharedButton)(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: alpha(theme.palette.error.main, 0.1),

  '&:hover': {
    backgroundColor: alpha(theme.palette.error.dark, 0.2),
    color: theme.palette.error.dark,
  },

  '&:active': {
    backgroundColor: alpha(theme.palette.error.dark, 0.3),
  },
}));

const ResetButton = (props) => <StyledResetButton {...props} />;

export default ResetButton;
