
import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const ThreeRCElements = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal }) => {
    const circuitName = 'Three RC Elements in Series (Voigt model)';

    const circuitExpression = '(Rser + pow(pow(R1, -1) + pow((1 / (s * C1)), -1), -1) + pow(pow(R2, -1) + pow((1 / (s * C2)), -1), -1) + pow(pow(R3, -1) + pow((1 / (s * C3)), -1), -1))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 8.0e-2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'R1', initialValue: 3, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C1', initialValue: 0.001, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
        { name: 'R2', initialValue: 10, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C2', initialValue: 0.01, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
        { name: 'R3', initialValue: 25, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C3', initialValue: 0.1, lowerBound: 1e-15, upperBound: 10, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{\\frac{1}{R_1} + (s \\cdot C_1)} + \\frac{1}{\\frac{1}{R_2} + (s \\cdot C_2)} + \\frac{1}{\\frac{1}{R_2} + (s \\cdot C_2)}';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/three_rc_elements_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
            Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/three_rc_elements.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                This circuit model has been used for ceramic electrolyte modeling. The RC elements correspond to grain interiors, grain boundaries, and electrode.
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] H.H.Bauerle, Study of Solid Electrolyte Polarization by a Complex Admittance Method, J. Phys. Chem. Solids 30 (1969) 2657
                    </li>
                </ul>
            </Typography>
            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

    };


export default ThreeRCElements;
