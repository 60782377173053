import React, { useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, List, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DraggablePaper from './DraggablePaper';
import RCElement from '../simple_circuit_library/RCElement';
import TwoRCElements from '../simple_circuit_library/TwoRCElements';
import ThreeRCElements from '../simple_circuit_library/ThreeRCElements';
import Debye from '../simple_circuit_library/Debye';
import StrongAdsorption from '../simple_circuit_library/StrongAdsorption';
import TwoPhaseMaterial from '../simple_circuit_library/TwoPhaseMaterial';
import ElectrochemicalSensor from '../simple_circuit_library/ElectrochemicalSensor';
import Fricke from '../simple_circuit_library/Fricke';
// Diffusion Effects
import Randles from '../diffusion_effects_library/Randles';
import RandlesTransmissive from '../diffusion_effects_library/RandlesTransmissive';
import RandlesReflective from '../diffusion_effects_library/RandlesReflective';
import DiffSecReaction from '../diffusion_effects_library/DiffSecReaction';
import DiffGenBoundary from '../diffusion_effects_library/DiffGenBoundary';
import CylindricalInfWarburg from '../diffusion_effects_library/CylindricalInfWarburg';
import CylindricalRefFiniteWarburg from '../diffusion_effects_library/CylindricalRefFiniteWarburg';
import SphericalInfWarburg from '../diffusion_effects_library/SphericalInfWarburg';
import SphericalRefFiniteWarburg from '../diffusion_effects_library/SphericalRefFiniteWarburg';
import FullCell from '../diffusion_effects_library/FullCell';


// Porous Electrodes
import PorousAdsorption from '../porous_electrodes_library/PorousAdsorption';
import ActivatedAdsorptionCircuit from '../porous_electrodes_library/ActivatedAdsorptionCircuit';
import IonicElectronicConductor from '../porous_electrodes_library/IonicElectronicConductor';
import IntercalatingMaterial from '../porous_electrodes_library/IntercalatingMaterial';
import SphericalParticlesMaterial from '../porous_electrodes_library/SphericalParticlesMaterial';

// Empirical Models
import EARC from '../empirical_models_library/EARC';
import ZARC from '../empirical_models_library/ZARC';
import YARC from '../empirical_models_library/YARC';
import PolycrystallineSolid from '../empirical_models_library/PolycrystallineSolid';
import NUD from '../empirical_models_library/NUD';
import DavidsonCole from '../empirical_models_library/DavidsonCole';

// Batteries and Fuel Cells
import Cathode from '../batteries_and_fuel_cells_library/Cathode';
import Anode from '../batteries_and_fuel_cells_library/Anode';
import FullCellBattery from '../batteries_and_fuel_cells_library/FullCellBattery';
import SimplifiedFullCellBattery from '../batteries_and_fuel_cells_library/SimplifiedFullCellBattery';
import Composite from '../batteries_and_fuel_cells_library/Composite';
import Spherical from '../batteries_and_fuel_cells_library/Spherical';
import Liion from '../batteries_and_fuel_cells_library/Liion';
import FuelCell from '../batteries_and_fuel_cells_library/FuelCell';
import FuelCellCPE from '../batteries_and_fuel_cells_library/FuelCellCPE';
import FuelCellPorous from '../batteries_and_fuel_cells_library/FuelCellPorous';

// Corrosion Models
import ActiveDissolution from '../corrosion_models_library/ActiveDissolution';
import InsulatingLayer from '../corrosion_models_library/InsulatingLayer';
import FilmSolutionInterface from '../corrosion_models_library/FilmSolutionInterface';
import DentingCorrosion from '../corrosion_models_library/DentingCorrosion';
import DentingCorrosionAdsorption from '../corrosion_models_library/DentingCorrosionAdsorption';

// Distributed Elements
import InfiniteLengthWarburg from '../distributed_elements/InfiniteLengthWarburg';
import ReflectiveFiniteLengthWarburg from '../distributed_elements/ReflectiveFiniteLengthWarburg';
import TransmissiveFiniteLengthWarburg from '../distributed_elements/TransmissiveFiniteLengthWarburg';
import IntercalationImpedance from '../distributed_elements/IntercalationImpedance';
import GeneralBoundary from '../distributed_elements/GeneralBoundary';
import SphericalInfinite from '../distributed_elements/SphericalInfinite';
import SphericalCenter from '../distributed_elements/SphericalCenter';
import CylindricalInfinite from '../distributed_elements/CylindricalInfinite';
import CylindricalCenter from '../distributed_elements/CylindricalCenter';
import HomogeneousAdsorption from '../distributed_elements/HomogeneousAdsorption';
import ActivatedAdsorption from '../distributed_elements/ActivatedAdsorption';
import Fletcher from '../distributed_elements/Fletcher';
import Paasch from '../distributed_elements/Paasch';
import SphericalNoPhase from '../distributed_elements/SphericalNoPhase';
import SphericalPhase from '../distributed_elements/SphericalPhase';
import Interc from '../distributed_elements/Interc';
import SimplifiedInterc from '../distributed_elements/SimplifiedInterc';
import ConstantPhaseElement from '../distributed_elements/ConstantPhaseElement';
import ColeCole from '../distributed_elements/ColeCole';
import HavriliakNegami from '../distributed_elements/HavriliakNegami';
import DavCole from '../distributed_elements/DavCole';
import Gerischer from '../distributed_elements/Gerischer';






const CircuitLibrary = ({ openLibrary, setOpenLibrary, setExpression, setVariables, setIsSettingsLoaded, setLoadedFormulaName }) => {
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedCircuit, setSelectedCircuit] = useState(null);
    const [openCircuitModal, setOpenCircuitModal] = useState(false);
    const [openGroupModal, setOpenGroupModal] = useState(false);

    const handleSelectGroup = (group) => {
      setSelectedGroup(group);
      setOpenGroupModal(true);
    };

    const handleCloseGroupModal = () => {
      setOpenGroupModal(false);
      setSelectedGroup(null);
    };

    const handleSelectCircuit = (circuit) => {
      setSelectedCircuit(circuit);
      setOpenCircuitModal(true);
    };

    const handleCloseCircuitModal = () => {
      setOpenCircuitModal(false);
      setSelectedCircuit(null);
    };

    const closeAll = () => {
      handleCloseCircuitModal();
      handleCloseGroupModal();
      setOpenLibrary(false);
    };

    const circuitGroups = {
        'Simple Circuits': ['RC element', 'Two RC elements', 'Three RC elements', 'Debye model', 'Double-layer with strong adsorption', 'Two-phase material', 'Electrochemical sensor', 'Fricke model'],
        'Diffusion Effects': [
          'Randles circuit',
          'Transmissive finite Warburg',
          'Reflective finite Warburg',
          'Diffusion with secondary reaction',
          'Diffusion with general boundary',
          'Cylindrical infinite Warburg',
          'Cylindrical reflective finite Warburg',
          'Spherical infinite Warburg',
          'Spherical reflective finite Warburg',
          'Full cell'
        ],
        'Porous Electrodes': [
          'Double-layer at porous electrode',
          'Activated adsorption circuit',
          'Ionic and electronic conductor',
          'Intercalating material',
          'Material with spherical particles',

        ],

        'Empirical Models': [
          'EARC',
          'ZARC',
          'YARC',
          'Polycrystalline solid',
          'NUD',
          'Davidson and Cole',
        ],

        'Batteries and Fuel Cells': [
          'Cathode',
          'Anode',
          'Full cell battery',
          'Simplified full cell battery',
          'Composite',
          'Spherical',
          'Li-ion',
          'fuel-cell',
          'fuel-cell with CPE',
          'fuel-cell (porous)'
        ],

        'Corrosion Models': [
          'Active dissolution',
          'Insulating layer',
          'Film-solution interface',
          'Denting corrosion',
          'Denting corrosion/adsorption'
        ],

        'Distributed Elements': [
          'W',
          'Wo',
          'Ws',
          'Wphase',
          'Wgen',
          'Wspinf',
          'Wsplim',
          'Wcyinf',
          'Wcylim',
          'Homogeneous adsorption',
          'Activated adsorption',
          'Fletch',
          'Paasch',
          'Trsph',
          'Trphase',
          'Interc',
          'Transhf',
          'CPE',
          'Cole',
          'HavNeg',
          'DavCole',
          'G',
        ],

    };

    const circuitComponents = {
        'RC element': () => <RCElement setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Two RC elements': () => <TwoRCElements setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Three RC elements': () => <ThreeRCElements setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Debye model': () => <Debye setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Double-layer with strong adsorption': () => <StrongAdsorption setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Two-phase material': () => <TwoPhaseMaterial setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Electrochemical sensor': () => <ElectrochemicalSensor setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Fricke model': () => <Fricke setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,

        // Diffusion Effects
        'Randles circuit': () => <Randles setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Transmissive finite Warburg': () => <RandlesTransmissive setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Reflective finite Warburg': () => <RandlesReflective setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Diffusion with secondary reaction': () => <DiffSecReaction setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Diffusion with general boundary': () => <DiffGenBoundary setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Cylindrical infinite Warburg': () => <CylindricalInfWarburg setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Cylindrical reflective finite Warburg': () => <CylindricalRefFiniteWarburg setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Spherical infinite Warburg': () => <SphericalInfWarburg setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Spherical reflective finite Warburg': () => <SphericalRefFiniteWarburg setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Full cell': () => <FullCell setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,


        // Porous Electrodes
        'Double-layer at porous electrode': () => <PorousAdsorption setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Activated adsorption circuit': () => <ActivatedAdsorptionCircuit setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Ionic and electronic conductor': () => <IonicElectronicConductor setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Intercalating material': () => <IntercalatingMaterial setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Material with spherical particles': () => <SphericalParticlesMaterial setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,


        // Empirical Models
        'EARC': () => <EARC setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'ZARC': () => <ZARC setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'YARC': () => <YARC setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Polycrystalline solid': () => <PolycrystallineSolid setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'NUD': () => <NUD setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Davidson and Cole': () => <DavidsonCole setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,

        // Batteries and Fuel Cells
        'Cathode': () => <Cathode setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Anode': () => <Anode setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Full cell battery': () => <FullCellBattery setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Simplified full cell battery': () => <SimplifiedFullCellBattery setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Composite': () => <Composite setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Spherical': () => <Spherical setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Li-ion': () => <Liion setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'fuel-cell': () => <FuelCell setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'fuel-cell with CPE': () => <FuelCellCPE setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'fuel-cell (porous)': () => <FuelCellPorous setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,

        // Corrosion Models
        'Active dissolution': () => <ActiveDissolution setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Insulating layer': () => <InsulatingLayer setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Film-solution interface': () => <FilmSolutionInterface setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Denting corrosion': () => <DentingCorrosion setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Denting corrosion/adsorption': () => <DentingCorrosionAdsorption setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,

        // Distributed Elements
        'W': () => <InfiniteLengthWarburg setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Wo': () => <ReflectiveFiniteLengthWarburg setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Ws': () => <TransmissiveFiniteLengthWarburg setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Wphase': () => <IntercalationImpedance setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Wgen': () => <GeneralBoundary setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Wspinf': () => <SphericalInfinite setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Wsplim': () => <SphericalCenter setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Wcyinf': () => <CylindricalInfinite setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Wcylim': () => <CylindricalCenter setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Homogeneous adsorption': () => <HomogeneousAdsorption setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Activated adsorption': () => <ActivatedAdsorption setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Fletch': () => <Fletcher setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Paasch': () => <Paasch setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Trsph': () => <SphericalNoPhase setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Trphase': () => <SphericalPhase setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Interc': () => <Interc setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'Transhf': () => <SimplifiedInterc setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
        'CPE': () => <ConstantPhaseElement setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll}/>,
        'Cole': () => <ColeCole setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll}/>,
        'HavNeg': () => <HavriliakNegami setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll}/>,
        'DavCole': () => <DavCole setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll}/>,
        'G': () => <Gerischer setCircuitExpression={setExpression} setCircuitVariables={setVariables} setLoadedFormulaName={setLoadedFormulaName} setIsSettingsLoaded={setIsSettingsLoaded} closeModal={closeAll} selectInnerGroup={handleSelectGroup} selectInnerCircuit={handleSelectCircuit}/>,
    };


    const circuitDescriptions = {
        // Simple Circuits
        'RC element': 'Simple model of an electrochemical double layer.',
        'Two RC elements': 'Impedance of a 2 phase ceramic.',
        'Three RC elements': 'Impedance of a solid electrolyte.',
        'Debye model': 'Impedance of dielectric solid.',
        'Double-layer with strong adsorption': 'Electrode/electrolyte interface in the presence of strong adsorbtion.',
        'Two-phase material': 'Easy path model by Schouler.',
        'Electrochemical sensor': '',
        'Fricke model': 'Effective medium model of dispersed dielectric.',
        // Diffusion Effects
        'Randles circuit': 'Corresponds to a flat electrode exposed to quasi-infinite solution.',
        'Transmissive finite Warburg': 'Flat electrode covered by film with restricted diffusion.',
        'Reflective finite Warburg': 'Flat electrode placed in a thin cell',
        'Diffusion with secondary reaction': 'Diffusing specie undergoes a secondary reaction at the electrode surface.',
        'Diffusion with general boundary': 'Diffusion through a membrane into a medium with restricted mass-transport.',
        'Cylindrical infinite Warburg': 'Cylindrical electrode exposed to a quasi-infinite solution.',
        'Cylindrical reflective finite Warburg': 'Intercalation into cylindrical fiber.',
        'Spherical infinite Warburg': 'Microelectrode in quasi-infinite solution.',
        'Spherical reflective finite Warburg': 'Intercalation into spherical particle.',
        'Full cell': 'Full-cell composed from two Randles circuits.',

        // Porous Electrodes
        'Double-layer at porous electrode': 'Homogenous porous electrode.',
        'Activated adsorption circuit': 'Porous electrode in presence of activated adsorption.',
        'Ionic and electronic conductor': 'Considering ion conduction in pores and electron conduction in particles',
        'Intercalating material': 'Meso-porous layer with diffusion inside flat particles.',
        'Material with spherical particles': 'Meso-porous layer wit diffusion inside spherical particles.',

        // Empirical Models
        'EARC': 'Cole-Cole treatment of dielectric systems.',
        'ZARC': 'Distribution of relaxation times in solids .',
        'YARC': 'Non-ideal capacitor given as CPE',
        'Polycrystalline solid': 'Anomalous frequency dispersion.',
        'NUD': 'Randles circuit with non-uniform diffusion .',
        'Davidson and Cole': 'Dielectric properties of orgainic solvents.',

        // Batteries and Fuel Cells
        'Cathode': 'Oxide-type battery cathode.',
        'Anode': 'Free metal battery anode.',
        'Full cell battery': 'Battery full-cell (Lead-Acid, NiCd, NiMH) .',
        'Simplified full cell battery': 'Simplified battery full-cell.',
        'Composite': 'Composite intercalation electrode.',
        'Spherical': 'Intercalation electrode with spherical particles.',
        'Li-ion': 'Li-ion intercalation electrode.',
        'fuel-cell': 'Simplified fuel-cell electrode.',
        'fuel-cell with CPE': 'Fuel-cell electrode with CPE.',
        'fuel-cell (porous)': 'Fuel-cell electrode with porosity considered.',

        // Corrosion Models
        'Active dissolution': 'No passivation layer or porosity',
        'Insulating layer': 'Ion diffusion through insulating layer',
        'Film-solution interface': 'Armstrong-Edmondson model with possible inductive effect',
        'Denting corrosion': 'Porous corroding surface',
        'Denting corrosion/adsorption': 'Porous corroding surface with hydrogen adsorbtion',


        // Distributed Elements
        'W': 'Warburg impedance (Infinite length diffusion).',
        'Wo': 'Finite length diffusion with reflective boundary (Reflective finite Warburg).',
        'Ws': 'Finite length diffusion with transmissive boundary (Transmissive finite Warburg).',
        'Wphase': 'Finite length diffusion with secondary reaction.',
        'Wgen': 'Finite length diffusion with general boundary.',
        'Wspinf': 'Diffusion from a spherical electrode into infinite solution.',
        'Wsplim': 'Diffusion towards the center of a sphere.',
        'Wcyinf': 'Cylindrical diffusion towards infinite volume.',
        'Wcylim': 'Diffusion towards the center of a cylindrical rod.',
        'Homogeneous adsorption': 'Adsorption at a homogeneous porous surface (open-circuit terminated transmission line).',
        'Activated adsorption': 'Activated adsorption at a homogeneous porous surface.',
        'Fletch': 'Activated adsorption at a homogeneous porous surface with both ionic end electronic pathways considered (Fletcher).',
        'Paasch': 'Electrochemical reaction at a porous surface with subsequent finite-length diffusion (Paasch-Micka impedance).',
        'Trsph': 'Electrochemical reaction at a porous material composed of spherical particles.',
        'Trphase': 'Electrochemical reaction at a porous surface with subsequent finite length diffusion with secondary reaction (Intercalation electrode impedance).',
        'Interc': 'Li-ion intercalation electrode impedance',
        'Transhf': 'Simplified case of "Interc" valid for spectra restricted to high-frequencies.',
        'CPE': 'Constant phase element (CPE).',
        'Cole': 'Cole-Cole (ZARC) element - CPE in parallel with a resistor.',
        'HavNeg': 'Havriliak-Negami element.',
        'DavCole': 'Generalized Davidson-Cole response.',
        'G': 'Gerischer element.',
        // Add more descriptions as necessary
    };

    const circuitImageURLs = {
        // Simple Circuits
        'RC element': '/circuit_images/rc_element_icon.png',
        'Two RC elements': '/circuit_images/two_rc_elements_icon.png',
        'Three RC elements': '/circuit_images/three_rc_elements_icon.png',
        'Debye model': '/circuit_images/debye_icon.png',
        'Double-layer with strong adsorption': '/circuit_images/strong_adsorption_icon.png',
        'Two-phase material': '/circuit_images/two_phase_material_icon.png',
        'Electrochemical sensor': '/circuit_images/electrochemical_sensor_icon.png',
        'Fricke model': '/circuit_images/fricke_icon.png',
        // Diffusion Effects
        'Randles circuit': '/circuit_images/randles_icon.png',
        'Transmissive finite Warburg': '/circuit_images/randlesws_icon.png',
        'Reflective finite Warburg': '/circuit_images/randleswo_icon.png',
        'Diffusion with secondary reaction': '/circuit_images/diffsecreaction_icon.png',
        'Diffusion with general boundary': '/circuit_images/diffgenboundary_icon.png',
        'Cylindrical infinite Warburg': '/circuit_images/cylindricalinfwarburg_icon.png',
        'Cylindrical reflective finite Warburg': '/circuit_images/cylindricalreffinitewarburg_icon.png',
        'Spherical infinite Warburg': '/circuit_images/sphericalinfwarburg_icon.png',
        'Spherical reflective finite Warburg': '/circuit_images/sphericalreffinitewarburg_icon.png',
        'Full cell': '/circuit_images/fullcell_icon.png',

        // Porous Electrodes
        'Double-layer at porous electrode': '/circuit_images/porousadsorption_icon.png',
        'Activated adsorption circuit': '/circuit_images/activatedadsorptioncircuit_icon.png',
        'Ionic and electronic conductor': '/circuit_images/ionicelectronicadsorption_icon.png',
        'Intercalating material': '/circuit_images/intercalatingmaterial_icon.png',
        'Material with spherical particles': '/circuit_images/sphericalparticlesmaterial_icon.png',

        // Empirical Models
        'EARC': '/circuit_images/earc_icon.png',
        'ZARC': '/circuit_images/zarc_icon.png',
        'YARC': '/circuit_images/yarc_icon.png',
        'Polycrystalline solid': '/circuit_images/polycrystallinesolid_icon.png',
        'NUD': '/circuit_images/nud_icon.png',
        'Davidson and Cole': '/circuit_images/davcolecircuit_icon.png',

        // Batteries and Fuel Cells
        'Cathode': '/circuit_images/randleswo_icon.png',
        'Anode': '/circuit_images/randlesws_icon.png',
        'Full cell battery': '/circuit_images/fullcellbattery_icon.png',
        'Simplified full cell battery': '/circuit_images/simplifiedfullcellbattery_icon.png',
        'Composite': '/circuit_images/composite_icon.png',
        'Spherical': '/circuit_images/spherical_icon.png',
        'Li-ion': '/circuit_images/liion_icon.png',
        'fuel-cell': '/circuit_images/fuelcell_icon.png',
        'fuel-cell with CPE': '/circuit_images/fuelcellcpe_icon.png',
        'fuel-cell (porous)': '/circuit_images/fuelcellporous_icon.png',

        // Corrosion Models
        'Active dissolution': '/circuit_images/activedissolution_icon.png',
        'Insulating layer': '/circuit_images/insulatinglayer_icon.png',
        'Film-solution interface': '/circuit_images/filmsolutioninterface_icon.png',
        'Denting corrosion': '/circuit_images/dentingcorrosion_icon.png',
        'Denting corrosion/adsorption': '/circuit_images/dentingcorrosionadsorption_icon.png',


        // Distributed Elements
        'W': '/circuit_images/warburg_impedance_icon.png',
        'Wo': '/circuit_images/warburg_open_icon.png',
        'Ws': '/circuit_images/warburg_short_icon.png',
        'Wphase': '/circuit_images/wphase_icon.png',
        'Wgen': '/circuit_images/wgen_icon.png',
        'Wspinf': '/circuit_images/wspinf_icon.png',
        'Wsplim': '/circuit_images/wsplim_icon.png',
        'Wcyinf': '/circuit_images/wcyinf_icon.png',
        'Wcylim': '/circuit_images/wcylim_icon.png',
        'Homogeneous adsorption': '/circuit_images/warburg_open_icon.png',
        'Activated adsorption': '/circuit_images/activated_adsorption_icon.png',
        'Fletch': '/circuit_images/fletcher_icon.png',
        'Paasch': '/circuit_images/paasch_icon.png',
        'Trsph': '/circuit_images/trsph_icon.png',
        'Trphase': '/circuit_images/trphase_icon.png',
        'Interc': '/circuit_images/interc_icon.png',
        'Transhf': '/circuit_images/transhf_icon.png',
        'CPE': '/circuit_images/cpe_icon.png',
        'Cole': '/circuit_images/cole_cole_icon.png',
        'HavNeg': '/circuit_images/hav_neg_icon.png',
        'DavCole': '/circuit_images/dav_cole_icon.png',
        'G': '/circuit_images/warburg_short_icon.png',
        // Add more images as necessary
    };


  return (
    <Box>
      <Dialog
        open={openLibrary}
        onClose={() => setOpenLibrary(false)}
        PaperComponent={DraggablePaper}
        PaperProps={{ handle: '.modal-title' }}
      >
        <div className="modal-title">
          <DialogTitle>Select Circuit Group</DialogTitle>
        </div>
        <DialogContent>
        <Typography variant="body1" paragraph component="div">
            Welcome to the Circuit Library! To help you find the circuit which best suits your needs, follow these steps:
            <ol>
                <li>Browse through the circuit groups by clicking on any group name.</li>
                <li>Once you've selected a group, you'll be shown a selection of circuits within that group.</li>
                <li>Click on a circuit to view its detailed description, the impedance function, its applications, and related parameters.</li>
                <li>When you've decided which circuit to use, click on the "Select Circuit" button to load the chosen circuit into your project and close the library viewer.</li>
            </ol>
            Take your time to explore and select the best circuit for your needs.
            </Typography>

            <List>
            {Object.keys(circuitGroups).map((group) => (
               <ListItem
               onClick={() => handleSelectGroup(group)}
               key={group}
               sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f0f0f0' } }}
             >
               <ListItemText primary={group} />
               <ListItemIcon>
                 <ArrowForwardIosIcon />
               </ListItemIcon>
             </ListItem>
            ))}
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenLibrary(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {selectedGroup && (
        <Dialog
            open={openGroupModal}
            onClose={handleCloseGroupModal}
            PaperComponent={DraggablePaper}
            PaperProps={{ handle: '.modal-title' }}
        >
            <div className="modal-title">
            <DialogTitle>{selectedGroup}</DialogTitle>
            </div>
            <DialogContent>
            <List>
                {circuitGroups[selectedGroup].map((circuit) => (
                <ListItem
                onClick={() => handleSelectCircuit(circuit)}
                key={circuit}
                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f0f0f0' } }}
              >
                <ListItemText
                  primary={circuit}
                  secondary={circuitDescriptions[circuit]}
                />
                <ListItemIcon>
                  <img src={circuitImageURLs[circuit]} alt={circuit} style={{width: "50px", height: "50px"}}/>
                </ListItemIcon>
              </ListItem>
                ))}
            </List>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseGroupModal} sx={{ marginRight: 'auto' }}>Back</Button>
            </DialogActions>
        </Dialog>
        )}


      {selectedCircuit && (
        <Dialog
          open={openCircuitModal}
          onClose={handleCloseCircuitModal}
          PaperComponent={DraggablePaper}
          PaperProps={{ handle: '.modal-title' }}
        >
          <div className="modal-title">
            <DialogTitle></DialogTitle>
          </div>
          <DialogContent  > {/* Add these styles */}
            {circuitComponents[selectedCircuit]()}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCircuitModal} sx={{ marginRight: 'auto' }}>Back</Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default CircuitLibrary;
