import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const ZARC = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = 'Transmissive dielectric system (ZARC)';

    const circuitExpression = '(Rser + pow(pow(R0, -1) + pow((1 / (pow(s, phi) * C)), -1), -1))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 8.0e-2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'C', initialValue: 0.1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'phi', initialValue: 0.7, lowerBound: 1e-1, upperBound: 1.01, smoothing: '0.0' },
        { name: 'R0', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{\\text{ser}} + \\frac{1}{\\frac{1}{R_{0}} + s^{\\phi}C}';



    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                Detailed description of distributed element CPE is given&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'CPE')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    here
                </span>,&nbsp;

            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/zarc_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/zarc.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Impedance of a <strong>dielectric material which appears as a depressed semicircle in <Latex>{`$$Z^{\\prime}, Z^{\\prime\\prime}$$`}</Latex>-presentation</strong>. Such semicircles are
                almost always observed instead of ideal semicircles in case of ion-conduction through thin films of weak ionic conductors such as passivating layers on metals.
                <br/>
                In most cases non-ideal semicircles can be analysed in terms of physically-relevant models explaining the "depression" for example in terms of&nbsp;
                <span onClick={() => handleNavigate('Porous Electrodes', '')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    porous electrodes
                </span>&nbsp;or&nbsp;
                <span onClick={() => handleNavigate('Diffusion Effects', '')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    solid state diffusion
                </span>&nbsp;characteristics. If no physically relevant model is available and some other aspects of impedance spectrum are of interest,
                CPE can be used to represent a non-ideal capacitor.
                <br/>
                This circuit is not physically realizable for all frequencies and can therefore describe experimental data only in narrow frequency range.
                Nevertheless it is widely used to analyse impedance spectra. See&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'CPE')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    here
                </span>&nbsp;for details on CPE-distributed element.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance in the electrolyte pathway that is not compensated for by the reference electrode
                    </li>
                    <li>
                        <Latex>{`$$R0$$`}</Latex> corresponds to ionic resistance of the layer
                    </li>

                    <li>
                        <Latex>{`$$\\text{CPE}$$`}</Latex> represents frequency-dependent capacitor. For cases when <Latex>{`$$\\phi=1$$`}</Latex>, it simplifies to an ideal capacitor
                        which related to the dielectric constant of the material as:
                        <Box display="flex" justifyContent="center" my={2}>
                        <Latex>{`$$\\varepsilon = C\\varepsilon_{0}$$`}</Latex>
                        </Box>
                    </li>
                    <li>
                        Other parameters have their usual meanings as given&nbsp;
                        <span onClick={() => handleNavigate('Distributed Elements', 'CPE')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            here
                        </span>.
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};


export default ZARC;
