import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const GeneralBoundary = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Finite length diffusion with general boundary';

    const circuitExpression = '(sqrt(Rd / (s * Cd)) * ((1 + ((((1 / (s * Ct + pow(Rt, -1))) / sqrt(Rd / (s * Cd))) - 1) / (((1 / (s * Ct + pow(Rt, -1))) / sqrt(Rd / (s * Cd))) + 1))  * exp(-2 * (sqrt(Rd * (s * Cd)))))/((1 - ((((1 / (s * Ct + pow(Rt, -1))) / sqrt(Rd / (s * Cd))) - 1) / (((1 / (s * Ct + pow(Rt, -1))) / sqrt(Rd / (s * Cd))) + 1))  * exp(-2 * (sqrt(Rd * (s * Cd))))))))';

    const circuitVariables = [
        { name: 'Rd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rt', initialValue: 5.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Ct', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = \\sqrt{\\frac{Rd}{s \\cdot Cd}} \\cdot \\frac{1 + \\left(\\frac{\\frac{1}{s \\cdot Ct + \\frac{1}{Rt}}}{\\sqrt{\\frac{Rd}{s \\cdot Cd}}} - 1\\right) / \\left(\\frac{\\frac{1}{s \\cdot Ct + \\frac{1}{Rt}}}{\\sqrt{\\frac{Rd}{s \\cdot Cd}}} + 1\\right)  \\cdot \\exp\\left(-2 \\cdot \\sqrt{Rd \\cdot (s \\cdot Cd)}\\right)}{1 - \\left(\\frac{\\frac{1}{s \\cdot Ct + \\frac{1}{Rt}}}{\\sqrt{\\frac{Rd}{s \\cdot Cd}}} - 1\\right) / \\left(\\frac{\\frac{1}{s \\cdot Ct + \\frac{1}{Rt}}}{\\sqrt{\\frac{Rd}{s \\cdot Cd}}} + 1\\right)  \\cdot \\exp\\left(-2 \\cdot \\sqrt{Rd \\cdot (s \\cdot Cd)}\\right)}';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                Mass transport through a membrane cannot always be described by a short-circuit terminated transmission line. This treatment assumes that after the membrane,
                the supply of diffusion species is infinitely fast. That could be correct in the case of a solid-state membrane with low diffusion coefficient
                and concentrated electrolyte on the 'conductive' side, but not correct in the case of nafion type membrane with high diffusion coefficient
                making the impedance of the membrane comparable with electrolyte impedance. At the other hand, reflective diffusion cases can sometimes have 'leaking' current
                due to slow but noticaeable reaction at the boundary of the active layer. In such cases both membrane and boundary impedances have to be explicitly considered [1].
                Such cases correspond to a transmission line terminated with an arbitrary impedance such as a resistor/capacitor couple


            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/wgen_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/wgen.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Breakdown is as follows
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                <div>
                    Impedance function in terms of electric parameters
                </div>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z(s) = Z_{0} \\cdot \\frac{1 + r_{t} \\cdot \\exp^{-2 \\gamma d}}{1 - r_{t} \\cdot \\exp ^{-2 \\gamma d}}$$`}</Latex>
                </Box>
                <br/>
                    here the termination reflection factor <Latex>{`$$r_{t}$$`}</Latex> is defined as:
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$r_{t} = \\frac{\\frac{Z_{t}}{Z_{0}}-1}{\\frac{Z_{t}}{Z_{0}} + 1}$$`}</Latex>
                </Box>
                <br/>
                    parallel RC couple gives the termination impedance:
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$Z_{t} = \\frac{1}{\\frac{1}{R_{t}} + s C_{t}}$$`}</Latex>
                </Box>
                <br/>
                    for the case of diffusion:
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$Z_{0} = \\sqrt{\\frac{R_{d}}{c C_{d}}}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$\\gamma = \\sqrt{Rd \\cdot sC_{d}}$$`}</Latex>
                </Box>



            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>
            <Typography variant="body1" color="text.secondary">
                Wgen
            </Typography>



            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{t} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{t} (F)$$`}</Latex>
                    </li>
                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$\\frac{dE}{dc}, (volt~cm^{3}) = \\frac{F\\cdot n \\cdot d \\cdot S}{C_{d}}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$D (sec^{-1} cm^{3}) = \\frac{d^{2}}{C_{d} \\cdot R_{d}} $$`}</Latex>
                </Box>
                <div>
                    Where d is the thickness of the diffusion layer (length of the transmission line), S is the geometric electrode area in <Latex>{`$$cm^{2}$$`}</Latex>, n is the number of electrons participating in the reaction, and c is the volume concentration in <Latex>{`$$mol \\cdot cm^{3}$$`}</Latex>.
                </div>
                <br />
                    <div>Here, <Latex>{`$$R_{t}$$`}</Latex> and <Latex>{`$$C_{t}$$`}</Latex> can be interpreted depending on the process, for example as activated adsorption resistance and adsorption capacitanc at the membrance
                    electrolyte boundary. In this case:</div>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$C_{a}, (F/cm^{2}) = \\frac{C_{t}}{S}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$R_{a}, (\\Omega~cm^{2}) = \\frac{R_{t}}{S}$$`}</Latex>
                </Box>
         </Typography>
         <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] Franceschetti, Macdonald and Buck, J.Electrochem.Soc. 138 (1991) 1368
                    </li>
                </ul>
            </Typography>


            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default GeneralBoundary;
