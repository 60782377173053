import React, { useRef, useState, useEffect } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import SharedButton from "./SharedButton";
import { CSVLink } from "react-csv";

const FitDataCSVSaver = ({
    exptData,
    fitData,
    wrss,
    wrms,
    aic,
    loadedFileNames,
    isDisabled
    }) => {
    const [inputVisible, setInputVisible] = useState(false);
    const [fileName, setFileName] = useState("fitmyeis");
    const [isTimedOut, setIsTimedOut] = useState(false);

    const fileNameRef = useRef(fileName);
    const downloadExptDataCSVRef = useRef(null);
    const downloadFitDataCSVRef = useRef(null);
    const downloadMetricsCSVRef = useRef(null);

    fileNameRef.current = fileName;

    useEffect(() => {
        if (inputVisible) {
        const timer = setTimeout(() => {
            setInputVisible(false);
            setFileName(fileNameRef.current);
            setIsTimedOut(true);
        }, 10000); // set timeout for 10 seconds

        return () => clearTimeout(timer);
        }
    }, [inputVisible]);

    useEffect(() => {
        if (isTimedOut) {
        const timer = setTimeout(() => {
            setIsTimedOut(false);
        }, 2000); // show 'Timed out' message for 2 seconds

        return () => clearTimeout(timer);
        }
    }, [isTimedOut]);

    const handleSaveCSVData = () => {
        if (!inputVisible) {
        setInputVisible(true);
        } else {
        setInputVisible(false);
        downloadExptDataCSVRef.current.link.click();
        downloadFitDataCSVRef.current.link.click();
        downloadMetricsCSVRef.current.link.click();
        }
    };

    const convertFitDataToCSV = (fitData) => {
        if (!fitData) return [];
        const headers = ['filename', 'freq (Hz)', 'Zreal (Ohms)', 'Zimag (Ohms)', 'Yreal (S)', 'Yimag (S)', 'magnitude (Ohms)', 'phase (degrees)'];
        const csvData = fitData.flatMap((dataset, index) =>
        dataset.map(dataPoint => [
            loadedFileNames[index],
            dataPoint.freq,
            dataPoint.realImpedance,
            dataPoint.imagImpedance,
            dataPoint.realAdmittance,
            dataPoint.imagAdmittance,
            dataPoint.magnitude,
            dataPoint.phase
        ])
        );
        return [headers, ...csvData];
    };

    const fitDataCSV = convertFitDataToCSV(fitData);

    const convertExptDataToCSV = (exptData) => {
        if (!exptData) return [];
        const headers = ['filename', 'freq (Hz)', 'Zreal (Ohms)', 'Zimag (Ohms)', 'Yreal (S)', 'Yimag (S)', 'magnitude (Ohms)', 'phase (degrees)'];
        const csvData = exptData.flatMap((dataset, index) =>
        dataset.map(dataPoint => [
            loadedFileNames[index],
            dataPoint.freq,
            dataPoint.realImpedance,
            dataPoint.imagImpedance,
            dataPoint.realAdmittance,
            dataPoint.imagAdmittance,
            dataPoint.magnitude,
            dataPoint.phase
        ])
        );
        return [headers, ...csvData];
    };

    const exptDataCSV = convertExptDataToCSV(exptData);

        const metricsData = [
        ['Metrics', 'Value'],
        ['wrss', wrss],
        ['wrms', wrms],
        ['aic', aic]
    ];


  return (
    <Grid item xs={12} sm={6}>
      <Grid container direction="column" alignItems="flex-start">
        {inputVisible && (
          <TextField
            value={fileName}
            onChange={(e) => setFileName(e.target.value)}
            label="File name"
            sx={{
              '& .MuiInputBase-input': {
                height: '30px',
                padding: '0px 10px',
                fontSize: '16px',
              }
            }}
          />
        )}
        <SharedButton
        sx={{
            mt: 1,
            width: '200px',
            '&.Mui-disabled': {
                backgroundColor: '#d3d3d3', // Light gray background
                color: '#808080' // Dark gray text
            }
            }}
          onClick={handleSaveCSVData}
          color="primary"
          component="button"
          disabled={!exptData || !fitData || isDisabled}
        >
          {inputVisible ? "Confirm Save" : "Save Fit Data (CSV)"}
        </SharedButton>
        {isTimedOut && (
          <Typography variant="body1" color="error">
            Timed out, please try again.
          </Typography>
        )}
        <CSVLink
          data={exptDataCSV}
          filename={`${fileName}_exptdata.csv`}
          style={{ display: "none" }}
          ref={downloadExptDataCSVRef}
        />
        <CSVLink
          data={fitDataCSV}
          filename={`${fileName}_fitdata.csv`}
          style={{ display: "none" }}
          ref={downloadFitDataCSVRef}
        />
        <CSVLink
          data={metricsData}
          filename={`${fileName}_metrics.csv`}
          style={{ display: "none" }}
          ref={downloadMetricsCSVRef}
        />
      </Grid>
    </Grid>
  );
};

export default FitDataCSVSaver;
