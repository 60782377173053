import React, { useRef, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import * as d3 from 'd3';
import './VisualCircuit.css'

const VisualCircuit = ({ circuitTree }) => {
  const containerRef = useRef(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!circuitTree) return;

    const drawCircuit = (parentSvg, node) => {
      if (!node) return;

      const elementWidth = 80;
      const elementHeight = 30;
      const xSpacing = 20;
      const ySpacing = 20;

      if (node.data.type === 'ELEMENT') {
        const rectWidth = elementWidth;
        const rectHeight = elementHeight;

        parentSvg
          .append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', rectWidth)
          .attr('height', rectHeight)
          .attr('stroke', 'black')
          .attr('fill', 'white');

        parentSvg
          .append('text')
          .attr('x', rectWidth / 2)
          .attr('y', rectHeight / 2)
          .attr('text-anchor', 'middle')
          .attr('dominant-baseline', 'central')
          .text(`${node.data.value}_${node.data.index}`);
      } else if (node.children) {
        const childrenSvgs = node.children.map((child) => {
          const childSvg = parentSvg.append('g');
          drawCircuit(childSvg, child);
          return childSvg;
        });

        if (node.data.type === 'SERIES') {
          let xOffset = 0;
          let maxHeight = 0;
          childrenSvgs.forEach((childSvg, i) => {
            const bbox = childSvg.node().getBBox();
            maxHeight = Math.max(maxHeight, bbox.height);
          });

          childrenSvgs.forEach((childSvg, i) => {
            const bbox = childSvg.node().getBBox();
            const centerY = maxHeight / 2;
            childSvg.attr('transform', `translate(${xOffset}, ${centerY - bbox.height / 2})`);
            xOffset += bbox.width + xSpacing;
          });
        } else if (node.data.type === 'PARALLEL') {
          let maxWidth = 0;
          childrenSvgs.forEach((childSvg, i) => {
            const bbox = childSvg.node().getBBox();
            maxWidth = Math.max(maxWidth, bbox.width);
          });

          let yOffset = 0;
          childrenSvgs.forEach((childSvg, i) => {
            const bbox = childSvg.node().getBBox();
            const centerX = maxWidth / 2;
            childSvg.attr('transform', `translate(${centerX - bbox.width / 2}, ${yOffset})`);
            yOffset += bbox.height + ySpacing;
          });
        }
      }
    };



    try {
      const container = d3.select(containerRef.current);
      container.selectAll('*').remove();

      const svg = container.append('svg');

      const circuitGroup = svg.append('g');

      drawCircuit(circuitGroup, d3.hierarchy(circuitTree));

      const margin = 10;

      const bbox = circuitGroup.node().getBBox();
      svg
        .attr('width', bbox.width + 2 * margin)
        .attr('height', bbox.height + 2 * margin)
        .attr(
          'viewBox',
          `${bbox.x - margin} ${bbox.y - margin} ${bbox.width + 2 * margin} ${bbox.height + 2 * margin}`
        );
    } catch (err) {
      setError(err.message);
      // Display the error message to the user if needed, e.g., using Typography or Snackbar component
    }
  }, [circuitTree]);
  if (!circuitTree) {
    return null;
  }

  return (
    <div>
      <div className="VisualCircuitContainer" ref={containerRef}></div>
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
    </div>
  );
};

export default VisualCircuit;