import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const SphericalPhase = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);


    const circuitName = 'Electrochemical reaction at a porous surface with subsequent finite length diffusion with secondary reaction (Intercalation electrode impedance).';

    const circuitExpression = '(sqrt(Rm / ((s * Cdl) + 1 / (Rct + (sqrt(Rd / ((s * Cd) + 1 / (Rp + 1 / (s * Cp)))) * Coth(sqrt(Rd * (((s * Cd) + 1 / (Rp + 1 / (s * Cp)))))))))) * Coth(sqrt(Rm * ((s * Cdl) + 1 / (Rct + (sqrt(Rd / ((s * Cd) + 1 / (Rp + 1 / (s * Cp)))) * Coth(sqrt(Rd * (((s * Cd) + 1 / (Rp + 1 / (s * Cp))))))))))))';

    const LatexExpression = 'Z(s) = \\sqrt{\\frac{R_{m}}{sC_{dl} + \\frac{1}{R_{ct} + \\sqrt{\\frac{Rd}{s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}}} \\cdot \\coth\\left(\\sqrt{Rd \\cdot \\left[s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}\\right]}\\right)} }}\\cdot coth\\left[\\sqrt{R_{m} \\cdot \\left(sC_{dl} + \\frac{1}{R_{ct} + \\sqrt{\\frac{Rd}{s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}}} \\cdot \\coth\\left(\\sqrt{Rd \\cdot \\left[s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}\\right]}\\right)} \\right)} \\right]';

    const circuitVariables = [
        { name: 'Rm', initialValue: 2.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct', initialValue: 0.2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 100, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rp', initialValue: 3.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cp', initialValue: 500, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                For many intercalation materials the extrapolation to very low frequencies, based on Paasch-Micka impedance fails to correctly predict experimental data,
                Barsoukov et al.[1] showed that this discrepancy can be resolved by considering that the intercalated ion is not homogeneously distributed in the  host,
                but is stored in one or several separate phases. Phase nucleation is an activated process, which can roughly be described as a nucleation resistance in series
                with capacitance due to charge storage in the new phase. The electochemical kinetic of such system can be modeled with a transmission line which distributed capacitance
                 is in parallel with a charge transfer resistance in series with diffusion with secondary reaction,&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Wphase')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Wphase
                </span>.&nbsp;
                Obtaining low-frequency related parameters <Latex>{`$$R_{p}$$`}</Latex> and <Latex>{`$$C_{p}$$`}</Latex> from a simple fit requires the spectrum reaching <Latex>{`$$100~\\mu Hz$$`}</Latex>.

            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/trphase_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/trphase.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Trphase
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function in terms of electric parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">

                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z(s) = \\sqrt{\\frac{R_{m}}{sC_{dl} + \\frac{1}{R_{ct} + Z_{d}(s)} }}\\cdot coth\\left[\\sqrt{R_{m} \\cdot \\left(sC_{dl} + \\frac{1}{R_{ct} + Z_{d}(s)} \\right)} \\right]$$`}</Latex>
                </Box>
                <br/>
                    where
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z_{d}(s) = \\sqrt{\\frac{Rd}{s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}}} \\cdot \\coth\\left(\\sqrt{Rd \\cdot \\left[s \\cdot Cd + \\frac{1}{Rp + \\frac{1}{s \\cdot Cp}}\\right]}\\right)$$`}</Latex>
                </Box>
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{m} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{ct} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{dl} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{p} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{p} (F)$$`}</Latex>
                    </li>
                </ul>
            </Typography>


            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                Equivalent circuit parameters can be transformed into specific resistance of porous electrode <Latex>{`$$\\rho (\\Omega~cm)$$`}</Latex>,
                specific double-layer capacitance of porous electrode <Latex>{`$$C_{dl} (F/cm^{2})$$`}</Latex>, specific charge transfer resistance <Latex>{`$$R_{ct} (\\Omega cm^{2})$$`}</Latex>
                and, solid-state diffusion coefficient of intercalation species in particles <Latex>{`$$D (sec^{-1}~cm^{2})$$`}</Latex>, emf-relation due to homogeneous
                intercalation <Latex>{`$$\\frac{dE}{dc} (volt~cm^{3})$$`}</Latex>, emf-relation due to new phase formation
                intercalation <Latex>{`$$\\frac{dE_{p}}{dc_{p}} (volt~cm^{3})$$`}</Latex> and volume-specific resistance of the secondary reaction resulting from a
                new phase formation <Latex>{`$$R^{\\prime}_{p} (\\Omega~cm^{3})$$`}</Latex>as follows.
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\rho = \\frac{R_{m}}{d} \\cdot S$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$C_{dl} = \\frac{C_{d}}{S_{true}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$R_{ct} = R_{d} \\cdot S_{true} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\frac{dE}{dc} = \\frac{F\\cdot n \\cdot l \\cdot S_{true}}{C_{d}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\frac{dE_{p}}{dc_{p}} = \\frac{F\\cdot n \\cdot l \\cdot S_{true}}{C_{p}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$R^{\\prime}_{p} = R_{p} \\cdot S_{true} \\cdot l $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$D = \\frac{l^{2}}{C_{d}\\cdot R_{d}} $$`}</Latex>
                </Box>
                Here <Latex>{`$$S$$`}</Latex> is the geometric electrode surface, <Latex>{`$$d$$`}</Latex> is the thickness of the porous layer. <Latex>{`$$S_{true}$$`}</Latex> is
                the true electrochemically active area of particles, <Latex>{`$$n$$`}</Latex> - the number of transferred electrons, <Latex>{`$$l$$`}</Latex>, the characteristic
                diffusion length (half-thickness of flake particle), <Latex>{`$$c$$`}</Latex> is the concentration of diffusing species and <Latex>{`$$c_{p}$$`}</Latex> is the
                concentration of particles separated as a different phase.
                <br/>
                <Latex>{`$$S_{true}$$`}</Latex> can be obtained independently from BET analysis (usually an overestimation), or calculated based on knowledge of
                the true density of the particles <Latex>{`$$\\sigma_{true}$$`}</Latex>, packing density of the layer <Latex>{`$$\\sigma$$`}</Latex>, and
                the characteristic thickness <Latex>{`$$l$$`}</Latex> of the flake-particles as shown below:
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$S_{true} = \\frac{S \\cdot d}{l} \\cdot \\frac{\\sigma}{\\sigma_{true}}$$`}</Latex>
                </Box>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] E.Barsoukov, J.Hyun Kim, D.H.Kim, K.S.Hwang, C.O.Yoon, H.Lee, J.New Materials for Electrochemical Systems, 3, 301
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default SphericalPhase;
