import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const SphericalNoPhase = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);


    const circuitName = 'Electrochemical reaction at a porous material composed of spherical particles (no phase separation)';

    const circuitExpression = '(sqrt(Rm / ((s * Cdl) + 1 / (Rct + ((Tanh(sqrt(Rd * (s * 3 * Cd)))) / ((sqrt((s * 3 * Cd) / Rd) - (1 / Rd) * Tanh(sqrt(Rd * (s * 3 * Cd))))))))) * (Coth(sqrt(Rm * ((s * Cdl) + 1 / (Rct + ((Tanh(sqrt(Rd * (s * 3 * Cd)))) / ((sqrt((s * 3 * Cd) / Rd) - (1 / Rd) * Tanh(sqrt(Rd * (s * 3 * Cd))))))))))))';

    const LatexExpression = 'Z(s) = \\sqrt{\\frac{Rm}{s \\cdot Cdl + \\frac{1}{Rct + \\frac{\\tanh(\\sqrt{Rd \\cdot (s \\cdot 3 \\cdot Cd)})}{\\sqrt{\\frac{s \\cdot 3 \\cdot Cd}{Rd}} - \\frac{1}{Rd} \\cdot \\tanh(\\sqrt{Rd \\cdot (s \\cdot 3 \\cdot Cd)})}}}} \\cdot \\coth\\left(\\sqrt{Rm \\cdot \\left(s \\cdot Cdl + \\frac{1}{Rct + \\frac{\\tanh(\\sqrt{Rd \\cdot (s \\cdot 3 \\cdot Cd)})}{\\sqrt{\\frac{s \\cdot 3 \\cdot Cd}{Rd}} - \\frac{1}{Rd} \\cdot \\tanh(\\sqrt{Rd \\cdot (s \\cdot 3 \\cdot Cd)})}}\\right)}\\right)';

    const circuitVariables = [
        { name: 'Rm', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct', initialValue: 0.01, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rd', initialValue: 0.1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 200, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                This case is typical for intercalation materials where no phase separation occurs udring intercalation and particle form is near to spherical
                (which is the best approximation in most cases). The electrochemical kinetic of such system can be modelled with a transmission line which distributed
                capacitance is in parallel with a series combination of a charge transfer resistance and diffusion toward the center of a spherical particle,&nbsp;
                <span onClick={() => handleNavigate('Distributed Elements', 'Wsplim')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Wsplim
                </span>.&nbsp;
                Such a model was applied by to the analysis of Li-intercalation impedance by Barsoukov et al.[1][2].

            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/trsph_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/trsph.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Trsph
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function in terms of electric parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">

                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z(s) = \\sqrt{\\frac{R_{m}}{sC_{dl} + \\frac{1}{R_{ct} + Z_{d}(s)} }}\\cdot coth\\left[\\sqrt{R_{m} \\cdot \\left(sC_{dl} + \\frac{1}{R_{ct} + Z_{d}(s)} \\right)} \\right]$$`}</Latex>
                </Box>
                <br/>
                    where
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z_{d}(s) = \\frac{tanh\\left( \\sqrt{3 sC_{d} \\cdot R_{d}}\\right)}{\\sqrt{\\frac{3 sC_{d}}{R_{d}}} - \\frac{1}{R_{d}}\\cdot tanh \\left(\\sqrt{3 sC_{d} \\cdot R_{d}} \\right)}$$`}</Latex>
                </Box>
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{m} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{ct} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{dl} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                </ul>
            </Typography>


            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                Equivalent circuit parameters can be transformed into specific resistance of porous electrode <Latex>{`$$\\rho (\\Omega~cm)$$`}</Latex>,
                specific double-layer capacitance of porous electrode <Latex>{`$$C_{dl} (F/cm^{2})$$`}</Latex>, specific charge transfer resistance <Latex>{`$$R_{ct} (\\Omega cm^{2})$$`}</Latex>
                and, solid-state diffusion coefficient of intercalation species in particles <Latex>{`$$D (sec^{-1}~cm^{2})$$`}</Latex>  and emf-relation <Latex>{`$$\\frac{dE}{dc} (volt~cm^{3})$$`}</Latex> as follows.
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\rho = \\frac{R_{m}}{d} \\cdot S$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$C_{dl} = \\frac{C_{d}}{S_{true}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\frac{dE}{dc} = \\frac{F\\cdot n \\cdot r \\cdot S_{true}}{3 C_{d}} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$R_{ct} = R_{d} \\cdot S_{true} $$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$D = \\frac{r^{2}}{3 C_{d}\\cdot R_{d}} $$`}</Latex>
                </Box>
                Here <Latex>{`$$S$$`}</Latex> is the geometric electrode surface, <Latex>{`$$d$$`}</Latex> is the thickness of the porous layer. <Latex>{`$$S_{true}$$`}</Latex> is
                the true electrochemically active area of particles, <Latex>{`$$n$$`}</Latex> - the number of transferred electrons, <Latex>{`$$r$$`}</Latex>, the
                average radius of the spherical particle and <Latex>{`$$c$$`}</Latex> is the concentration of diffusing species.
                <br/>
                <Latex>{`$$S_{true}$$`}</Latex> can be calculated based on knowledge of
                the true density of the particles <Latex>{`$$\\sigma_{true}$$`}</Latex>, packing density of the layer <Latex>{`$$\\sigma$$`}</Latex>, and
                the radius <Latex>{`$$r$$`}</Latex> of the spherical particle:
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$S_{true} = \\frac{3 S \\cdot d}{r} \\cdot \\frac{\\sigma}{\\sigma_{true}}$$`}</Latex>
                </Box>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] E.Barsoukov, J.Hyun Kim, J.H.Kim, C.O.Yoon, H.Lee, Solid State Ionics, 116 (1999) 249
                    </li>
                    <li>
                        [2] E.Barsoukov, J.Hyun Kim, J.H.Kim, C.O.Yoon, H.Lee, J.Electrochem.Soc. 145/8 (1998) 2711
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default SphericalNoPhase;
