
import React, { useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogActions, DialogContent, List, ListItem, ListItemText, ListItemIcon, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from '@mui/material';
import DraggablePaper from './DraggablePaper';

// Loading Data
import TutorialLoadingData from '../tutorial_section/TutorialLoadingData';


// Entering Model
import TutorialEnteringModel from '../tutorial_section/TutorialEnteringModel';


// Simulating Data
import TutorialSimulatingData from '../tutorial_section/TutorialSimulatingData';

// Running The Fit
import TutorialRunningFit from '../tutorial_section/TutorialRunningFit.js';

// Visualizing Data
import TutorialVisualizingData from '../tutorial_section/TutorialVisualizingData';

// Saving Data
import TutorialSavingData from '../tutorial_section/TutorialSavingData';

// References
import References from '../tutorial_section/References';

// License
import License from '../tutorial_section/License';





const Tutorial = ({ openTutorial, setOpenTutorial }) => {
    const [selectedSection, setSelectedSection] = useState(null);
    const [openSectionModal, setOpenSectionModal] = useState(false);

    const handleSelectSection = (section) => {
      setSelectedSection(section);
      setOpenSectionModal(true);
    };

    const handleCloseSectionModal = () => {
      setOpenSectionModal(false);
      setSelectedSection(null);
    };


    const closeAll = () => {
      handleCloseSectionModal();
      setOpenTutorial(false);
    };

    const sections = [
        'Loading Data',
        'Entering the model',
        'Simulating impedance data',
        'Running the fit',
        'Visualizing the data',
        'Saving Data',
        'References',
        'License',
    ];

    const circuitComponents = {
        'Loading Data': () => <TutorialLoadingData  closeModal={closeAll} selectInnerSection={handleSelectSection} />,

        // Entering Model
        'Entering the model': () => <TutorialEnteringModel closeModal={closeAll} selectInnerSection={handleSelectSection}/>,


        // Simulating Data
        'Simulating impedance data': () => <TutorialSimulatingData closeModal={closeAll} selectInnerSection={handleSelectSection}/>,



        // Running The Fit
        'Running the fit': () => <TutorialRunningFit closeModal={closeAll} selectInnerSection={handleSelectSection}/>,


        // Visualizing Data
        'Visualizing the data': () => <TutorialVisualizingData closeModal={closeAll} selectInnerSection={handleSelectSection}/>,

        // Saving Data
        'Saving Data': () => <TutorialSavingData closeModal={closeAll} selectInnerSection={handleSelectSection}/>,


        // References
        'References': () => <References closeModal={closeAll} selectInnerSection={handleSelectSection}/>,

        // License
        'License': () => <License />,


    };


  return (
    <Box>
      <Dialog
        open={openTutorial}
        onClose={() => setOpenTutorial(false)}
        PaperComponent={DraggablePaper}
        PaperProps={{ handle: '.modal-title' }}
      >
        <div className="modal-title">
          <DialogTitle>Welcome to the Tutorial Section!</DialogTitle>
        </div>
        <DialogContent>
        <Typography variant="body1" paragraph component="div">
            <p>This space has been thoughtfully curated to guide you in harnessing the full potential of our system. Our aim here is to empower you with knowledge and tools, enabling you to navigate our system seamlessly.</p>
            <p>Whether you're a novice or an experienced user, these step-by-step instructions are designed with simplicity and user-friendliness in mind.</p>
            <p>From loading data and model parameters, to crafting arbitrary circuit expressions, composing equivalent circuits,  using the circuit ibrary, simulating data , and visualizing data, we've got you covered. Additionally, this tutorial will walk you through the processes of running fits, saving, and loading fit results. We've carefully streamlined these steps to make your experience smooth and efficient.</p>
            <p>So, sit back, relax, and let's embark on this exciting journey of exploration and learning. Remember, the only stupid question is the one that isn't asked, so don't hesitate to <Link href="https://github.com/richinex/fitmyeis-issue-tracker/issues" target="_blank" rel="noopener noreferrer">reach out</Link> if you need further assistance. Happy exploring!</p>
            <p>For a quick tutorial on how to use the application, please&nbsp;
            <span onClick={() => document.getElementById('intro-video').play()} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              click here
            </span>
          . This tutorial video will show you how easy to use FitMyEIS.</p>

        </Typography>

        <video id="intro-video" width="100%" controls>
          <source src={'/circuit_images/complete_fit_using_the_composer_01.mp4'} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

            <List>
            {sections.map((section) => (
              <ListItem
              onClick={() => handleSelectSection(section)}
              key={section}
              sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f0f0f0' } }}
            >
              <ListItemText primary={section} />
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItem>
            ))}

            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenTutorial(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {selectedSection && (
      <Dialog
          open={openSectionModal}
          onClose={handleCloseSectionModal}
          PaperComponent={DraggablePaper}
          PaperProps={{ handle: '.modal-title' }}
      >
          <div className="modal-title">
          <DialogTitle style={{ fontSize: '1.5em' }}>{selectedSection}</DialogTitle>
          </div>
          <DialogContent>
          {circuitComponents[selectedSection]()}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSectionModal} sx={{ marginRight: 'auto' }}>Back</Button>
            <Button onClick={closeAll}>Close</Button>  {/* Adding Close Button */}
          </DialogActions>
      </Dialog>
      )}


    </Box>
  );
};

export default Tutorial;
