import React, { useState, useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
// import Latex from 'react-latex';
import 'katex/dist/katex.min.css';

const TutorialSaveData = ({ closeModal, selectInnerSection }) => {
    const [navigating, setNavigating] = useState(false);

    const handleNavigate = (innerSection) => {
        setNavigating(true);
        selectInnerSection(innerSection);

      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="body1" color="text.secondary" component="div">
                This section will guide you on how to save your impedance model and fit results.
                </Typography>
                <Divider/>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    1. Saving the Impedance Model
                </Typography>
                <Divider/>
                <Typography variant="body1" color="text.secondary" component="div">
                Once you've created your impedance expression, selected or composed a circuit, and filled out the parameter editing section, you may want to save your model. This is especially useful if you plan on using this model for multiple datasets, as it eliminates the need to repeat these steps for every fitting procedure. To save your model:
                <ol style={{ listStyleType: 'upper-roman' }}>
                    <li>Click on the menu icon located at the top-right corner of the application, beside "FitMyEIS", to open the file drawer.</li>
                    <li>Select the 'Save Model' button.</li>
                    <li>Provide a suitable name for your file.</li>
                    <li>Click on the "Save Model" button again to finalize the process.</li>
                    <li>Please note that the "Save Model" button will only be active if the model is valid. This means the resulting Python function should be visible.</li>
                    <li>The model is saved as a JSON file. This file contains the impedance expression and a variables list, which includes the names, initial values, and bounds of the parameters of the impedance model.</li>
                </ol>
                For information on how to load a previously saved model, please refer to the&nbsp;
                <span onClick={() => handleNavigate('Loading Data')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Loading Model
                </span> section.
            </Typography>

            <Divider/>

            <Divider/>
            <Typography variant="h6" color="text.primary" gutterBottom>
                2. Saving the Fit Data
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
            After successfully fitting your data, you might want to save the fit data for future reference or for further analyses. The steps below guide you on how to do this:
            <ol style={{ listStyleType: 'upper-roman' }}>
                <li>Click on the menu icon located at the top-right corner of the application, beside "FitMyEIS", to open the file drawer.</li>
                <li>Locate the 'Save Project' button which can be found below the "Save Model button".</li>
                <li>Clicking on this button will prompt an input field to enter a name for your fit data. If you're doing this for the first time, a placeholder name "fitdata" is provided. You can change this to any name of your choice.</li>
                <li>After inputting your desired name, click on the 'Save Project' button again.</li>
                <li>The application will save your fit data as a JSON file. This file includes all essential fit results, such as fit parameters, errors, selected fit type, and other settings used in your fit process.</li>
                <li>Once the data is successfully saved, the text on the button will briefly change to "Saved!". After a couple of seconds, it will revert to 'Save Project' and the input field will disappear.</li>

            </ol>
                Keep in mind that the 'Save Project' button will only be active if there is valid fit data to be saved.
            </Typography>


        </Box>
    );
};


export default TutorialSaveData;
