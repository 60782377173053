import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const CylindricalCenter = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Diffusion toward the center of a cylindrical rod';

    const circuitExpression = '(((I0(sqrt(2 * Rd * (s * Cd)))) / ((sqrt(2 * Rd * (s * Cd))) * I1(sqrt(2 * Rd * (s * Cd))))) * Rd)';

    const circuitVariables = [
        { name: 'Rd', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 200, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },

    ];

    const LatexExpression = 'Z(s) = \\frac{I_{0}(\\sqrt{2 \\cdot Rd \\cdot (s \\cdot Cd)})}{\\sqrt{2 \\cdot Rd \\cdot (s \\cdot Cd)} \\cdot I_{1}(\\sqrt{2 \\cdot Rd \\cdot (s \\cdot Cd)})} \\cdot Rd';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                This closely corresponds to the impedance of a cylindrical rod-like particle, where the intercalation compound diffuses towards the center of the rod.
                It can be important as one of the building blocks of an impedance of a porous electrode composed of such cylindrical particles. Cylindrical particles
                can be found in Li-ion battery anodes (carbon fibers) and in conducting polymers (e.g polyaniline) agglomerates.
                <br/>
                This case can be represented as an inhomogeneous infinite transmission line, in which the resistance is increasing and distributed capacitances is decresing from left to right
                proportionally to the decrease of flow surface while near to the center of the cylinder. Treating this transmission line as an approximation of a lumped network is impractical;
                formulating it directly as continuous differential equations is preferable. Therefore, no equivalent circuit is shown.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/wcylim.png" alt="Nyquist" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
                <br/>
                <br/>
                Here <Latex>{`$$I_{0}$$`}</Latex> and <Latex>{`$$I_{1}$$`}</Latex> are modified Bessel functions of the first kind of order 0 and 1 respectively.
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>

            <Typography variant="body1" color="text.secondary">
                Wcylim
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>

                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$\\frac{dE}{dc}, (volt~cm^{3}) = \\frac{F\\cdot n \\cdot \\pi r^{2}h}{C_{d}}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$D (sec^{-1} cm^{3}) = \\frac{r^{2}}{2 C_{d} \\cdot R_{d}} $$`}</Latex>
                </Box>

                    Where r is the radius of the spherical electrode, h is the length of the cylindrical rod in <Latex>{`$$cm^{2}$$`}</Latex>, n is the number of electrons participating in the reaction,
                    and c is the volume concentration of diffusing species in <Latex>{`$$mol \\cdot cm^{3}$$`}</Latex>.

         </Typography>
         <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] T.Jacobsen and K.West, Electrochimica Acta, 40/2 (1995) 255
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default CylindricalCenter;
