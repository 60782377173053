/////////////////////////////////////
import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, Select, Typography, MenuItem, Checkbox, FormGroup, TextField, InputLabel } from '@mui/material';
import DraggablePaper from './DraggablePaper';
import * as math from 'mathjs';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SharedButton from './SharedButton';



const LoadCustomData = ({ openModal, handleCloseModal, setFileData, setPlotData, setFitData, setIsFileUploaded, showSuccessMessage, setShowSuccessMessage, setLoadedFileNames }) => {


  const [decimalSeparator, setDecimalSeparator] = useState('.');
  const [isZNegative, setIsZNegative] = useState(false);
  const [skipRows, setSkipRows] = useState(0);
  const [delimiter, setDelimiter] = useState(',');
  const [freqColumn, setFreqColumn] = useState(0);
  const [realImpedanceColumn, setRealImpedanceColumn] = useState(1);
  const [imagImpedanceColumn, setImagImpedanceColumn] = useState(2);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if(alertOpen) {
      const timer = setTimeout(() => {
        setAlertOpen(false);
      }, 3000); // alert will close after 3 seconds

      return () => clearTimeout(timer); // clean up on component unmount
    }
  }, [alertOpen]);


  const handleLabelClick = (event) => {
    event.preventDefault();

    if (fileInputRef.current) {
    setFiles([]);
    setFileNames([]);  // clear the filenames
    fileInputRef.current.value = null;
    fileInputRef.current.click();
    }
  };



  const handleFileInput = (e) => {
    setFiles(e.target.files);  // Save the selected files into the state
    setFileNames(Array.from(e.target.files).map(file => file.name));
  };


  const handleLoadData = (e) => {

    setShowSuccessMessage(false);

    // Clear the state for fileData and isFileUploaded
    setFileData([]);
    setIsFileUploaded(false);
    setFitData(null);



    if (!files.length) {
      // Set the alert message and open the alert
      setAlertMessage('No file selected');
      setAlertOpen(true);
      return;
    }

    if (files.length > 50) {
      alert('Error: You can only load 50 spectra at a time.');
      setIsFileUploaded(false);
      return;
    }

    let allFileData = [];
    let newLoadedFileNames = [];

    for (const file of files) {
      newLoadedFileNames.push(file.name);
      const reader = new FileReader();

      reader.onload = (event) => {
        const fileContent = event.target.result;

        let delimiterRegEx;
        switch (delimiter) {
          case ',':
              delimiterRegEx = /,+/;
              break;
          case '.':
              delimiterRegEx = /\.+/;
              break;
          case ';':
              delimiterRegEx = /;+/;
              break;
          case '\t':
              delimiterRegEx = /\t+/;
              break;
          case ' ':
              delimiterRegEx = / +/;
              break;
          default:
              delimiterRegEx = /[ ,\t]+/;
          }

        let rows = fileContent.trim().split('\n').slice(skipRows);

        if (decimalSeparator === ',') {
          const scientificNotationRegex = /(\d+)?,(\d*)?[Ee]([+-]?\d+)/gi;

          rows = rows.map(row => {
              return row.replace(scientificNotationRegex, function(match, integerPart = '', fractionalPart = '', exponent){
                  return integerPart + '.' + (fractionalPart || '0') + 'E' + exponent;
              }).replace(/,/g, '.');
          });
        }

        const firstRowColumns = rows[0].split(delimiterRegEx);
        const isFirstRowHeader = firstRowColumns.some((column) => isNaN(column));
        if (isFirstRowHeader) {
          rows = rows.slice(1);
        }

        const numColumns = rows[0].split(delimiterRegEx).length;
        const hasSameNumberOfColumns = rows.every(
          (row) => row.split(delimiterRegEx).length === numColumns
        );

        if (!hasSameNumberOfColumns) {
          alert('Error: Rows have different numbers of columns.');
          setIsFileUploaded(false);
          return;
        }

        const fileData = rows.map((line) => {
          const columns = line.split(delimiterRegEx).map(Number);
          const freq = columns[freqColumn];
          const zreal = columns[realImpedanceColumn];
          const zimag = isZNegative ? -columns[imagImpedanceColumn] : columns[imagImpedanceColumn];
          return { freq, zreal, zimag };
        });

        const isValidData = fileData.every((dataPoint) => {
          return (
            !isNaN(dataPoint.freq) &&
            !isNaN(dataPoint.zreal) &&
            !isNaN(dataPoint.zimag)
          );
        });

        if (isValidData) {
          allFileData.push(fileData);
        } else {
          setIsFileUploaded(false);
        }
      };

      reader.onloadend = () => {
        if (allFileData.length > 0) {
          setPlotData([]);
          setFileData(allFileData);
          setIsFileUploaded(true);

          const newPlotData = allFileData.map((dataset, datasetIndex) =>
            dataset.map((dataPoint) => {
              const Z = math.complex(dataPoint.zreal, dataPoint.zimag);
              // console.log(Z);
              const Y = math.inv(Z);
              const real = dataPoint.zreal;
              const imag = dataPoint.zimag;
              const magnitude = Math.sqrt(real ** 2 + imag ** 2);
              const phase = Math.atan2(imag, real) * (180 / Math.PI);

              return {
                realImpedance: dataPoint.zreal,
                imagImpedance: -dataPoint.zimag,
                realAdmittance: Y.re,
                imagAdmittance: Y.im,
                freq: dataPoint.freq,
                datasetIndex: datasetIndex,
                magnitude,
                phase,
              };
            })
          );

          setPlotData(newPlotData);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        } else {
          setIsFileUploaded(false);
        }
      };

      reader.readAsText(file);
    }

    setLoadedFileNames(newLoadedFileNames);
  };


  const handleCloseAlert = () => {
    setAlertOpen(false);
  };



  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      PaperComponent={DraggablePaper}
      PaperProps={{ handle: '.modal-title' }}
    >
      <DialogTitle className="modal-title">Load Custom Data
      </DialogTitle>
      <DialogContent dividers>
        <Collapse in={alertOpen}>
          <Alert severity="error" onClose={handleCloseAlert}>
            {alertMessage}
          </Alert>
        </Collapse>
        <Box sx={{ mb: 2 }}>
        <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileInput}
            style={{ display: 'none' }}
            multiple
            />
        <SharedButton style={{ width: '200px' }} htmlFor="file-upload" onClick={handleLabelClick}>
                Choose File(s)
        </SharedButton>
        <Typography variant="body2" sx={{ mt: 2, fontStyle: "italic", color: "text.secondary" }}>
          {fileNames.length === 0 ? 'No file(s) selected' :
          fileNames.length === 1 ? `Selected file: ${fileNames[0]}` :
          `Selected ${fileNames.length} files`
          }
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
              <FormControl sx={{ mt: 2, width: 200 }}>
                <InputLabel id="delimiter-options-select-label">Delimiter</InputLabel>

                <Select value={delimiter}
                labelId="delimiter-options-select-label"
                onChange={e => setDelimiter(e.target.value)}
                label="Delimiter"
                >
                  <MenuItem value=",">Comma (,)</MenuItem>
                  <MenuItem value=".">Dot (.)</MenuItem>
                  <MenuItem value=";">Semicolon (;)</MenuItem>
                  <MenuItem value="\t">Tab (\t)</MenuItem>
                  <MenuItem value=" ">Space ( )</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ mt: 2, width: 200 }}>
              <InputLabel id="decimal-separator-options-select-label">Decimal Separator</InputLabel>
                <Select
                labelId="decimal-separator-options-select-label"
                value={decimalSeparator}
                onChange={e => setDecimalSeparator(e.target.value)}
                label="Decimal Separator"
                >
                  <MenuItem value=".">Dot (.)</MenuItem>
                  <MenuItem value=",">Comma (,)</MenuItem>

                </Select>
              </FormControl>

              <FormGroup sx={{ mt: 2, width: '100%' }}>
                <FormControlLabel
                  control={<Checkbox checked={isZNegative} onChange={e => setIsZNegative(e.target.checked)} />}
                  label="Convert the imaginary part of the impedance to -Z"
                />
              </FormGroup>

              <TextField
                sx={{ mt: 2}}
                label="Skip Rows"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={skipRows}
                onChange={e => setSkipRows(e.target.value)}
              />

            <Divider sx={{width: '100%' }} />

            <Typography sx={{ width: '100%' }} variant="body1">Column Index (Zero-based)</Typography>

            <Divider sx={{ width: '100%' }} />
              <TextField
                sx={{ mt: 2 }}
                label="Frequency"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={freqColumn}
                onChange={e => setFreqColumn(e.target.value)}
              />

              <TextField
                sx={{ mt: 2}}
                label="Real part of Impedance"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={realImpedanceColumn}
                onChange={e => setRealImpedanceColumn(e.target.value)}
              />

              <TextField
                sx={{ mt: 2, mb: 1 }}
                label="Imaginary part of Impedance"
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
                value={imagImpedanceColumn}
                onChange={e => setImagImpedanceColumn(e.target.value)}
              />
          </Box>
          <Collapse in={showSuccessMessage}>
          <Alert
              severity="success"
              action={
              <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                  setShowSuccessMessage(false);
                  }}
              >
                  <CloseIcon fontSize="inherit" />
              </IconButton>
              }
          >
              File loaded successfully!
          </Alert>
          </Collapse>
          <SharedButton
            sx={{
                mt: 2,
                width: '200px',
                '&.Mui-disabled': {
                    backgroundColor: '#d3d3d3', // Light gray background
                    color: '#808080' // Dark gray text
                }
                }}
            onClick={handleLoadData}
            disabled={fileNames.length === 0}>
                Process & Load Data
            </SharedButton>
        </Box>

      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={handleCloseModal} >
          Close
        </Button>
        </DialogActions>
    </Dialog>
  );

};

export default LoadCustomData;