import React, { useState, useEffect } from 'react';
import { Box, Divider, Typography } from '@mui/material';
// import Latex from 'react-latex';
import 'katex/dist/katex.min.css';

const TutorialLoadingData = ({ closeModal, selectInnerSection}) => {
    const [navigating, setNavigating] = useState(false);

    const handleNavigate = (innerSection) => {
        setNavigating(true);
        selectInnerSection(innerSection);

      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >

            <Divider/>
            <Typography variant="h5" color="text.primary" gutterBottom>
                In this section, we will guide you through the process of loading impedance data into the application.
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                1. Loading impedance data
                <ol>
                    <li>Navigate to the menu icon at the top right corner of the application beside "FitMyEIS" and click on it to open the file drawer.</li>
                    <li>Click on the 'Load Impedance Data' button.</li>
                    <li>In the dialog box that appears, you can import your impedance data by navigating to the location of the file on your device. </li>
                    <li>Note that the file should be in a specific format (e.g. .csv, .txt) containing three columns (frequencies, real and imaginary parts of the impedance).</li>
                    <li>After selecting the file, click 'Open' to load it into the application.</li>
                    <li>If a single file is loaded, the file name appears at the buttom of the screen. However if multiple files are loaded, the number of files loaded is shown</li>
                    <li>Note that if multiple files are loaded, all the files must contain the same number of rows. and the same set of frequencies.</li>
                    <li>Once the file is successfully loaded you can click on the nyquist or Bode plot buttons to view the Nyquist and Bode plot/s of the loaded impedance data</li>
                </ol>
                <p>Please&nbsp;
                <span onClick={() => document.getElementById('multiple-spectra-video').play()} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    click here
                </span>&nbsp;
                to see how to load multiple impedance data.</p>
            </Typography>
            <video id="multiple-spectra-video" width="100%" controls>
             <source src={'/circuit_images/multiple_spectra_video.mp4'} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <Divider/>
            <Typography variant="h5" color="text.primary" gutterBottom>
                2. Loading previously fit data
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                This section shows how to load previously fit data into the application.
                <ol>
                    <li>Navigate to the menu icon at the top right corner of the application beside "FitMyEIS" and click on it to open the file drawer.</li>
                    <li>Click on the 'Load Project' button.</li>
                    <li>In the dialog box that appears, navigate to the location of your saved project on your device. The file should be in JSON format, previously exported from this application.</li>
                    <li>Select the file and click 'Open'.</li>
                    <li>The application will then load and validate the data, and if successful, you will see your fit data populated in the respective fields of the application.</li>
                </ol>
                Please note: Manual input of fit data is not supported, as fit data files contain specific properties that must be correctly formatted to ensure proper functionality of the application.
            </Typography>
            <Divider/>
            <Typography variant="h6" component="h2">
                3. Deleting Unwanted Rows
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" component="div">
                This section guides you on how to delete unwanted rows from your dataset in the application.
                <ol>
                    <li>Navigate to the "Edit Impedance Data" button and click it. This will open up the data editor dialog.</li>
                    <li>In the data editor, you will see a table which displays your impedance data.</li>
                    <li>Identify the rows you want to remove. Rows in the data table are usually identified by an index number on the far left.</li>
                    <li>To remove a row, click on the delete icon (usually a trash bin) corresponding to the row. Note that you can only delete one row at a time.</li>
                    <li>After you have removed all unwanted rows, click the 'Save Changes' button at the bottom of the dialog. This will save your changes and close the data editor.</li>
                </ol>
                <span style={{color: 'red'}}>Please note: if you load multiple files and delete a row at a certain index, the corresponding row will also be deleted from all other files loaded. Also, once deleted, rows cannot be restored, so ensure to double-check before deleting.</span>
            </Typography>


            <br/>
            <br/>

            <Typography variant="body1" color="text.secondary" component="div">
               See&nbsp;
                <span onClick={() => handleNavigate('Visualizing the data')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    Visualizing the data
                </span>&nbsp;for how to view the resulting Nyquist and Bode plots or Click&nbsp;
                <span onClick={() => handleNavigate('Entering the model')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    here
                </span>&nbsp;to learn how to enter the impedance model.

            </Typography>
        </Box>
    );
};

export default TutorialLoadingData;
