// AddButton.js
import { Button } from '@mui/material';
import { styled } from '@mui/system';

const AddButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  padding: theme.spacing(1),
  borderRadius: 4,
  cursor: 'pointer',
  textAlign: 'center',
  width: '100%',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default AddButton;
