
import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Collapse, TextField } from '@mui/material';
import DraggablePaper from './DraggablePaper';
import MuiAlert from '@mui/material/Alert';
import { CSVLink } from "react-csv";
import ComposerButton from './ComposerButton';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ParamTable = ({ open, handleClose, paramsData, errorData, paramNames, variables, setVariables, loadedFileNames }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [fileName, setFileName] = useState('fit');
  const [inputVisible, setInputVisible] = useState(false);
  const [isTimedOut, setIsTimedOut] = useState(false);

  const downloadParamsCSVRef = useRef(null);
  const downloadErrorsCSVRef = useRef(null);

  const fileNameRef = useRef(fileName);
  fileNameRef.current = fileName;

  useEffect(() => {
    if (inputVisible) {
      const timer = setTimeout(() => {
        setInputVisible(false);
        setFileName(fileNameRef.current);
        setIsTimedOut(true);
      }, 10000); // set timeout for 10 seconds

      return () => clearTimeout(timer);
    }
  }, [inputVisible]);

  useEffect(() => {
    if (isTimedOut) {
      const timer = setTimeout(() => {
        setIsTimedOut(false);
      }, 2000); // show 'Timed out' message for 2 seconds

      return () => clearTimeout(timer);
    }
  }, [isTimedOut]);

  const handleUseAsInitialValues = (datasetParams) => {
    const variableNames = variables.map(variable => variable.name);
    if (JSON.stringify(variableNames) !== JSON.stringify(paramNames)) {
      setAlertMessage('The variable names do not match with parameter names. The initial values will not be updated.');
      setAlertSeverity('error');
      setAlertOpen(true);
      return;
    }

    const newVariables = variables.map((variable, index) => ({
      ...variable,
      initialValue: parseFloat(datasetParams[index]).toFixed(15),
    }));

    setVariables(newVariables);
    setAlertMessage('The initial values have been successfully updated.');
    setAlertSeverity('success');
    setAlertOpen(true);
  };

  const convertToCSV = (data) => {
    if (!data) return null; //
    const headers = ['dataset_name', ...paramNames];
    const csvData = data.map((datasetParams, index) => [loadedFileNames[index], ...datasetParams]);
    return [headers, ...csvData];
  };


  const paramCSVData = convertToCSV(paramsData);
  const errorCSVData = convertToCSV(errorData);

  const handleClick = () => {
    if(inputVisible) {
      setInputVisible(false);
      setFileName(fileNameRef.current);
      downloadParamsCSVRef.current.link.click();
      downloadErrorsCSVRef.current.link.click();
    } else {
      setInputVisible(true);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}
      PaperComponent={DraggablePaper}
      PaperProps={{ handle: '.modal-title' }}
    >
      <div className="modal-title">
        <DialogTitle>Fit Parameters Table</DialogTitle>
      </div>
      <DialogContent dividers>
        <Collapse in={alertOpen}>
          <Alert
            severity={alertSeverity}
            action={
              <Button color="inherit" size="small" onClick={() => setAlertOpen(false)}>
                Close
              </Button>
            }
          >
            {alertMessage}
          </Alert>
        </Collapse>
        {paramsData.map((datasetParams, datasetIndex) => (
          <div key={datasetIndex}>
            <Typography variant="h6">Dataset {datasetIndex + 1} ({loadedFileNames[datasetIndex]})</Typography>
            <TableContainer component={Paper}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{border: '1px solid black'}}>Parameter Name</TableCell>
                    <TableCell sx={{border: '1px solid black'}}>Value</TableCell>
                    <TableCell sx={{border: '1px solid black'}}>Standard Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {datasetParams.map((paramValue, paramIndex) => (
                    <TableRow key={paramIndex}>
                      <TableCell
                        sx={{
                          border: '1px solid black',
                          overflow: 'auto',
                          maxWidth: 200,
                        }}
                      >
                        {paramNames[paramIndex]}
                      </TableCell>
                      <TableCell sx={{border: '1px solid black'}}>{paramValue}</TableCell>
                      <TableCell sx={{border: '1px solid black'}}>{errorData[datasetIndex][paramIndex]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <ComposerButton
                style={{ width: '200px' }}
                onClick={() => handleUseAsInitialValues(datasetParams)} sx={{ mt: 1 }}>
              Use As Initial Values
            </ComposerButton>
          </div>
        ))}
        <DialogActions>
        {inputVisible && (
            <TextField
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              label="File name"
              sx={{
                '& .MuiInputBase-input': {
                  height: '30px',
                  padding: '0px 10px',
                  fontSize: '16px',
                }
              }}
            />
          )}
          <Button variant="contained" color="primary" onClick={handleClick} sx={{bgcolor: inputVisible ? '#1976d2' : '#e0e0e0', mx: 1}}>
            {inputVisible ? 'Confirm Save' : 'Save as CSVs'}
          </Button>
          <CSVLink data={paramCSVData} filename={`${fileName}_params.csv`} style={{display: 'none'}} ref={downloadParamsCSVRef} />
          <CSVLink data={errorCSVData} filename={`${fileName}_errors.csv`} style={{display: 'none'}} ref={downloadErrorsCSVRef} />
          <Button variant="contained" color="secondary" onClick={handleClose} sx={{mx: 1}}>
            Close
          </Button>
          {isTimedOut && (
            <Typography variant="body1" color="error">
              Timed out, please try again.
            </Typography>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ParamTable;
