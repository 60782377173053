import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';



const TransmissiveFiniteLengthWarburg = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Finite length diffusion with transmissive boundary (Transmissive finite length Warburg impedance - Warburg short)';

    const circuitExpression = '(sqrt(Rd / (s * Cd)) * Tanh(sqrt(Rd * (s * Cd))))';


    const circuitVariables = [
        { name: 'Rd', initialValue: 1, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cd', initialValue: 10, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = $$\\sqrt{\\frac{Rd}{s \\cdot Cd}} \\cdot \\tanh \\sqrt{Rd \\cdot (s \\cdot Cd)}$$';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Typography variant="body1" color="text.secondary" paragraph>
                In the case of reaction of mobile active species distributed in a layer with finite length terminated by a permeable boundary,
                the impedance behaviour is similar to that of an electrical transmission line that is terminated in a short circuit,
                which allows current to flow freely without any reflections. This case is realized if species is diffusing through a semi-permeable membrance
                before reaching the electrode interface, or in the case of impedance measuremnent on a rotating disc electrode, where diffusion length is constant
                and determined by rotation speed.
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/warburg_short_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/warburg_short.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>
            <Typography variant="h6" color="text.primary" gutterBottom>
                Symbol
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$$Ws$$`}</Latex>
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Fit parameters:</h3>
                <ul>
                    <li>
                        <Latex>{`$$C_{d} (F)$$`}</Latex>
                    </li>
                    <li>
                        <Latex>{`$$R_{d} (\\Omega)$$`}</Latex>
                    </li>
                </ul>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Conversion into electrochemical parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$\\frac{dE}{dc} (volt~cm^{3}) = \\frac{F\\cdot n \\cdot d \\cdot S}{C_{d}}$$`}</Latex>
                </Box>
                <Box display="flex" justifyContent="center" my={2}>
                <Latex>{`$$D (sec^{-1} cm^{3}) = \\frac{d^{2}}{C_{d} \\cdot R_{d}} $$`}</Latex>
                </Box>
                Where d is the thickness of the diffusion layer (length of the transmission line), S is the geometric electrode area in <Latex>{`$$cm^{2}$$`}</Latex>, n - the number of electrons participating in the reaction and c  - the volume concentration in <Latex>{`$$mol \\cdot cm^{3}$$`}</Latex>.
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );
};

export default TransmissiveFiniteLengthWarburg;
