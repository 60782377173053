
import React from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const FilmSolutionInterface = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {

    const circuitName = 'Effect of Film-Solution interface on corrosion impedance';

    const circuitExpression = '(Rser + (1 / ((1 / Rinf1) + (k / (k + s)) * (1 / R1) + (1 / Rinf2) * (s / (k + s)) + (s * Cinf))))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 0.00001, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rinf1', initialValue: 10.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rinf2', initialValue: 20.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'R1', initialValue: 5.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'k', initialValue: 0.01, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cinf', initialValue: 1.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\frac{1}{\\left( \\frac{1}{R_{inf1}} + \\frac{k}{k + s} \\cdot \\frac{1}{R_1} + \\frac{1}{R_{inf2}} \\frac{s}{k + s} + sC_{inf} \\right)}';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/filmsolutioninterface_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/filmsolutioninterface.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Impedance of the <strong>film-solution interface of a corroding electrode</strong> can be
                expressed by equation derived by Armstrong and Edmondson by considering
                simultaneous charge transfer into and out of passivation film
                by anion and cations [1].
                This impedance function is interesting because at certain ratios between
                ion transfer rate dependencies on overvoltage and surface coverage it
                gives inductive effects, indeed observed in experiment.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>

                <h4>Preliminary definitions</h4>
                V<sub>1</sub> is transfer rate of cations from solution to film,
                V<sub>2</sub> is transfer rate of cations from film to solution,
                V<sub>3</sub> is transfer rate of cations from solution to film,
                and V<sub>4</sub> is transfer rate of cations from film to solution. <Latex>{`$$\\Gamma$$`}</Latex> is excess of cation coverage over anion coverage
                in the surface layer.
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance in the electrolyte pathway that is not compensated for by the reference electrode
                    </li>
                    <li>
                        <Latex>{`$$R_{inf1}$$`}</Latex> is the "infinite frequency charge transfer resistance of
                            cations", and is related to dependence of cation transfer rates on overvoltage as:
                        <Box>
                            <Latex>{`$$\\frac{1}{R_{inf1}} = n_{1} \\cdot F \\cdot \\left(\\frac{dV_{1}}{dE} \\right)_{\\Gamma} + n_{2} \\cdot F \\cdot \\left(\\frac{dV_{2}}{dE} \\right)_{\\Gamma}$$`}</Latex>
                        </Box>

                    </li>

                    <li>
                        <Latex>{`$$R_{inf2}$$`}</Latex> is the "infinite frequency charge transfer resistance of
                            anions", and is related to dependence of anion transfer rates on overvoltage as:
                        <Box>
                            <Latex>{`$$\\frac{1}{R_{inf2}} = n_{3} \\cdot F \\cdot \\left(\\frac{dV_{3}}{dE} \\right)_{\\Gamma} + n_{4} \\cdot F \\cdot \\left(\\frac{dV_{4}}{dE} \\right)_{\\Gamma}$$`}</Latex>
                        </Box>

                    </li>

                        <li>
                    <Latex>{`$$R_{1}$$`}</Latex> is the charge transfer resistance of cations at zero frequency
                            and is related to dependence of cation transfer rates on
                            overvoltage and coverage as:
                        <Box>
                            <Latex>{`$$\\frac{1}{R_{inf1}} = \\left[\\left(\\frac{dV_{1}}{dE} \\right)_{\\Gamma} + \\left(\\frac{dV_{2}}{dE} \\right)_{\\Gamma} + \\left(\\frac{dV_{3}}{dE} \\right)_{\\Gamma} + \\left(\\frac{dV_{4}}{dE} \\right)_{\\Gamma} \\right] $$`}</Latex>
                        </Box>
                        Same resistance for anions is assumed to be equal to -R<sub>inf2</sub> and therefore does not appear as a separate parameter.

                    </li>

                    <li>
                        <Latex>{`$$k$$`}</Latex> is the rate constant ion transfer, given as:

                    </li>
                        <box>
                            <Latex>{`$$k = \\left(\\frac{dV_{3}}{d\\Gamma} \\right)_{E} + \\left(\\frac{dV_{4}}{d\\Gamma} \\right)_{E} - \\left(\\frac{dV_{1}}{d\\Gamma} \\right)_{E} - \\left(\\frac{dV_{2}}{d\\Gamma} \\right)_{E}$$`}</Latex>
                        </box>

                    <li>
                        <Latex>{`$$C_{inf}$$`}</Latex> is the capacitance of the double-layer
                    </li>

                </ul>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] R.D.Armstrong and K. Edmondson, The Impedance of Metals in the Passive and Transpassive Regions, Electrochim. Acta 18 (1973) 937
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};

export default FilmSolutionInterface;
