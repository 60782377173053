import React, { useState, useEffect } from 'react';
import NyquistPlot2D from './NyquistPlot2D';
import ExpressionTextField from './ExpressionTextField';
import { create, all } from 'mathjs';




import {
  Box,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import { custom_tanh, custom_coth } from '../custom_funtions/mathHelpers';

const Simulator = ({
  variables,
  onVariableChange,
  onVariableBlur,
  onAccept,
  expression,
  plotData,
}) => {
  const [initialValuesData, setInitialValuesData] = useState([]);
  const [Fmin, setFmin] = useState(0.001);
  const [Fmax, setFmax] = useState(1000000);
  const [Pperd, setPperd] = useState(8);
  const [isFreqInputValid, setIsFreqInputValid] = useState(true); // Add this line



  const titleStyle = {
    fontWeight: 'bold',
    position: 'sticky',
    top: 0,
    background: 'white',
    zIndex: 1,
  };


  const inputStyle = {
    padding: '4px 8px',
    paddingTop: '6px',
    paddingBottom: '8px',
    marginLeft: '10px', // Add margin to the entry inside the box
    fontSize: '14px',
  };




  const generateLogspaceData = (Fmin, Fmax, Pperd) => {
    // Convert inputs to numbers and handle invalid inputs
    Fmin = parseFloat(Fmin) || 0.001;  // defaults to 0.001 if Fmin is not a valid number
    Fmax = parseFloat(Fmax) || 1000000; // defaults to 1000000 if Fmax is not a valid number
    Pperd = parseFloat(Pperd) || 8;     // defaults to 8 if Pperd is not a valid number

    const decades = Math.log10(Fmax / Fmin);
    const numPoints = Math.round(decades * Pperd);

    if (numPoints > 0) {
      const logspaceData = Array.from({ length: numPoints }, (_, i) => {
        const exponent = (i / Pperd) + Math.log10(Fmin);
        const freq = Math.pow(10, exponent);
        return { freq };
      });
      return logspaceData;
    }

    return [];
  };

  useEffect(() => {
    const config = {};
    const math = create(all, config);
    // console.log('Fmin:', Fmin);
    // console.log('Fmax:', Fmax);
    // console.log('Pperd:', Pperd);

    if (
        !isFreqInputValid ||
        !variables ||
        variables.length === 0
      ) {
        return;
      }



    // Define the new function
    math.import({
      K0: function(x) {
        let result = window.besselK(0, x);
        return math.complex(result.re, result.im);
      },
      K1: function(x) {
        let result = window.besselK(1, x);
        return math.complex(result.re, result.im);
      },
      I0: function(x) {
        let result = window.besselI(0, x);
        return math.complex(result.re, result.im);
      },
      I1: function(x) {
        let result = window.besselI(1, x);
        return math.complex(result.re, result.im);
      },
      Tanh: function(x) {
        let result = custom_tanh(x);
        return math.complex(result);
      },
      Coth: function(x) {
        let result = custom_coth(x);
        return math.complex(result);
      },
    });




    const plotDataToUse = plotData[0] ? plotData[0] : generateLogspaceData(Fmin, Fmax, Pperd);

    const createExpressionsWithVariables = (variables, expression) => {
      const variableDictionary = variables.reduce((dict, variable) => {
        // If the variable value is real, convert it to a complex number
        const variableValue = typeof variable.initialValue === 'number'
          ? `complex(${variable.initialValue}, 0)`
          : variable.initialValue;

        dict[variable.name] = variableValue;
        return dict;
      }, {});


      const expressionsWithS = plotDataToUse.map((data) => {
        const sValue = `complex(0, 1) * 2 * PI * ${data.freq}`;
        return expression.replace(/\bs\b/g, sValue);
      });

      return expressionsWithS.map((exprWithS) =>
        Object.keys(variableDictionary).reduce((expr, variableName) => {
          const variableValue = variableDictionary[variableName];
          const variableRegex = new RegExp(`\\b${variableName}\\b`, 'g');
          return expr.replace(variableRegex, variableValue);
        }, exprWithS)
      );
    };




    try {
      const expressionsWithVariables = createExpressionsWithVariables(variables, expression);

      // console.log('expressionsWithVariables:', expressionsWithVariables);

      const impedance = expressionsWithVariables.map((exprWithVars) => {
        return math.evaluate(exprWithVars);
      });

      // console.log('Impedance:', impedance);

      const isArrayOfComplexNumbers = Array.isArray(impedance) && impedance.every((value) => math.typeOf(value) === 'Complex');
      if (isArrayOfComplexNumbers) {
        const initialValuesData = impedance.map((value, index) => {
          return {
            freq: plotDataToUse[index].freq, // Add the frequency property
            realImpedance: math.re(value),
            imagImpedance: -math.im(value),
          };
        });
        setInitialValuesData(initialValuesData);
      }
    } catch (error) {
      console.error('Error in expression:', error);
      setInitialValuesData([]);

    }
  }, [expression, plotData, variables, Fmin, Fmax, Pperd, isFreqInputValid]);



const handleFrequencyParametersBlur = (parameter) => {
  let inputValid = true;

  let isEmpty = false;

  const showError = (message) => {
    alert(message);
    inputValid = false;
  };

  const handleEmptyInput = (value, defaultValue) => {
    if (value === '') {
      isEmpty = true;
      return defaultValue;
    }
    return value;
  };

  if (parameter === 'Fmin') {
    let newFmin = handleEmptyInput(Fmin, 0.00001);
    newFmin = parseFloat(newFmin);

    if (newFmin < 0.00001) {
      showError('Fmin must not be less than 0.00001.');
      setFmin(0.00001);
    } else if (isEmpty) {
      setFmin(0.00001);
    }
  } else if (parameter === 'Fmax') {
    let newFmax = handleEmptyInput(Fmax, 1000000);
    newFmax = parseFloat(newFmax);

    if (newFmax > 1000000) {
      showError('Fmax must not be higher than 1,000,000.');
      setFmax(1000000);
    } else if (newFmax <= parseFloat(Fmin)) {
      showError('Fmax must be greater than Fmin.');
      setFmax(Math.max(parseFloat(Fmin) + 1, 1000));
    } else if (isEmpty) {
      setFmax(1000000);
    }
  } else if (parameter === 'Pperd') {
    let newPperd = handleEmptyInput(Pperd, 1);
    newPperd = parseFloat(newPperd);

    if (newPperd < 1 || newPperd > 10) {
      showError('Pperd must be between 1 and 10.');
      setPperd(Math.max(1, Math.min(10, parseFloat(Pperd))));
    } else if (isEmpty) {
      setPperd(1);
    }
  }

  setIsFreqInputValid(inputValid);
};




  return (
    <Grid container spacing={2} alignItems="center" justifyContent="center">

      <Grid item xs={12} sm={6}>

        <Grid container direction="row" spacing={2} alignItems="flex-start">
          <Grid item xs={12} sm={12}>
          <Grid container direction="row" spacing={2} alignItems="flex-start">
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%">
              <ExpressionTextField
                label="Fmin"
                value={Fmin}
                onChange={(e) => setFmin(e.target.value)}
                onBlur={() => handleFrequencyParametersBlur('Fmin')}
                margin="dense"
                variant="outlined"
              />
              <ExpressionTextField
                label="Fmax"
                value={Fmax}
                onChange={(e) => setFmax(e.target.value)}
                onBlur={() => handleFrequencyParametersBlur('Fmax')}
                margin="dense"
                variant="outlined"
              />
              <ExpressionTextField
                label="Pperd"
                value={Pperd}
                onChange={(e) => setPperd(e.target.value)}
                onBlur={() => handleFrequencyParametersBlur('Pperd')}
                margin="dense"
                variant="outlined"
              />
            </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginTop: '16px' }}>
          <Typography variant="body1" style={titleStyle}>Parameters:</Typography>
          <Box maxHeight={300} overflow="auto">
          {variables.map((variable, index) => (
            <Grid container spacing={1} alignItems="center" key={index}>
              <Grid item xs={12} sm={6} md={3}>
                <ExpressionTextField
                  label="Name"
                  value={variable.name}
                  disabled
                  margin="dense"
                  variant="outlined"
                  inputProps={{ style: inputStyle }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ExpressionTextField
                  label="Initial Value"
                  value={variable.initialValue}
                  onChange={(e) =>
                    onVariableChange(index, { initialValue: e.target.value })
                  }
                  onBlur={() => onVariableBlur(index, 'initialValue')}
                  margin="dense"
                  variant="outlined"
                  inputProps={{ style: inputStyle }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ExpressionTextField
                  label="Lower Bound"
                  value={variable.lowerBound}
                  onChange={(e) => {
                    onVariableChange(index, { lowerBound: e.target.value });
                  }}
                  onBlur={() => onVariableBlur(index, 'lowerBound')}
                  margin="dense"
                  variant="outlined"
                  inputProps={{ style: inputStyle }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <ExpressionTextField
                  label="Upper Bound"
                  value={variable.upperBound}
                  onChange={(e) => {
                    onVariableChange(index, { upperBound: e.target.value });
                  }}
                  onBlur={() => onVariableBlur(index, 'upperBound')}
                  margin="dense"
                  variant="outlined"
                  inputProps={{ style: inputStyle }}
                  fullWidth
                />
              </Grid>
            </Grid>
          ))}
          </Box>
        </Grid>

        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
      <Box width="100%" maxHeight="500px" overflow="auto">
      <NyquistPlot2D
        data={plotData[0] ? plotData[0] : initialValuesData}
        initialValuesData={plotData[0] && initialValuesData.length > 0 ? initialValuesData : []}
      />

        </Box>
        </Grid>
        <Grid item xs={12} container justifyContent="space-between">
        <Button
            variant="contained"
            color="primary"
            onClick={onAccept}
        >
            Accept
        </Button>
        </Grid>
    </Grid>
    );
  };

export default Simulator;

