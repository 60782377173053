import React from 'react';
import {
    Box,
    Grid,

  } from '@mui/material';
// Import SharedButton at the top of the file
import SharedButton from './SharedButton';


const FormulaLoader = ({ setLoadedFormulaName, setExpression, setVariables, setIsSettingsLoaded }) => {

  // Load Formula
  const handleLoadFormula = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setLoadedFormulaName(file.name); // Set the loaded formula name

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const settings = JSON.parse(event.target.result);
        setExpression(settings.expression);
        setVariables(
          settings.variables.map((variable) => ({
            ...variable,
            smoothing: variable.smoothing !== undefined ? variable.smoothing : '0.0',
          }))
        );

        // Update the state when settings are successfully loaded
        setIsSettingsLoaded(true);
      } catch (err) {
        console.error("Error loading settings:", err);
        alert("Error loading settings. Please check the file format.");

        // Reset the state when there is an error
        setIsSettingsLoaded(false);
      }
    };
    reader.readAsText(file);
  };

  const formulaInputRef = React.createRef();
  const handleFormulaLabelClick = (event) => {
    event.preventDefault();

    if (formulaInputRef.current) {
      formulaInputRef.current.value = null;
      formulaInputRef.current.click();
    }
  };


  return (
    <Box position="relative">
      <Grid item xs={12} sm={6}>
        <Grid container item xs={12} direction="column" alignItems="center">
          <Grid item>
            <input
              ref={formulaInputRef}
              id="formula-upload"
              type="file"
              onChange={handleLoadFormula}
              style={{ display: 'none' }}
            />
            <SharedButton style={{ width: '200px' }} htmlFor="formula-upload" onClick={handleFormulaLabelClick}>
              Load Impedance Model
            </SharedButton>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};




export default FormulaLoader;