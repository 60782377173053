
import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import Latex from 'react-latex';
import 'katex/dist/katex.min.css';


const DentingCorrosion = ({ setCircuitExpression, setCircuitVariables, setLoadedFormulaName, setIsSettingsLoaded, closeModal, selectInnerGroup, selectInnerCircuit }) => {
    const [navigating, setNavigating] = useState(false);

    const circuitName = 'Corrosion on porous electrode';

    const circuitExpression = '(Rser + sqrt(Rp / (s * Cdl + 1 / Rct)) * Tanh(sqrt(Rp * (s * Cdl + 1 / Rct))))';

    const circuitVariables = [
        { name: 'Rser', initialValue: 0.21, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rct', initialValue: 0.5, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Cdl', initialValue: 1.2, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },
        { name: 'Rp', initialValue: 2.0, lowerBound: 1e-15, upperBound: 1000000000000000, smoothing: '0.0' },

    ];

    const LatexExpression = 'Z(s) = R_{ser} + \\sqrt{\\frac{R_{p}}{\\frac{1}{sC_{dl + \\frac{1}{R_{ct}}}}}} \\cdot \\tanh(\\sqrt{R_{p}\\cdot \\frac{1}{sC_{dl + \\frac{1}{R_{ct}}}}})';

    const handleSelect = () => {
        setCircuitExpression(circuitExpression);
        setCircuitVariables(circuitVariables);
        setLoadedFormulaName(circuitName);
        setIsSettingsLoaded(true);
        closeModal(); // Close the modal after selecting the circuit
    };

    const handleNavigate = (innerGroup, innerCircuit) => {
        setNavigating(true);
        selectInnerGroup(innerGroup);
        selectInnerCircuit(innerCircuit);
      };

    useEffect(() => {
    if (navigating) {
        closeModal();
    }
    }, [navigating, closeModal]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
        >
            <Typography variant="h5" color="text.primary" gutterBottom>
                {circuitName}
            </Typography>
            <Divider/>
            <Divider/>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Schematic Presentation
            </Typography>
            <img src="/circuit_images/dentingcorrosion_ecm.svg" alt="Schematic Presentation" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Typical spectrum in complex presentation
            </Typography>
            <img src="/circuit_images/dentingcorrosion.png" alt="Circuit" style={{ maxWidth: '100%', marginBottom: '20px' }} />

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function
            </Typography>
            <Typography variant="body1" color="text.secondary">
                <Latex>{`$${LatexExpression}$`}</Latex>
            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Impedance function in terms of electric parameters
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">

                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z_{s}(s) = \\frac{1}{sC_{dl + \\frac{1}{R_{ct}}}}$$`}</Latex>
                </Box>
                <br/>
                    and
                <Box display="flex" justifyContent="center" my={2}>
                    <Latex>{`$$ Z(s) = \\sqrt{\\left(\\frac{R_{p}}{Z_{s}}\\right)} \\cdot \\tanh(\\sqrt{R_{p}\\cdot Z_{s}})$$`}</Latex>
                </Box>

            </Typography>

            <Typography variant="h6" color="text.primary" gutterBottom>
                Applications
            </Typography>
            <Typography variant="body1" color="text.secondary" paragraph>
                Impedance of a <strong>porous actively corroding electrode</strong> can be described by above equivalent circuit. It is obtained by
                simplification of the model by Park and Macdonald [1]
                through neglecting the electronic resistance along the pore. See circuit
                proposed for&nbsp;
                <span onClick={() => handleNavigate('Corrosion Models', 'Denting corrosion/adsorption')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                    porous fuel-cell electrode
                </span> as possible treatment for the case where hydrogen adsorption effect is prominent.
            </Typography>

            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Physical meaning of parameters</h3>
                <ul>
                    <li>
                        <Latex>{`$$R_{ser}$$`}</Latex> is the resistance in the electrolyte pathway that is not compensated for by the reference electrode
                    </li>
                    <li>
                        <Latex>{`$$R_{p}$$`}</Latex> is the resistance of electrolyte in pores. Specific resistance
	                        of electrolyte in pores can be obtained as:
                        <Box>
                            <Latex>{`$$\\rho = R_{p} * \\frac{S}{d}$$`}</Latex> where <Latex>{`$$S$$`}</Latex> is the geometric area of the porous electrode and d is its thickness.
                        </Box>

                    </li>

                    <li>
                        <Latex>{`$$R_{ct}$$`}</Latex> is the charge-transfer resistance of electrochemical reaction.

                    </li>
                    <li>
                        <Latex>{`$$C_{dl}$$`}</Latex> is the capacitance of the double-layer
                    </li>

                </ul>
            </Typography>
            <Typography variant="body1" color="text.secondary" component="div">
                <h3>Reference:</h3>
                <ul>
                    <li>
                        [1] J.R.Park and D.D.Macdonald, Impedance studies of the Growth of Porous Magnetite Films on Carbon Steel
                            in High Temperature Aqueous Systems, Corros. Sci. 23, (1983), 295
                    </li>
                </ul>
            </Typography>

            <Box paddingBottom={1}>
                <Button variant="contained" color="primary" onClick={handleSelect}>
                    Select Circuit
                </Button>
            </Box>
        </Box>
    );

};

export default DentingCorrosion;
