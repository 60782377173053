import React, { useRef, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import * as math from 'mathjs';
import SharedButton from './SharedButton';

const ImpedanceLoader = ({
    setLoadedFileNames,
    setIsFileUploaded,
    setShowSuccessMessage,
    setFileData,
    setPlotData,
    setFitData, }) => {
    const fileInputRef = useRef(null);
    const [timerId, setTimerId] = useState(null);

    useEffect(() => {
        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [timerId]);

    const handleLabelClick = (event) => {
        event.preventDefault();
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
            fileInputRef.current.click();
        }
    };

    // File upload logic
    const handleFileUpload = (e) => {
        setShowSuccessMessage(false);
        setFileData([]);
        setIsFileUploaded(false);
        setFitData(null);
        const files = e.target.files;
        if (!files.length) return;

        let allFileData = [];
        let newLoadedFileNames = [];

        for (const file of files) {
            newLoadedFileNames.push(file.name);
            const reader = new FileReader();
            reader.onload = (event) => {
                const fileContent = event.target.result;
                const delimiter = /[ ,\t]+/;
                let rows = fileContent.trim().split('\n');
                const firstRowColumns = rows[0].split(delimiter);
                const isFirstRowHeader = firstRowColumns.some((column) => isNaN(column));
                if (isFirstRowHeader) {
                    rows = rows.slice(1);
                }
                const numColumns = rows[0].split(delimiter).length;
                const hasSameNumberOfColumns = rows.every((row) => row.split(delimiter).length === numColumns);
                if (!hasSameNumberOfColumns) {
                    alert('Error: Rows have different numbers of columns.');
                    setIsFileUploaded(false);
                    return;
                }

                const fileData = rows.map((line) => {
                    const [freq, zreal, zimag] = line.split(delimiter).map(Number);
                    return { freq, zreal, zimag };
                });

                const isValidData = fileData.every((dataPoint) => {
                    return (
                        !isNaN(dataPoint.freq) &&
                        !isNaN(dataPoint.zreal) &&
                        !isNaN(dataPoint.zimag)
                    );
                });
                if (isValidData) {
                    allFileData.push(fileData);
                } else {
                    setIsFileUploaded(false);
                }
            };
            reader.onloadend = () => {
                if (allFileData.length > 0) {
                    setPlotData([]);
                    setFileData(allFileData);
                    setIsFileUploaded(true);
                    const newPlotData = allFileData.map((dataset, datasetIndex) =>
                        dataset.map((dataPoint) => {
                            const Z = math.complex(dataPoint.zreal, dataPoint.zimag);
                            const Y = math.inv(Z);
                            const real = dataPoint.zreal;
                            const imag = dataPoint.zimag;
                            const magnitude = Math.sqrt(real ** 2 + imag ** 2);
                            const phase = Math.atan2(imag, real) * (180 / Math.PI);

                            return {
                                realImpedance: dataPoint.zreal,
                                imagImpedance: -dataPoint.zimag,
                                realAdmittance: Y.re,
                                imagAdmittance: Y.im,
                                freq: dataPoint.freq,
                                datasetIndex: datasetIndex,
                                magnitude,
                                phase,
                            };
                        })
                    );
                    setPlotData(newPlotData);
                    setShowSuccessMessage(true);
                    const id = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 3000);
                    setTimerId(id);
                } else {
                    setIsFileUploaded(false);
                }
            };
            reader.readAsText(file);
        }
        setLoadedFileNames(newLoadedFileNames);
    };


    return (
        <Box position="relative">
            <Grid item xs={12}>
                <Grid container item xs={12} direction="column" alignItems="center">
                    <Grid item>
                        <input
                            ref={fileInputRef}
                            id="file-upload"
                            type="file"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/plain"
                            multiple // Add this attribute to allow multiple file selection
                        />
                        <SharedButton style={{ width: '200px' }} htmlFor="file-upload" onClick={handleLabelClick}>
                            Load Impedance Data
                        </SharedButton>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ImpedanceLoader;

